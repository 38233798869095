export default [
  {
    children: [
      {
        children: [
          {
            pid: "110100",
            label: "东城区",
            value: "110101"
          },
          {
            pid: "110100",
            label: "西城区",
            value: "110102"
          },
          {
            pid: "110100",
            label: "朝阳区",
            value: "110105"
          },
          {
            pid: "110100",
            label: "丰台区",
            value: "110106"
          },
          {
            pid: "110100",
            label: "石景山区",
            value: "110107"
          },
          {
            pid: "110100",
            label: "海淀区",
            value: "110108"
          },
          {
            pid: "110100",
            label: "门头沟区",
            value: "110109"
          },
          {
            pid: "110100",
            label: "房山区",
            value: "110111"
          },
          {
            pid: "110100",
            label: "通州区",
            value: "110112"
          },
          {
            pid: "110100",
            label: "顺义区",
            value: "110113"
          },
          {
            pid: "110100",
            label: "昌平区",
            value: "110114"
          },
          {
            pid: "110100",
            label: "大兴区",
            value: "110115"
          },
          {
            pid: "110100",
            label: "怀柔区",
            value: "110116"
          },
          {
            pid: "110100",
            label: "平谷区",
            value: "110117"
          },
          {
            pid: "110100",
            label: "密云区",
            value: "110118"
          },
          {
            pid: "110100",
            label: "延庆区",
            value: "110119"
          }
        ],
        pid: "110000",
        label: "北京市",
        value: "110100"
      }
    ],
    pid: "-1",
    label: "北京市",
    value: "110000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "120100",
            label: "和平区",
            value: "120101"
          },
          {
            pid: "120100",
            label: "河东区",
            value: "120102"
          },
          {
            pid: "120100",
            label: "河西区",
            value: "120103"
          },
          {
            pid: "120100",
            label: "南开区",
            value: "120104"
          },
          {
            pid: "120100",
            label: "河北区",
            value: "120105"
          },
          {
            pid: "120100",
            label: "红桥区",
            value: "120106"
          },
          {
            pid: "120100",
            label: "东丽区",
            value: "120110"
          },
          {
            pid: "120100",
            label: "西青区",
            value: "120111"
          },
          {
            pid: "120100",
            label: "津南区",
            value: "120112"
          },
          {
            pid: "120100",
            label: "北辰区",
            value: "120113"
          },
          {
            pid: "120100",
            label: "武清区",
            value: "120114"
          },
          {
            pid: "120100",
            label: "宝坻区",
            value: "120115"
          },
          {
            pid: "120100",
            label: "滨海新区",
            value: "120116"
          },
          {
            pid: "120100",
            label: "宁河区",
            value: "120117"
          },
          {
            pid: "120100",
            label: "静海区",
            value: "120118"
          },
          {
            pid: "120100",
            label: "蓟州区",
            value: "120119"
          }
        ],
        pid: "120000",
        label: "天津市",
        value: "120100"
      }
    ],
    pid: "-1",
    label: "天津市",
    value: "120000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "130100",
            label: "长安区",
            value: "130102"
          },
          {
            pid: "130100",
            label: "桥西区",
            value: "130104"
          },
          {
            pid: "130100",
            label: "新华区",
            value: "130105"
          },
          {
            pid: "130100",
            label: "井陉矿区",
            value: "130107"
          },
          {
            pid: "130100",
            label: "裕华区",
            value: "130108"
          },
          {
            pid: "130100",
            label: "藁城区",
            value: "130109"
          },
          {
            pid: "130100",
            label: "鹿泉区",
            value: "130110"
          },
          {
            pid: "130100",
            label: "栾城区",
            value: "130111"
          },
          {
            pid: "130100",
            label: "井陉县",
            value: "130121"
          },
          {
            pid: "130100",
            label: "正定县",
            value: "130123"
          },
          {
            pid: "130100",
            label: "行唐县",
            value: "130125"
          },
          {
            pid: "130100",
            label: "灵寿县",
            value: "130126"
          },
          {
            pid: "130100",
            label: "高邑县",
            value: "130127"
          },
          {
            pid: "130100",
            label: "深泽县",
            value: "130128"
          },
          {
            pid: "130100",
            label: "赞皇县",
            value: "130129"
          },
          {
            pid: "130100",
            label: "无极县",
            value: "130130"
          },
          {
            pid: "130100",
            label: "平山县",
            value: "130131"
          },
          {
            pid: "130100",
            label: "元氏县",
            value: "130132"
          },
          {
            pid: "130100",
            label: "赵县",
            value: "130133"
          },
          {
            pid: "130100",
            label: "辛集市",
            value: "130181"
          },
          {
            pid: "130100",
            label: "晋州市",
            value: "130183"
          },
          {
            pid: "130100",
            label: "新乐市",
            value: "130184"
          }
        ],
        pid: "130000",
        label: "石家庄市",
        value: "130100"
      },
      {
        children: [
          {
            pid: "130200",
            label: "路南区",
            value: "130202"
          },
          {
            pid: "130200",
            label: "路北区",
            value: "130203"
          },
          {
            pid: "130200",
            label: "古冶区",
            value: "130204"
          },
          {
            pid: "130200",
            label: "开平区",
            value: "130205"
          },
          {
            pid: "130200",
            label: "丰南区",
            value: "130207"
          },
          {
            pid: "130200",
            label: "丰润区",
            value: "130208"
          },
          {
            pid: "130200",
            label: "曹妃甸区",
            value: "130209"
          },
          {
            pid: "130200",
            label: "滦南县",
            value: "130224"
          },
          {
            pid: "130200",
            label: "乐亭县",
            value: "130225"
          },
          {
            pid: "130200",
            label: "迁西县",
            value: "130227"
          },
          {
            pid: "130200",
            label: "玉田县",
            value: "130229"
          },
          {
            pid: "130200",
            label: "遵化市",
            value: "130281"
          },
          {
            pid: "130200",
            label: "迁安市",
            value: "130283"
          },
          {
            pid: "130200",
            label: "滦州市",
            value: "130284"
          }
        ],
        pid: "130000",
        label: "唐山市",
        value: "130200"
      },
      {
        children: [
          {
            pid: "130300",
            label: "海港区",
            value: "130302"
          },
          {
            pid: "130300",
            label: "山海关区",
            value: "130303"
          },
          {
            pid: "130300",
            label: "北戴河区",
            value: "130304"
          },
          {
            pid: "130300",
            label: "抚宁区",
            value: "130306"
          },
          {
            pid: "130300",
            label: "青龙满族自治县",
            value: "130321"
          },
          {
            pid: "130300",
            label: "昌黎县",
            value: "130322"
          },
          {
            pid: "130300",
            label: "卢龙县",
            value: "130324"
          }
        ],
        pid: "130000",
        label: "秦皇岛市",
        value: "130300"
      },
      {
        children: [
          {
            pid: "130400",
            label: "邯山区",
            value: "130402"
          },
          {
            pid: "130400",
            label: "丛台区",
            value: "130403"
          },
          {
            pid: "130400",
            label: "复兴区",
            value: "130404"
          },
          {
            pid: "130400",
            label: "峰峰矿区",
            value: "130406"
          },
          {
            pid: "130400",
            label: "肥乡区",
            value: "130407"
          },
          {
            pid: "130400",
            label: "永年区",
            value: "130408"
          },
          {
            pid: "130400",
            label: "临漳县",
            value: "130423"
          },
          {
            pid: "130400",
            label: "成安县",
            value: "130424"
          },
          {
            pid: "130400",
            label: "大名县",
            value: "130425"
          },
          {
            pid: "130400",
            label: "涉县",
            value: "130426"
          },
          {
            pid: "130400",
            label: "磁县",
            value: "130427"
          },
          {
            pid: "130400",
            label: "邱县",
            value: "130430"
          },
          {
            pid: "130400",
            label: "鸡泽县",
            value: "130431"
          },
          {
            pid: "130400",
            label: "广平县",
            value: "130432"
          },
          {
            pid: "130400",
            label: "馆陶县",
            value: "130433"
          },
          {
            pid: "130400",
            label: "魏县",
            value: "130434"
          },
          {
            pid: "130400",
            label: "曲周县",
            value: "130435"
          },
          {
            pid: "130400",
            label: "武安市",
            value: "130481"
          }
        ],
        pid: "130000",
        label: "邯郸市",
        value: "130400"
      },
      {
        children: [
          {
            pid: "130500",
            label: "桥东区",
            value: "130502"
          },
          {
            pid: "130500",
            label: "桥西区",
            value: "130503"
          },
          {
            pid: "130500",
            label: "邢台县",
            value: "130521"
          },
          {
            pid: "130500",
            label: "临城县",
            value: "130522"
          },
          {
            pid: "130500",
            label: "内丘县",
            value: "130523"
          },
          {
            pid: "130500",
            label: "柏乡县",
            value: "130524"
          },
          {
            pid: "130500",
            label: "隆尧县",
            value: "130525"
          },
          {
            pid: "130500",
            label: "任县",
            value: "130526"
          },
          {
            pid: "130500",
            label: "南和县",
            value: "130527"
          },
          {
            pid: "130500",
            label: "宁晋县",
            value: "130528"
          },
          {
            pid: "130500",
            label: "巨鹿县",
            value: "130529"
          },
          {
            pid: "130500",
            label: "新河县",
            value: "130530"
          },
          {
            pid: "130500",
            label: "广宗县",
            value: "130531"
          },
          {
            pid: "130500",
            label: "平乡县",
            value: "130532"
          },
          {
            pid: "130500",
            label: "威县",
            value: "130533"
          },
          {
            pid: "130500",
            label: "清河县",
            value: "130534"
          },
          {
            pid: "130500",
            label: "临西县",
            value: "130535"
          },
          {
            pid: "130500",
            label: "南宫市",
            value: "130581"
          },
          {
            pid: "130500",
            label: "沙河市",
            value: "130582"
          }
        ],
        pid: "130000",
        label: "邢台市",
        value: "130500"
      },
      {
        children: [
          {
            pid: "130600",
            label: "竞秀区",
            value: "130602"
          },
          {
            pid: "130600",
            label: "莲池区",
            value: "130606"
          },
          {
            pid: "130600",
            label: "满城区",
            value: "130607"
          },
          {
            pid: "130600",
            label: "清苑区",
            value: "130608"
          },
          {
            pid: "130600",
            label: "徐水区",
            value: "130609"
          },
          {
            pid: "130600",
            label: "涞水县",
            value: "130623"
          },
          {
            pid: "130600",
            label: "阜平县",
            value: "130624"
          },
          {
            pid: "130600",
            label: "定兴县",
            value: "130626"
          },
          {
            pid: "130600",
            label: "唐县",
            value: "130627"
          },
          {
            pid: "130600",
            label: "高阳县",
            value: "130628"
          },
          {
            pid: "130600",
            label: "容城县",
            value: "130629"
          },
          {
            pid: "130600",
            label: "涞源县",
            value: "130630"
          },
          {
            pid: "130600",
            label: "望都县",
            value: "130631"
          },
          {
            pid: "130600",
            label: "安新县",
            value: "130632"
          },
          {
            pid: "130600",
            label: "易县",
            value: "130633"
          },
          {
            pid: "130600",
            label: "曲阳县",
            value: "130634"
          },
          {
            pid: "130600",
            label: "蠡县",
            value: "130635"
          },
          {
            pid: "130600",
            label: "顺平县",
            value: "130636"
          },
          {
            pid: "130600",
            label: "博野县",
            value: "130637"
          },
          {
            pid: "130600",
            label: "雄县",
            value: "130638"
          },
          {
            pid: "130600",
            label: "涿州市",
            value: "130681"
          },
          {
            pid: "130600",
            label: "定州市",
            value: "130682"
          },
          {
            pid: "130600",
            label: "安国市",
            value: "130683"
          },
          {
            pid: "130600",
            label: "高碑店市",
            value: "130684"
          }
        ],
        pid: "130000",
        label: "保定市",
        value: "130600"
      },
      {
        children: [
          {
            pid: "130700",
            label: "桥东区",
            value: "130702"
          },
          {
            pid: "130700",
            label: "桥西区",
            value: "130703"
          },
          {
            pid: "130700",
            label: "宣化区",
            value: "130705"
          },
          {
            pid: "130700",
            label: "下花园区",
            value: "130706"
          },
          {
            pid: "130700",
            label: "万全区",
            value: "130708"
          },
          {
            pid: "130700",
            label: "崇礼区",
            value: "130709"
          },
          {
            pid: "130700",
            label: "张北县",
            value: "130722"
          },
          {
            pid: "130700",
            label: "康保县",
            value: "130723"
          },
          {
            pid: "130700",
            label: "沽源县",
            value: "130724"
          },
          {
            pid: "130700",
            label: "尚义县",
            value: "130725"
          },
          {
            pid: "130700",
            label: "蔚县",
            value: "130726"
          },
          {
            pid: "130700",
            label: "阳原县",
            value: "130727"
          },
          {
            pid: "130700",
            label: "怀安县",
            value: "130728"
          },
          {
            pid: "130700",
            label: "怀来县",
            value: "130730"
          },
          {
            pid: "130700",
            label: "涿鹿县",
            value: "130731"
          },
          {
            pid: "130700",
            label: "赤城县",
            value: "130732"
          }
        ],
        pid: "130000",
        label: "张家口市",
        value: "130700"
      },
      {
        children: [
          {
            pid: "130800",
            label: "双桥区",
            value: "130802"
          },
          {
            pid: "130800",
            label: "双滦区",
            value: "130803"
          },
          {
            pid: "130800",
            label: "鹰手营子矿区",
            value: "130804"
          },
          {
            pid: "130800",
            label: "承德县",
            value: "130821"
          },
          {
            pid: "130800",
            label: "兴隆县",
            value: "130822"
          },
          {
            pid: "130800",
            label: "滦平县",
            value: "130824"
          },
          {
            pid: "130800",
            label: "隆化县",
            value: "130825"
          },
          {
            pid: "130800",
            label: "丰宁满族自治县",
            value: "130826"
          },
          {
            pid: "130800",
            label: "宽城满族自治县",
            value: "130827"
          },
          {
            pid: "130800",
            label: "围场满族蒙古族自治县",
            value: "130828"
          },
          {
            pid: "130800",
            label: "平泉市",
            value: "130881"
          }
        ],
        pid: "130000",
        label: "承德市",
        value: "130800"
      },
      {
        children: [
          {
            pid: "130900",
            label: "新华区",
            value: "130902"
          },
          {
            pid: "130900",
            label: "运河区",
            value: "130903"
          },
          {
            pid: "130900",
            label: "沧县",
            value: "130921"
          },
          {
            pid: "130900",
            label: "青县",
            value: "130922"
          },
          {
            pid: "130900",
            label: "东光县",
            value: "130923"
          },
          {
            pid: "130900",
            label: "海兴县",
            value: "130924"
          },
          {
            pid: "130900",
            label: "盐山县",
            value: "130925"
          },
          {
            pid: "130900",
            label: "肃宁县",
            value: "130926"
          },
          {
            pid: "130900",
            label: "南皮县",
            value: "130927"
          },
          {
            pid: "130900",
            label: "吴桥县",
            value: "130928"
          },
          {
            pid: "130900",
            label: "献县",
            value: "130929"
          },
          {
            pid: "130900",
            label: "孟村回族自治县",
            value: "130930"
          },
          {
            pid: "130900",
            label: "泊头市",
            value: "130981"
          },
          {
            pid: "130900",
            label: "任丘市",
            value: "130982"
          },
          {
            pid: "130900",
            label: "黄骅市",
            value: "130983"
          },
          {
            pid: "130900",
            label: "河间市",
            value: "130984"
          }
        ],
        pid: "130000",
        label: "沧州市",
        value: "130900"
      },
      {
        children: [
          {
            pid: "131000",
            label: "安次区",
            value: "131002"
          },
          {
            pid: "131000",
            label: "广阳区",
            value: "131003"
          },
          {
            pid: "131000",
            label: "固安县",
            value: "131022"
          },
          {
            pid: "131000",
            label: "永清县",
            value: "131023"
          },
          {
            pid: "131000",
            label: "香河县",
            value: "131024"
          },
          {
            pid: "131000",
            label: "大城县",
            value: "131025"
          },
          {
            pid: "131000",
            label: "文安县",
            value: "131026"
          },
          {
            pid: "131000",
            label: "大厂回族自治县",
            value: "131028"
          },
          {
            pid: "131000",
            label: "霸州市",
            value: "131081"
          },
          {
            pid: "131000",
            label: "三河市",
            value: "131082"
          }
        ],
        pid: "130000",
        label: "廊坊市",
        value: "131000"
      },
      {
        children: [
          {
            pid: "131100",
            label: "桃城区",
            value: "131102"
          },
          {
            pid: "131100",
            label: "冀州区",
            value: "131103"
          },
          {
            pid: "131100",
            label: "枣强县",
            value: "131121"
          },
          {
            pid: "131100",
            label: "武邑县",
            value: "131122"
          },
          {
            pid: "131100",
            label: "武强县",
            value: "131123"
          },
          {
            pid: "131100",
            label: "饶阳县",
            value: "131124"
          },
          {
            pid: "131100",
            label: "安平县",
            value: "131125"
          },
          {
            pid: "131100",
            label: "故城县",
            value: "131126"
          },
          {
            pid: "131100",
            label: "景县",
            value: "131127"
          },
          {
            pid: "131100",
            label: "阜城县",
            value: "131128"
          },
          {
            pid: "131100",
            label: "深州市",
            value: "131182"
          }
        ],
        pid: "130000",
        label: "衡水市",
        value: "131100"
      }
    ],
    pid: "-1",
    label: "河北省",
    value: "130000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "140100",
            label: "小店区",
            value: "140105"
          },
          {
            pid: "140100",
            label: "迎泽区",
            value: "140106"
          },
          {
            pid: "140100",
            label: "杏花岭区",
            value: "140107"
          },
          {
            pid: "140100",
            label: "尖草坪区",
            value: "140108"
          },
          {
            pid: "140100",
            label: "万柏林区",
            value: "140109"
          },
          {
            pid: "140100",
            label: "晋源区",
            value: "140110"
          },
          {
            pid: "140100",
            label: "清徐县",
            value: "140121"
          },
          {
            pid: "140100",
            label: "阳曲县",
            value: "140122"
          },
          {
            pid: "140100",
            label: "娄烦县",
            value: "140123"
          },
          {
            pid: "140100",
            label: "古交市",
            value: "140181"
          }
        ],
        pid: "140000",
        label: "太原市",
        value: "140100"
      },
      {
        children: [
          {
            pid: "140200",
            label: "新荣区",
            value: "140212"
          },
          {
            pid: "140200",
            label: "平城区",
            value: "140213"
          },
          {
            pid: "140200",
            label: "云冈区",
            value: "140214"
          },
          {
            pid: "140200",
            label: "云州区",
            value: "140215"
          },
          {
            pid: "140200",
            label: "阳高县",
            value: "140221"
          },
          {
            pid: "140200",
            label: "天镇县",
            value: "140222"
          },
          {
            pid: "140200",
            label: "广灵县",
            value: "140223"
          },
          {
            pid: "140200",
            label: "灵丘县",
            value: "140224"
          },
          {
            pid: "140200",
            label: "浑源县",
            value: "140225"
          },
          {
            pid: "140200",
            label: "左云县",
            value: "140226"
          }
        ],
        pid: "140000",
        label: "大同市",
        value: "140200"
      },
      {
        children: [
          {
            pid: "140300",
            label: "城区",
            value: "140302"
          },
          {
            pid: "140300",
            label: "矿区",
            value: "140303"
          },
          {
            pid: "140300",
            label: "郊区",
            value: "140311"
          },
          {
            pid: "140300",
            label: "平定县",
            value: "140321"
          },
          {
            pid: "140300",
            label: "盂县",
            value: "140322"
          }
        ],
        pid: "140000",
        label: "阳泉市",
        value: "140300"
      },
      {
        children: [
          {
            pid: "140400",
            label: "潞州区",
            value: "140403"
          },
          {
            pid: "140400",
            label: "上党区",
            value: "140404"
          },
          {
            pid: "140400",
            label: "屯留区",
            value: "140405"
          },
          {
            pid: "140400",
            label: "潞城区",
            value: "140406"
          },
          {
            pid: "140400",
            label: "襄垣县",
            value: "140423"
          },
          {
            pid: "140400",
            label: "平顺县",
            value: "140425"
          },
          {
            pid: "140400",
            label: "黎城县",
            value: "140426"
          },
          {
            pid: "140400",
            label: "壶关县",
            value: "140427"
          },
          {
            pid: "140400",
            label: "长子县",
            value: "140428"
          },
          {
            pid: "140400",
            label: "武乡县",
            value: "140429"
          },
          {
            pid: "140400",
            label: "沁县",
            value: "140430"
          },
          {
            pid: "140400",
            label: "沁源县",
            value: "140431"
          }
        ],
        pid: "140000",
        label: "长治市",
        value: "140400"
      },
      {
        children: [
          {
            pid: "140500",
            label: "城区",
            value: "140502"
          },
          {
            pid: "140500",
            label: "沁水县",
            value: "140521"
          },
          {
            pid: "140500",
            label: "阳城县",
            value: "140522"
          },
          {
            pid: "140500",
            label: "陵川县",
            value: "140524"
          },
          {
            pid: "140500",
            label: "泽州县",
            value: "140525"
          },
          {
            pid: "140500",
            label: "高平市",
            value: "140581"
          }
        ],
        pid: "140000",
        label: "晋城市",
        value: "140500"
      },
      {
        children: [
          {
            pid: "140600",
            label: "朔城区",
            value: "140602"
          },
          {
            pid: "140600",
            label: "平鲁区",
            value: "140603"
          },
          {
            pid: "140600",
            label: "山阴县",
            value: "140621"
          },
          {
            pid: "140600",
            label: "应县",
            value: "140622"
          },
          {
            pid: "140600",
            label: "右玉县",
            value: "140623"
          },
          {
            pid: "140600",
            label: "怀仁市",
            value: "140681"
          }
        ],
        pid: "140000",
        label: "朔州市",
        value: "140600"
      },
      {
        children: [
          {
            pid: "140700",
            label: "榆次区",
            value: "140702"
          },
          {
            pid: "140700",
            label: "榆社县",
            value: "140721"
          },
          {
            pid: "140700",
            label: "左权县",
            value: "140722"
          },
          {
            pid: "140700",
            label: "和顺县",
            value: "140723"
          },
          {
            pid: "140700",
            label: "昔阳县",
            value: "140724"
          },
          {
            pid: "140700",
            label: "寿阳县",
            value: "140725"
          },
          {
            pid: "140700",
            label: "太谷县",
            value: "140726"
          },
          {
            pid: "140700",
            label: "祁县",
            value: "140727"
          },
          {
            pid: "140700",
            label: "平遥县",
            value: "140728"
          },
          {
            pid: "140700",
            label: "灵石县",
            value: "140729"
          },
          {
            pid: "140700",
            label: "介休市",
            value: "140781"
          }
        ],
        pid: "140000",
        label: "晋中市",
        value: "140700"
      },
      {
        children: [
          {
            pid: "140800",
            label: "盐湖区",
            value: "140802"
          },
          {
            pid: "140800",
            label: "临猗县",
            value: "140821"
          },
          {
            pid: "140800",
            label: "万荣县",
            value: "140822"
          },
          {
            pid: "140800",
            label: "闻喜县",
            value: "140823"
          },
          {
            pid: "140800",
            label: "稷山县",
            value: "140824"
          },
          {
            pid: "140800",
            label: "新绛县",
            value: "140825"
          },
          {
            pid: "140800",
            label: "绛县",
            value: "140826"
          },
          {
            pid: "140800",
            label: "垣曲县",
            value: "140827"
          },
          {
            pid: "140800",
            label: "夏县",
            value: "140828"
          },
          {
            pid: "140800",
            label: "平陆县",
            value: "140829"
          },
          {
            pid: "140800",
            label: "芮城县",
            value: "140830"
          },
          {
            pid: "140800",
            label: "永济市",
            value: "140881"
          },
          {
            pid: "140800",
            label: "河津市",
            value: "140882"
          }
        ],
        pid: "140000",
        label: "运城市",
        value: "140800"
      },
      {
        children: [
          {
            pid: "140900",
            label: "忻府区",
            value: "140902"
          },
          {
            pid: "140900",
            label: "定襄县",
            value: "140921"
          },
          {
            pid: "140900",
            label: "五台县",
            value: "140922"
          },
          {
            pid: "140900",
            label: "代县",
            value: "140923"
          },
          {
            pid: "140900",
            label: "繁峙县",
            value: "140924"
          },
          {
            pid: "140900",
            label: "宁武县",
            value: "140925"
          },
          {
            pid: "140900",
            label: "静乐县",
            value: "140926"
          },
          {
            pid: "140900",
            label: "神池县",
            value: "140927"
          },
          {
            pid: "140900",
            label: "五寨县",
            value: "140928"
          },
          {
            pid: "140900",
            label: "岢岚县",
            value: "140929"
          },
          {
            pid: "140900",
            label: "河曲县",
            value: "140930"
          },
          {
            pid: "140900",
            label: "保德县",
            value: "140931"
          },
          {
            pid: "140900",
            label: "偏关县",
            value: "140932"
          },
          {
            pid: "140900",
            label: "原平市",
            value: "140981"
          }
        ],
        pid: "140000",
        label: "忻州市",
        value: "140900"
      },
      {
        children: [
          {
            pid: "141000",
            label: "尧都区",
            value: "141002"
          },
          {
            pid: "141000",
            label: "曲沃县",
            value: "141021"
          },
          {
            pid: "141000",
            label: "翼城县",
            value: "141022"
          },
          {
            pid: "141000",
            label: "襄汾县",
            value: "141023"
          },
          {
            pid: "141000",
            label: "洪洞县",
            value: "141024"
          },
          {
            pid: "141000",
            label: "古县",
            value: "141025"
          },
          {
            pid: "141000",
            label: "安泽县",
            value: "141026"
          },
          {
            pid: "141000",
            label: "浮山县",
            value: "141027"
          },
          {
            pid: "141000",
            label: "吉县",
            value: "141028"
          },
          {
            pid: "141000",
            label: "乡宁县",
            value: "141029"
          },
          {
            pid: "141000",
            label: "大宁县",
            value: "141030"
          },
          {
            pid: "141000",
            label: "隰县",
            value: "141031"
          },
          {
            pid: "141000",
            label: "永和县",
            value: "141032"
          },
          {
            pid: "141000",
            label: "蒲县",
            value: "141033"
          },
          {
            pid: "141000",
            label: "汾西县",
            value: "141034"
          },
          {
            pid: "141000",
            label: "侯马市",
            value: "141081"
          },
          {
            pid: "141000",
            label: "霍州市",
            value: "141082"
          }
        ],
        pid: "140000",
        label: "临汾市",
        value: "141000"
      },
      {
        children: [
          {
            pid: "141100",
            label: "离石区",
            value: "141102"
          },
          {
            pid: "141100",
            label: "文水县",
            value: "141121"
          },
          {
            pid: "141100",
            label: "交城县",
            value: "141122"
          },
          {
            pid: "141100",
            label: "兴县",
            value: "141123"
          },
          {
            pid: "141100",
            label: "临县",
            value: "141124"
          },
          {
            pid: "141100",
            label: "柳林县",
            value: "141125"
          },
          {
            pid: "141100",
            label: "石楼县",
            value: "141126"
          },
          {
            pid: "141100",
            label: "岚县",
            value: "141127"
          },
          {
            pid: "141100",
            label: "方山县",
            value: "141128"
          },
          {
            pid: "141100",
            label: "中阳县",
            value: "141129"
          },
          {
            pid: "141100",
            label: "交口县",
            value: "141130"
          },
          {
            pid: "141100",
            label: "孝义市",
            value: "141181"
          },
          {
            pid: "141100",
            label: "汾阳市",
            value: "141182"
          }
        ],
        pid: "140000",
        label: "吕梁市",
        value: "141100"
      }
    ],
    pid: "-1",
    label: "山西省",
    value: "140000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "150100",
            label: "新城区",
            value: "150102"
          },
          {
            pid: "150100",
            label: "回民区",
            value: "150103"
          },
          {
            pid: "150100",
            label: "玉泉区",
            value: "150104"
          },
          {
            pid: "150100",
            label: "赛罕区",
            value: "150105"
          },
          {
            pid: "150100",
            label: "土默特左旗",
            value: "150121"
          },
          {
            pid: "150100",
            label: "托克托县",
            value: "150122"
          },
          {
            pid: "150100",
            label: "和林格尔县",
            value: "150123"
          },
          {
            pid: "150100",
            label: "清水河县",
            value: "150124"
          },
          {
            pid: "150100",
            label: "武川县",
            value: "150125"
          }
        ],
        pid: "150000",
        label: "呼和浩特市",
        value: "150100"
      },
      {
        children: [
          {
            pid: "150200",
            label: "东河区",
            value: "150202"
          },
          {
            pid: "150200",
            label: "昆都仑区",
            value: "150203"
          },
          {
            pid: "150200",
            label: "青山区",
            value: "150204"
          },
          {
            pid: "150200",
            label: "石拐区",
            value: "150205"
          },
          {
            pid: "150200",
            label: "白云鄂博矿区",
            value: "150206"
          },
          {
            pid: "150200",
            label: "九原区",
            value: "150207"
          },
          {
            pid: "150200",
            label: "土默特右旗",
            value: "150221"
          },
          {
            pid: "150200",
            label: "固阳县",
            value: "150222"
          },
          {
            pid: "150200",
            label: "达尔罕茂明安联合旗",
            value: "150223"
          }
        ],
        pid: "150000",
        label: "包头市",
        value: "150200"
      },
      {
        children: [
          {
            pid: "150300",
            label: "海勃湾区",
            value: "150302"
          },
          {
            pid: "150300",
            label: "海南区",
            value: "150303"
          },
          {
            pid: "150300",
            label: "乌达区",
            value: "150304"
          }
        ],
        pid: "150000",
        label: "乌海市",
        value: "150300"
      },
      {
        children: [
          {
            pid: "150400",
            label: "红山区",
            value: "150402"
          },
          {
            pid: "150400",
            label: "元宝山区",
            value: "150403"
          },
          {
            pid: "150400",
            label: "松山区",
            value: "150404"
          },
          {
            pid: "150400",
            label: "阿鲁科尔沁旗",
            value: "150421"
          },
          {
            pid: "150400",
            label: "巴林左旗",
            value: "150422"
          },
          {
            pid: "150400",
            label: "巴林右旗",
            value: "150423"
          },
          {
            pid: "150400",
            label: "林西县",
            value: "150424"
          },
          {
            pid: "150400",
            label: "克什克腾旗",
            value: "150425"
          },
          {
            pid: "150400",
            label: "翁牛特旗",
            value: "150426"
          },
          {
            pid: "150400",
            label: "喀喇沁旗",
            value: "150428"
          },
          {
            pid: "150400",
            label: "宁城县",
            value: "150429"
          },
          {
            pid: "150400",
            label: "敖汉旗",
            value: "150430"
          }
        ],
        pid: "150000",
        label: "赤峰市",
        value: "150400"
      },
      {
        children: [
          {
            pid: "150500",
            label: "科尔沁区",
            value: "150502"
          },
          {
            pid: "150500",
            label: "科尔沁左翼中旗",
            value: "150521"
          },
          {
            pid: "150500",
            label: "科尔沁左翼后旗",
            value: "150522"
          },
          {
            pid: "150500",
            label: "开鲁县",
            value: "150523"
          },
          {
            pid: "150500",
            label: "库伦旗",
            value: "150524"
          },
          {
            pid: "150500",
            label: "奈曼旗",
            value: "150525"
          },
          {
            pid: "150500",
            label: "扎鲁特旗",
            value: "150526"
          },
          {
            pid: "150500",
            label: "霍林郭勒市",
            value: "150581"
          }
        ],
        pid: "150000",
        label: "通辽市",
        value: "150500"
      },
      {
        children: [
          {
            pid: "150600",
            label: "东胜区",
            value: "150602"
          },
          {
            pid: "150600",
            label: "康巴什区",
            value: "150603"
          },
          {
            pid: "150600",
            label: "达拉特旗",
            value: "150621"
          },
          {
            pid: "150600",
            label: "准格尔旗",
            value: "150622"
          },
          {
            pid: "150600",
            label: "鄂托克前旗",
            value: "150623"
          },
          {
            pid: "150600",
            label: "鄂托克旗",
            value: "150624"
          },
          {
            pid: "150600",
            label: "杭锦旗",
            value: "150625"
          },
          {
            pid: "150600",
            label: "乌审旗",
            value: "150626"
          },
          {
            pid: "150600",
            label: "伊金霍洛旗",
            value: "150627"
          }
        ],
        pid: "150000",
        label: "鄂尔多斯市",
        value: "150600"
      },
      {
        children: [
          {
            pid: "150700",
            label: "海拉尔区",
            value: "150702"
          },
          {
            pid: "150700",
            label: "扎赉诺尔区",
            value: "150703"
          },
          {
            pid: "150700",
            label: "阿荣旗",
            value: "150721"
          },
          {
            pid: "150700",
            label: "莫力达瓦达斡尔族自治旗",
            value: "150722"
          },
          {
            pid: "150700",
            label: "鄂伦春自治旗",
            value: "150723"
          },
          {
            pid: "150700",
            label: "鄂温克族自治旗",
            value: "150724"
          },
          {
            pid: "150700",
            label: "陈巴尔虎旗",
            value: "150725"
          },
          {
            pid: "150700",
            label: "新巴尔虎左旗",
            value: "150726"
          },
          {
            pid: "150700",
            label: "新巴尔虎右旗",
            value: "150727"
          },
          {
            pid: "150700",
            label: "满洲里市",
            value: "150781"
          },
          {
            pid: "150700",
            label: "牙克石市",
            value: "150782"
          },
          {
            pid: "150700",
            label: "扎兰屯市",
            value: "150783"
          },
          {
            pid: "150700",
            label: "额尔古纳市",
            value: "150784"
          },
          {
            pid: "150700",
            label: "根河市",
            value: "150785"
          }
        ],
        pid: "150000",
        label: "呼伦贝尔市",
        value: "150700"
      },
      {
        children: [
          {
            pid: "150800",
            label: "临河区",
            value: "150802"
          },
          {
            pid: "150800",
            label: "五原县",
            value: "150821"
          },
          {
            pid: "150800",
            label: "磴口县",
            value: "150822"
          },
          {
            pid: "150800",
            label: "乌拉特前旗",
            value: "150823"
          },
          {
            pid: "150800",
            label: "乌拉特中旗",
            value: "150824"
          },
          {
            pid: "150800",
            label: "乌拉特后旗",
            value: "150825"
          },
          {
            pid: "150800",
            label: "杭锦后旗",
            value: "150826"
          }
        ],
        pid: "150000",
        label: "巴彦淖尔市",
        value: "150800"
      },
      {
        children: [
          {
            pid: "150900",
            label: "集宁区",
            value: "150902"
          },
          {
            pid: "150900",
            label: "卓资县",
            value: "150921"
          },
          {
            pid: "150900",
            label: "化德县",
            value: "150922"
          },
          {
            pid: "150900",
            label: "商都县",
            value: "150923"
          },
          {
            pid: "150900",
            label: "兴和县",
            value: "150924"
          },
          {
            pid: "150900",
            label: "凉城县",
            value: "150925"
          },
          {
            pid: "150900",
            label: "察哈尔右翼前旗",
            value: "150926"
          },
          {
            pid: "150900",
            label: "察哈尔右翼中旗",
            value: "150927"
          },
          {
            pid: "150900",
            label: "察哈尔右翼后旗",
            value: "150928"
          },
          {
            pid: "150900",
            label: "四子王旗",
            value: "150929"
          },
          {
            pid: "150900",
            label: "丰镇市",
            value: "150981"
          }
        ],
        pid: "150000",
        label: "乌兰察布市",
        value: "150900"
      },
      {
        children: [
          {
            pid: "152200",
            label: "乌兰浩特市",
            value: "152201"
          },
          {
            pid: "152200",
            label: "阿尔山市",
            value: "152202"
          },
          {
            pid: "152200",
            label: "科尔沁右翼前旗",
            value: "152221"
          },
          {
            pid: "152200",
            label: "科尔沁右翼中旗",
            value: "152222"
          },
          {
            pid: "152200",
            label: "扎赉特旗",
            value: "152223"
          },
          {
            pid: "152200",
            label: "突泉县",
            value: "152224"
          }
        ],
        pid: "150000",
        label: "兴安盟",
        value: "152200"
      },
      {
        children: [
          {
            pid: "152500",
            label: "二连浩特市",
            value: "152501"
          },
          {
            pid: "152500",
            label: "锡林浩特市",
            value: "152502"
          },
          {
            pid: "152500",
            label: "阿巴嘎旗",
            value: "152522"
          },
          {
            pid: "152500",
            label: "苏尼特左旗",
            value: "152523"
          },
          {
            pid: "152500",
            label: "苏尼特右旗",
            value: "152524"
          },
          {
            pid: "152500",
            label: "东乌珠穆沁旗",
            value: "152525"
          },
          {
            pid: "152500",
            label: "西乌珠穆沁旗",
            value: "152526"
          },
          {
            pid: "152500",
            label: "太仆寺旗",
            value: "152527"
          },
          {
            pid: "152500",
            label: "镶黄旗",
            value: "152528"
          },
          {
            pid: "152500",
            label: "正镶白旗",
            value: "152529"
          },
          {
            pid: "152500",
            label: "正蓝旗",
            value: "152530"
          },
          {
            pid: "152500",
            label: "多伦县",
            value: "152531"
          }
        ],
        pid: "150000",
        label: "锡林郭勒盟",
        value: "152500"
      },
      {
        children: [
          {
            pid: "152900",
            label: "阿拉善左旗",
            value: "152921"
          },
          {
            pid: "152900",
            label: "阿拉善右旗",
            value: "152922"
          },
          {
            pid: "152900",
            label: "额济纳旗",
            value: "152923"
          }
        ],
        pid: "150000",
        label: "阿拉善盟",
        value: "152900"
      }
    ],
    pid: "-1",
    label: "内蒙古自治区",
    value: "150000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "210100",
            label: "和平区",
            value: "210102"
          },
          {
            pid: "210100",
            label: "沈河区",
            value: "210103"
          },
          {
            pid: "210100",
            label: "大东区",
            value: "210104"
          },
          {
            pid: "210100",
            label: "皇姑区",
            value: "210105"
          },
          {
            pid: "210100",
            label: "铁西区",
            value: "210106"
          },
          {
            pid: "210100",
            label: "苏家屯区",
            value: "210111"
          },
          {
            pid: "210100",
            label: "浑南区",
            value: "210112"
          },
          {
            pid: "210100",
            label: "沈北新区",
            value: "210113"
          },
          {
            pid: "210100",
            label: "于洪区",
            value: "210114"
          },
          {
            pid: "210100",
            label: "辽中区",
            value: "210115"
          },
          {
            pid: "210100",
            label: "康平县",
            value: "210123"
          },
          {
            pid: "210100",
            label: "法库县",
            value: "210124"
          },
          {
            pid: "210100",
            label: "新民市",
            value: "210181"
          }
        ],
        pid: "210000",
        label: "沈阳市",
        value: "210100"
      },
      {
        children: [
          {
            pid: "210200",
            label: "中山区",
            value: "210202"
          },
          {
            pid: "210200",
            label: "西岗区",
            value: "210203"
          },
          {
            pid: "210200",
            label: "沙河口区",
            value: "210204"
          },
          {
            pid: "210200",
            label: "甘井子区",
            value: "210211"
          },
          {
            pid: "210200",
            label: "旅顺口区",
            value: "210212"
          },
          {
            pid: "210200",
            label: "金州区",
            value: "210213"
          },
          {
            pid: "210200",
            label: "普兰店区",
            value: "210214"
          },
          {
            pid: "210200",
            label: "长海县",
            value: "210224"
          },
          {
            pid: "210200",
            label: "瓦房店市",
            value: "210281"
          },
          {
            pid: "210200",
            label: "庄河市",
            value: "210283"
          }
        ],
        pid: "210000",
        label: "大连市",
        value: "210200"
      },
      {
        children: [
          {
            pid: "210300",
            label: "铁东区",
            value: "210302"
          },
          {
            pid: "210300",
            label: "铁西区",
            value: "210303"
          },
          {
            pid: "210300",
            label: "立山区",
            value: "210304"
          },
          {
            pid: "210300",
            label: "千山区",
            value: "210311"
          },
          {
            pid: "210300",
            label: "台安县",
            value: "210321"
          },
          {
            pid: "210300",
            label: "岫岩满族自治县",
            value: "210323"
          },
          {
            pid: "210300",
            label: "海城市",
            value: "210381"
          }
        ],
        pid: "210000",
        label: "鞍山市",
        value: "210300"
      },
      {
        children: [
          {
            pid: "210400",
            label: "新抚区",
            value: "210402"
          },
          {
            pid: "210400",
            label: "东洲区",
            value: "210403"
          },
          {
            pid: "210400",
            label: "望花区",
            value: "210404"
          },
          {
            pid: "210400",
            label: "顺城区",
            value: "210411"
          },
          {
            pid: "210400",
            label: "抚顺县",
            value: "210421"
          },
          {
            pid: "210400",
            label: "新宾满族自治县",
            value: "210422"
          },
          {
            pid: "210400",
            label: "清原满族自治县",
            value: "210423"
          }
        ],
        pid: "210000",
        label: "抚顺市",
        value: "210400"
      },
      {
        children: [
          {
            pid: "210500",
            label: "平山区",
            value: "210502"
          },
          {
            pid: "210500",
            label: "溪湖区",
            value: "210503"
          },
          {
            pid: "210500",
            label: "明山区",
            value: "210504"
          },
          {
            pid: "210500",
            label: "南芬区",
            value: "210505"
          },
          {
            pid: "210500",
            label: "本溪满族自治县",
            value: "210521"
          },
          {
            pid: "210500",
            label: "桓仁满族自治县",
            value: "210522"
          }
        ],
        pid: "210000",
        label: "本溪市",
        value: "210500"
      },
      {
        children: [
          {
            pid: "210600",
            label: "元宝区",
            value: "210602"
          },
          {
            pid: "210600",
            label: "振兴区",
            value: "210603"
          },
          {
            pid: "210600",
            label: "振安区",
            value: "210604"
          },
          {
            pid: "210600",
            label: "宽甸满族自治县",
            value: "210624"
          },
          {
            pid: "210600",
            label: "东港市",
            value: "210681"
          },
          {
            pid: "210600",
            label: "凤城市",
            value: "210682"
          }
        ],
        pid: "210000",
        label: "丹东市",
        value: "210600"
      },
      {
        children: [
          {
            pid: "210700",
            label: "古塔区",
            value: "210702"
          },
          {
            pid: "210700",
            label: "凌河区",
            value: "210703"
          },
          {
            pid: "210700",
            label: "太和区",
            value: "210711"
          },
          {
            pid: "210700",
            label: "黑山县",
            value: "210726"
          },
          {
            pid: "210700",
            label: "义县",
            value: "210727"
          },
          {
            pid: "210700",
            label: "凌海市",
            value: "210781"
          },
          {
            pid: "210700",
            label: "北镇市",
            value: "210782"
          }
        ],
        pid: "210000",
        label: "锦州市",
        value: "210700"
      },
      {
        children: [
          {
            pid: "210800",
            label: "站前区",
            value: "210802"
          },
          {
            pid: "210800",
            label: "西市区",
            value: "210803"
          },
          {
            pid: "210800",
            label: "鲅鱼圈区",
            value: "210804"
          },
          {
            pid: "210800",
            label: "老边区",
            value: "210811"
          },
          {
            pid: "210800",
            label: "盖州市",
            value: "210881"
          },
          {
            pid: "210800",
            label: "大石桥市",
            value: "210882"
          }
        ],
        pid: "210000",
        label: "营口市",
        value: "210800"
      },
      {
        children: [
          {
            pid: "210900",
            label: "海州区",
            value: "210902"
          },
          {
            pid: "210900",
            label: "新邱区",
            value: "210903"
          },
          {
            pid: "210900",
            label: "太平区",
            value: "210904"
          },
          {
            pid: "210900",
            label: "清河门区",
            value: "210905"
          },
          {
            pid: "210900",
            label: "细河区",
            value: "210911"
          },
          {
            pid: "210900",
            label: "阜新蒙古族自治县",
            value: "210921"
          },
          {
            pid: "210900",
            label: "彰武县",
            value: "210922"
          }
        ],
        pid: "210000",
        label: "阜新市",
        value: "210900"
      },
      {
        children: [
          {
            pid: "211000",
            label: "白塔区",
            value: "211002"
          },
          {
            pid: "211000",
            label: "文圣区",
            value: "211003"
          },
          {
            pid: "211000",
            label: "宏伟区",
            value: "211004"
          },
          {
            pid: "211000",
            label: "弓长岭区",
            value: "211005"
          },
          {
            pid: "211000",
            label: "太子河区",
            value: "211011"
          },
          {
            pid: "211000",
            label: "辽阳县",
            value: "211021"
          },
          {
            pid: "211000",
            label: "灯塔市",
            value: "211081"
          }
        ],
        pid: "210000",
        label: "辽阳市",
        value: "211000"
      },
      {
        children: [
          {
            pid: "211100",
            label: "双台子区",
            value: "211102"
          },
          {
            pid: "211100",
            label: "兴隆台区",
            value: "211103"
          },
          {
            pid: "211100",
            label: "大洼区",
            value: "211104"
          },
          {
            pid: "211100",
            label: "盘山县",
            value: "211122"
          }
        ],
        pid: "210000",
        label: "盘锦市",
        value: "211100"
      },
      {
        children: [
          {
            pid: "211200",
            label: "银州区",
            value: "211202"
          },
          {
            pid: "211200",
            label: "清河区",
            value: "211204"
          },
          {
            pid: "211200",
            label: "铁岭县",
            value: "211221"
          },
          {
            pid: "211200",
            label: "西丰县",
            value: "211223"
          },
          {
            pid: "211200",
            label: "昌图县",
            value: "211224"
          },
          {
            pid: "211200",
            label: "调兵山市",
            value: "211281"
          },
          {
            pid: "211200",
            label: "开原市",
            value: "211282"
          }
        ],
        pid: "210000",
        label: "铁岭市",
        value: "211200"
      },
      {
        children: [
          {
            pid: "211300",
            label: "双塔区",
            value: "211302"
          },
          {
            pid: "211300",
            label: "龙城区",
            value: "211303"
          },
          {
            pid: "211300",
            label: "朝阳县",
            value: "211321"
          },
          {
            pid: "211300",
            label: "建平县",
            value: "211322"
          },
          {
            pid: "211300",
            label: "喀喇沁左翼蒙古族自治县",
            value: "211324"
          },
          {
            pid: "211300",
            label: "北票市",
            value: "211381"
          },
          {
            pid: "211300",
            label: "凌源市",
            value: "211382"
          }
        ],
        pid: "210000",
        label: "朝阳市",
        value: "211300"
      },
      {
        children: [
          {
            pid: "211400",
            label: "连山区",
            value: "211402"
          },
          {
            pid: "211400",
            label: "龙港区",
            value: "211403"
          },
          {
            pid: "211400",
            label: "南票区",
            value: "211404"
          },
          {
            pid: "211400",
            label: "绥中县",
            value: "211421"
          },
          {
            pid: "211400",
            label: "建昌县",
            value: "211422"
          },
          {
            pid: "211400",
            label: "兴城市",
            value: "211481"
          }
        ],
        pid: "210000",
        label: "葫芦岛市",
        value: "211400"
      }
    ],
    pid: "-1",
    label: "辽宁省",
    value: "210000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "220100",
            label: "南关区",
            value: "220102"
          },
          {
            pid: "220100",
            label: "宽城区",
            value: "220103"
          },
          {
            pid: "220100",
            label: "朝阳区",
            value: "220104"
          },
          {
            pid: "220100",
            label: "二道区",
            value: "220105"
          },
          {
            pid: "220100",
            label: "绿园区",
            value: "220106"
          },
          {
            pid: "220100",
            label: "双阳区",
            value: "220112"
          },
          {
            pid: "220100",
            label: "九台区",
            value: "220113"
          },
          {
            pid: "220100",
            label: "农安县",
            value: "220122"
          },
          {
            pid: "220100",
            label: "榆树市",
            value: "220182"
          },
          {
            pid: "220100",
            label: "德惠市",
            value: "220183"
          }
        ],
        pid: "220000",
        label: "长春市",
        value: "220100"
      },
      {
        children: [
          {
            pid: "220200",
            label: "昌邑区",
            value: "220202"
          },
          {
            pid: "220200",
            label: "龙潭区",
            value: "220203"
          },
          {
            pid: "220200",
            label: "船营区",
            value: "220204"
          },
          {
            pid: "220200",
            label: "丰满区",
            value: "220211"
          },
          {
            pid: "220200",
            label: "永吉县",
            value: "220221"
          },
          {
            pid: "220200",
            label: "蛟河市",
            value: "220281"
          },
          {
            pid: "220200",
            label: "桦甸市",
            value: "220282"
          },
          {
            pid: "220200",
            label: "舒兰市",
            value: "220283"
          },
          {
            pid: "220200",
            label: "磐石市",
            value: "220284"
          }
        ],
        pid: "220000",
        label: "吉林市",
        value: "220200"
      },
      {
        children: [
          {
            pid: "220300",
            label: "铁西区",
            value: "220302"
          },
          {
            pid: "220300",
            label: "铁东区",
            value: "220303"
          },
          {
            pid: "220300",
            label: "梨树县",
            value: "220322"
          },
          {
            pid: "220300",
            label: "伊通满族自治县",
            value: "220323"
          },
          {
            pid: "220300",
            label: "公主岭市",
            value: "220381"
          },
          {
            pid: "220300",
            label: "双辽市",
            value: "220382"
          }
        ],
        pid: "220000",
        label: "四平市",
        value: "220300"
      },
      {
        children: [
          {
            pid: "220400",
            label: "龙山区",
            value: "220402"
          },
          {
            pid: "220400",
            label: "西安区",
            value: "220403"
          },
          {
            pid: "220400",
            label: "东丰县",
            value: "220421"
          },
          {
            pid: "220400",
            label: "东辽县",
            value: "220422"
          }
        ],
        pid: "220000",
        label: "辽源市",
        value: "220400"
      },
      {
        children: [
          {
            pid: "220500",
            label: "东昌区",
            value: "220502"
          },
          {
            pid: "220500",
            label: "二道江区",
            value: "220503"
          },
          {
            pid: "220500",
            label: "通化县",
            value: "220521"
          },
          {
            pid: "220500",
            label: "辉南县",
            value: "220523"
          },
          {
            pid: "220500",
            label: "柳河县",
            value: "220524"
          },
          {
            pid: "220500",
            label: "梅河口市",
            value: "220581"
          },
          {
            pid: "220500",
            label: "集安市",
            value: "220582"
          }
        ],
        pid: "220000",
        label: "通化市",
        value: "220500"
      },
      {
        children: [
          {
            pid: "220600",
            label: "浑江区",
            value: "220602"
          },
          {
            pid: "220600",
            label: "江源区",
            value: "220605"
          },
          {
            pid: "220600",
            label: "抚松县",
            value: "220621"
          },
          {
            pid: "220600",
            label: "靖宇县",
            value: "220622"
          },
          {
            pid: "220600",
            label: "长白朝鲜族自治县",
            value: "220623"
          },
          {
            pid: "220600",
            label: "临江市",
            value: "220681"
          }
        ],
        pid: "220000",
        label: "白山市",
        value: "220600"
      },
      {
        children: [
          {
            pid: "220700",
            label: "宁江区",
            value: "220702"
          },
          {
            pid: "220700",
            label: "前郭尔罗斯蒙古族自治县",
            value: "220721"
          },
          {
            pid: "220700",
            label: "长岭县",
            value: "220722"
          },
          {
            pid: "220700",
            label: "乾安县",
            value: "220723"
          },
          {
            pid: "220700",
            label: "扶余市",
            value: "220781"
          }
        ],
        pid: "220000",
        label: "松原市",
        value: "220700"
      },
      {
        children: [
          {
            pid: "220800",
            label: "洮北区",
            value: "220802"
          },
          {
            pid: "220800",
            label: "镇赉县",
            value: "220821"
          },
          {
            pid: "220800",
            label: "通榆县",
            value: "220822"
          },
          {
            pid: "220800",
            label: "洮南市",
            value: "220881"
          },
          {
            pid: "220800",
            label: "大安市",
            value: "220882"
          }
        ],
        pid: "220000",
        label: "白城市",
        value: "220800"
      },
      {
        children: [
          {
            pid: "222400",
            label: "延吉市",
            value: "222401"
          },
          {
            pid: "222400",
            label: "图们市",
            value: "222402"
          },
          {
            pid: "222400",
            label: "敦化市",
            value: "222403"
          },
          {
            pid: "222400",
            label: "珲春市",
            value: "222404"
          },
          {
            pid: "222400",
            label: "龙井市",
            value: "222405"
          },
          {
            pid: "222400",
            label: "和龙市",
            value: "222406"
          },
          {
            pid: "222400",
            label: "汪清县",
            value: "222424"
          },
          {
            pid: "222400",
            label: "安图县",
            value: "222426"
          }
        ],
        pid: "220000",
        label: "延边朝鲜族自治州",
        value: "222400"
      }
    ],
    pid: "-1",
    label: "吉林省",
    value: "220000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "230100",
            label: "道里区",
            value: "230102"
          },
          {
            pid: "230100",
            label: "南岗区",
            value: "230103"
          },
          {
            pid: "230100",
            label: "道外区",
            value: "230104"
          },
          {
            pid: "230100",
            label: "平房区",
            value: "230108"
          },
          {
            pid: "230100",
            label: "松北区",
            value: "230109"
          },
          {
            pid: "230100",
            label: "香坊区",
            value: "230110"
          },
          {
            pid: "230100",
            label: "呼兰区",
            value: "230111"
          },
          {
            pid: "230100",
            label: "阿城区",
            value: "230112"
          },
          {
            pid: "230100",
            label: "双城区",
            value: "230113"
          },
          {
            pid: "230100",
            label: "依兰县",
            value: "230123"
          },
          {
            pid: "230100",
            label: "方正县",
            value: "230124"
          },
          {
            pid: "230100",
            label: "宾县",
            value: "230125"
          },
          {
            pid: "230100",
            label: "巴彦县",
            value: "230126"
          },
          {
            pid: "230100",
            label: "木兰县",
            value: "230127"
          },
          {
            pid: "230100",
            label: "通河县",
            value: "230128"
          },
          {
            pid: "230100",
            label: "延寿县",
            value: "230129"
          },
          {
            pid: "230100",
            label: "尚志市",
            value: "230183"
          },
          {
            pid: "230100",
            label: "五常市",
            value: "230184"
          }
        ],
        pid: "230000",
        label: "哈尔滨市",
        value: "230100"
      },
      {
        children: [
          {
            pid: "230200",
            label: "龙沙区",
            value: "230202"
          },
          {
            pid: "230200",
            label: "建华区",
            value: "230203"
          },
          {
            pid: "230200",
            label: "铁锋区",
            value: "230204"
          },
          {
            pid: "230200",
            label: "昂昂溪区",
            value: "230205"
          },
          {
            pid: "230200",
            label: "富拉尔基区",
            value: "230206"
          },
          {
            pid: "230200",
            label: "碾子山区",
            value: "230207"
          },
          {
            pid: "230200",
            label: "梅里斯达斡尔族区",
            value: "230208"
          },
          {
            pid: "230200",
            label: "龙江县",
            value: "230221"
          },
          {
            pid: "230200",
            label: "依安县",
            value: "230223"
          },
          {
            pid: "230200",
            label: "泰来县",
            value: "230224"
          },
          {
            pid: "230200",
            label: "甘南县",
            value: "230225"
          },
          {
            pid: "230200",
            label: "富裕县",
            value: "230227"
          },
          {
            pid: "230200",
            label: "克山县",
            value: "230229"
          },
          {
            pid: "230200",
            label: "克东县",
            value: "230230"
          },
          {
            pid: "230200",
            label: "拜泉县",
            value: "230231"
          },
          {
            pid: "230200",
            label: "讷河市",
            value: "230281"
          }
        ],
        pid: "230000",
        label: "齐齐哈尔市",
        value: "230200"
      },
      {
        children: [
          {
            pid: "230300",
            label: "鸡冠区",
            value: "230302"
          },
          {
            pid: "230300",
            label: "恒山区",
            value: "230303"
          },
          {
            pid: "230300",
            label: "滴道区",
            value: "230304"
          },
          {
            pid: "230300",
            label: "梨树区",
            value: "230305"
          },
          {
            pid: "230300",
            label: "城子河区",
            value: "230306"
          },
          {
            pid: "230300",
            label: "麻山区",
            value: "230307"
          },
          {
            pid: "230300",
            label: "鸡东县",
            value: "230321"
          },
          {
            pid: "230300",
            label: "虎林市",
            value: "230381"
          },
          {
            pid: "230300",
            label: "密山市",
            value: "230382"
          }
        ],
        pid: "230000",
        label: "鸡西市",
        value: "230300"
      },
      {
        children: [
          {
            pid: "230400",
            label: "向阳区",
            value: "230402"
          },
          {
            pid: "230400",
            label: "工农区",
            value: "230403"
          },
          {
            pid: "230400",
            label: "南山区",
            value: "230404"
          },
          {
            pid: "230400",
            label: "兴安区",
            value: "230405"
          },
          {
            pid: "230400",
            label: "东山区",
            value: "230406"
          },
          {
            pid: "230400",
            label: "兴山区",
            value: "230407"
          },
          {
            pid: "230400",
            label: "萝北县",
            value: "230421"
          },
          {
            pid: "230400",
            label: "绥滨县",
            value: "230422"
          }
        ],
        pid: "230000",
        label: "鹤岗市",
        value: "230400"
      },
      {
        children: [
          {
            pid: "230500",
            label: "尖山区",
            value: "230502"
          },
          {
            pid: "230500",
            label: "岭东区",
            value: "230503"
          },
          {
            pid: "230500",
            label: "四方台区",
            value: "230505"
          },
          {
            pid: "230500",
            label: "宝山区",
            value: "230506"
          },
          {
            pid: "230500",
            label: "集贤县",
            value: "230521"
          },
          {
            pid: "230500",
            label: "友谊县",
            value: "230522"
          },
          {
            pid: "230500",
            label: "宝清县",
            value: "230523"
          },
          {
            pid: "230500",
            label: "饶河县",
            value: "230524"
          }
        ],
        pid: "230000",
        label: "双鸭山市",
        value: "230500"
      },
      {
        children: [
          {
            pid: "230600",
            label: "萨尔图区",
            value: "230602"
          },
          {
            pid: "230600",
            label: "龙凤区",
            value: "230603"
          },
          {
            pid: "230600",
            label: "让胡路区",
            value: "230604"
          },
          {
            pid: "230600",
            label: "红岗区",
            value: "230605"
          },
          {
            pid: "230600",
            label: "大同区",
            value: "230606"
          },
          {
            pid: "230600",
            label: "肇州县",
            value: "230621"
          },
          {
            pid: "230600",
            label: "肇源县",
            value: "230622"
          },
          {
            pid: "230600",
            label: "林甸县",
            value: "230623"
          },
          {
            pid: "230600",
            label: "杜尔伯特蒙古族自治县",
            value: "230624"
          }
        ],
        pid: "230000",
        label: "大庆市",
        value: "230600"
      },
      {
        children: [
          {
            pid: "230700",
            label: "伊春区",
            value: "230702"
          },
          {
            pid: "230700",
            label: "南岔区",
            value: "230703"
          },
          {
            pid: "230700",
            label: "友好区",
            value: "230704"
          },
          {
            pid: "230700",
            label: "西林区",
            value: "230705"
          },
          {
            pid: "230700",
            label: "翠峦区",
            value: "230706"
          },
          {
            pid: "230700",
            label: "新青区",
            value: "230707"
          },
          {
            pid: "230700",
            label: "美溪区",
            value: "230708"
          },
          {
            pid: "230700",
            label: "金山屯区",
            value: "230709"
          },
          {
            pid: "230700",
            label: "五营区",
            value: "230710"
          },
          {
            pid: "230700",
            label: "乌马河区",
            value: "230711"
          },
          {
            pid: "230700",
            label: "汤旺河区",
            value: "230712"
          },
          {
            pid: "230700",
            label: "带岭区",
            value: "230713"
          },
          {
            pid: "230700",
            label: "乌伊岭区",
            value: "230714"
          },
          {
            pid: "230700",
            label: "红星区",
            value: "230715"
          },
          {
            pid: "230700",
            label: "上甘岭区",
            value: "230716"
          },
          {
            pid: "230700",
            label: "嘉荫县",
            value: "230722"
          },
          {
            pid: "230700",
            label: "铁力市",
            value: "230781"
          }
        ],
        pid: "230000",
        label: "伊春市",
        value: "230700"
      },
      {
        children: [
          {
            pid: "230800",
            label: "向阳区",
            value: "230803"
          },
          {
            pid: "230800",
            label: "前进区",
            value: "230804"
          },
          {
            pid: "230800",
            label: "东风区",
            value: "230805"
          },
          {
            pid: "230800",
            label: "郊区",
            value: "230811"
          },
          {
            pid: "230800",
            label: "桦南县",
            value: "230822"
          },
          {
            pid: "230800",
            label: "桦川县",
            value: "230826"
          },
          {
            pid: "230800",
            label: "汤原县",
            value: "230828"
          },
          {
            pid: "230800",
            label: "同江市",
            value: "230881"
          },
          {
            pid: "230800",
            label: "富锦市",
            value: "230882"
          },
          {
            pid: "230800",
            label: "抚远市",
            value: "230883"
          }
        ],
        pid: "230000",
        label: "佳木斯市",
        value: "230800"
      },
      {
        children: [
          {
            pid: "230900",
            label: "新兴区",
            value: "230902"
          },
          {
            pid: "230900",
            label: "桃山区",
            value: "230903"
          },
          {
            pid: "230900",
            label: "茄子河区",
            value: "230904"
          },
          {
            pid: "230900",
            label: "勃利县",
            value: "230921"
          }
        ],
        pid: "230000",
        label: "七台河市",
        value: "230900"
      },
      {
        children: [
          {
            pid: "231000",
            label: "东安区",
            value: "231002"
          },
          {
            pid: "231000",
            label: "阳明区",
            value: "231003"
          },
          {
            pid: "231000",
            label: "爱民区",
            value: "231004"
          },
          {
            pid: "231000",
            label: "西安区",
            value: "231005"
          },
          {
            pid: "231000",
            label: "林口县",
            value: "231025"
          },
          {
            pid: "231000",
            label: "绥芬河市",
            value: "231081"
          },
          {
            pid: "231000",
            label: "海林市",
            value: "231083"
          },
          {
            pid: "231000",
            label: "宁安市",
            value: "231084"
          },
          {
            pid: "231000",
            label: "穆棱市",
            value: "231085"
          },
          {
            pid: "231000",
            label: "东宁市",
            value: "231086"
          }
        ],
        pid: "230000",
        label: "牡丹江市",
        value: "231000"
      },
      {
        children: [
          {
            pid: "231100",
            label: "爱辉区",
            value: "231102"
          },
          {
            pid: "231100",
            label: "嫩江县",
            value: "231121"
          },
          {
            pid: "231100",
            label: "逊克县",
            value: "231123"
          },
          {
            pid: "231100",
            label: "孙吴县",
            value: "231124"
          },
          {
            pid: "231100",
            label: "北安市",
            value: "231181"
          },
          {
            pid: "231100",
            label: "五大连池市",
            value: "231182"
          }
        ],
        pid: "230000",
        label: "黑河市",
        value: "231100"
      },
      {
        children: [
          {
            pid: "231200",
            label: "北林区",
            value: "231202"
          },
          {
            pid: "231200",
            label: "望奎县",
            value: "231221"
          },
          {
            pid: "231200",
            label: "兰西县",
            value: "231222"
          },
          {
            pid: "231200",
            label: "青冈县",
            value: "231223"
          },
          {
            pid: "231200",
            label: "庆安县",
            value: "231224"
          },
          {
            pid: "231200",
            label: "明水县",
            value: "231225"
          },
          {
            pid: "231200",
            label: "绥棱县",
            value: "231226"
          },
          {
            pid: "231200",
            label: "安达市",
            value: "231281"
          },
          {
            pid: "231200",
            label: "肇东市",
            value: "231282"
          },
          {
            pid: "231200",
            label: "海伦市",
            value: "231283"
          }
        ],
        pid: "230000",
        label: "绥化市",
        value: "231200"
      },
      {
        children: [
          {
            pid: "232700",
            label: "漠河市",
            value: "232701"
          },
          {
            pid: "232700",
            label: "呼玛县",
            value: "232721"
          },
          {
            pid: "232700",
            label: "塔河县",
            value: "232722"
          }
        ],
        pid: "230000",
        label: "大兴安岭地区",
        value: "232700"
      }
    ],
    pid: "-1",
    label: "黑龙江省",
    value: "230000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "310100",
            label: "黄浦区",
            value: "310101"
          },
          {
            pid: "310100",
            label: "徐汇区",
            value: "310104"
          },
          {
            pid: "310100",
            label: "长宁区",
            value: "310105"
          },
          {
            pid: "310100",
            label: "静安区",
            value: "310106"
          },
          {
            pid: "310100",
            label: "普陀区",
            value: "310107"
          },
          {
            pid: "310100",
            label: "虹口区",
            value: "310109"
          },
          {
            pid: "310100",
            label: "杨浦区",
            value: "310110"
          },
          {
            pid: "310100",
            label: "闵行区",
            value: "310112"
          },
          {
            pid: "310100",
            label: "宝山区",
            value: "310113"
          },
          {
            pid: "310100",
            label: "嘉定区",
            value: "310114"
          },
          {
            pid: "310100",
            label: "浦东新区",
            value: "310115"
          },
          {
            pid: "310100",
            label: "金山区",
            value: "310116"
          },
          {
            pid: "310100",
            label: "松江区",
            value: "310117"
          },
          {
            pid: "310100",
            label: "青浦区",
            value: "310118"
          },
          {
            pid: "310100",
            label: "奉贤区",
            value: "310120"
          },
          {
            pid: "310100",
            label: "崇明区",
            value: "310151"
          }
        ],
        pid: "310000",
        label: "上海市",
        value: "310100"
      }
    ],
    pid: "-1",
    label: "上海市",
    value: "310000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "320100",
            label: "玄武区",
            value: "320102"
          },
          {
            pid: "320100",
            label: "秦淮区",
            value: "320104"
          },
          {
            pid: "320100",
            label: "建邺区",
            value: "320105"
          },
          {
            pid: "320100",
            label: "鼓楼区",
            value: "320106"
          },
          {
            pid: "320100",
            label: "浦口区",
            value: "320111"
          },
          {
            pid: "320100",
            label: "栖霞区",
            value: "320113"
          },
          {
            pid: "320100",
            label: "雨花台区",
            value: "320114"
          },
          {
            pid: "320100",
            label: "江宁区",
            value: "320115"
          },
          {
            pid: "320100",
            label: "六合区",
            value: "320116"
          },
          {
            pid: "320100",
            label: "溧水区",
            value: "320117"
          },
          {
            pid: "320100",
            label: "高淳区",
            value: "320118"
          }
        ],
        pid: "320000",
        label: "南京市",
        value: "320100"
      },
      {
        children: [
          {
            pid: "320200",
            label: "锡山区",
            value: "320205"
          },
          {
            pid: "320200",
            label: "惠山区",
            value: "320206"
          },
          {
            pid: "320200",
            label: "滨湖区",
            value: "320211"
          },
          {
            pid: "320200",
            label: "梁溪区",
            value: "320213"
          },
          {
            pid: "320200",
            label: "新吴区",
            value: "320214"
          },
          {
            pid: "320200",
            label: "江阴市",
            value: "320281"
          },
          {
            pid: "320200",
            label: "宜兴市",
            value: "320282"
          }
        ],
        pid: "320000",
        label: "无锡市",
        value: "320200"
      },
      {
        children: [
          {
            pid: "320300",
            label: "鼓楼区",
            value: "320302"
          },
          {
            pid: "320300",
            label: "云龙区",
            value: "320303"
          },
          {
            pid: "320300",
            label: "贾汪区",
            value: "320305"
          },
          {
            pid: "320300",
            label: "泉山区",
            value: "320311"
          },
          {
            pid: "320300",
            label: "铜山区",
            value: "320312"
          },
          {
            pid: "320300",
            label: "丰县",
            value: "320321"
          },
          {
            pid: "320300",
            label: "沛县",
            value: "320322"
          },
          {
            pid: "320300",
            label: "睢宁县",
            value: "320324"
          },
          {
            pid: "320300",
            label: "新沂市",
            value: "320381"
          },
          {
            pid: "320300",
            label: "邳州市",
            value: "320382"
          }
        ],
        pid: "320000",
        label: "徐州市",
        value: "320300"
      },
      {
        children: [
          {
            pid: "320400",
            label: "天宁区",
            value: "320402"
          },
          {
            pid: "320400",
            label: "钟楼区",
            value: "320404"
          },
          {
            pid: "320400",
            label: "新北区",
            value: "320411"
          },
          {
            pid: "320400",
            label: "武进区",
            value: "320412"
          },
          {
            pid: "320400",
            label: "金坛区",
            value: "320413"
          },
          {
            pid: "320400",
            label: "溧阳市",
            value: "320481"
          }
        ],
        pid: "320000",
        label: "常州市",
        value: "320400"
      },
      {
        children: [
          {
            pid: "320500",
            label: "虎丘区",
            value: "320505"
          },
          {
            pid: "320500",
            label: "吴中区",
            value: "320506"
          },
          {
            pid: "320500",
            label: "相城区",
            value: "320507"
          },
          {
            pid: "320500",
            label: "姑苏区",
            value: "320508"
          },
          {
            pid: "320500",
            label: "吴江区",
            value: "320509"
          },
          {
            pid: "320500",
            label: "常熟市",
            value: "320581"
          },
          {
            pid: "320500",
            label: "张家港市",
            value: "320582"
          },
          {
            pid: "320500",
            label: "昆山市",
            value: "320583"
          },
          {
            pid: "320500",
            label: "太仓市",
            value: "320585"
          }
        ],
        pid: "320000",
        label: "苏州市",
        value: "320500"
      },
      {
        children: [
          {
            pid: "320600",
            label: "崇川区",
            value: "320602"
          },
          {
            pid: "320600",
            label: "港闸区",
            value: "320611"
          },
          {
            pid: "320600",
            label: "通州区",
            value: "320612"
          },
          {
            pid: "320600",
            label: "如东县",
            value: "320623"
          },
          {
            pid: "320600",
            label: "启东市",
            value: "320681"
          },
          {
            pid: "320600",
            label: "如皋市",
            value: "320682"
          },
          {
            pid: "320600",
            label: "海门市",
            value: "320684"
          },
          {
            pid: "320600",
            label: "海安市",
            value: "320685"
          }
        ],
        pid: "320000",
        label: "南通市",
        value: "320600"
      },
      {
        children: [
          {
            pid: "320700",
            label: "连云区",
            value: "320703"
          },
          {
            pid: "320700",
            label: "海州区",
            value: "320706"
          },
          {
            pid: "320700",
            label: "赣榆区",
            value: "320707"
          },
          {
            pid: "320700",
            label: "东海县",
            value: "320722"
          },
          {
            pid: "320700",
            label: "灌云县",
            value: "320723"
          },
          {
            pid: "320700",
            label: "灌南县",
            value: "320724"
          }
        ],
        pid: "320000",
        label: "连云港市",
        value: "320700"
      },
      {
        children: [
          {
            pid: "320800",
            label: "淮安区",
            value: "320803"
          },
          {
            pid: "320800",
            label: "淮阴区",
            value: "320804"
          },
          {
            pid: "320800",
            label: "清江浦区",
            value: "320812"
          },
          {
            pid: "320800",
            label: "洪泽区",
            value: "320813"
          },
          {
            pid: "320800",
            label: "涟水县",
            value: "320826"
          },
          {
            pid: "320800",
            label: "盱眙县",
            value: "320830"
          },
          {
            pid: "320800",
            label: "金湖县",
            value: "320831"
          }
        ],
        pid: "320000",
        label: "淮安市",
        value: "320800"
      },
      {
        children: [
          {
            pid: "320900",
            label: "亭湖区",
            value: "320902"
          },
          {
            pid: "320900",
            label: "盐都区",
            value: "320903"
          },
          {
            pid: "320900",
            label: "大丰区",
            value: "320904"
          },
          {
            pid: "320900",
            label: "响水县",
            value: "320921"
          },
          {
            pid: "320900",
            label: "滨海县",
            value: "320922"
          },
          {
            pid: "320900",
            label: "阜宁县",
            value: "320923"
          },
          {
            pid: "320900",
            label: "射阳县",
            value: "320924"
          },
          {
            pid: "320900",
            label: "建湖县",
            value: "320925"
          },
          {
            pid: "320900",
            label: "东台市",
            value: "320981"
          }
        ],
        pid: "320000",
        label: "盐城市",
        value: "320900"
      },
      {
        children: [
          {
            pid: "321000",
            label: "广陵区",
            value: "321002"
          },
          {
            pid: "321000",
            label: "邗江区",
            value: "321003"
          },
          {
            pid: "321000",
            label: "江都区",
            value: "321012"
          },
          {
            pid: "321000",
            label: "宝应县",
            value: "321023"
          },
          {
            pid: "321000",
            label: "仪征市",
            value: "321081"
          },
          {
            pid: "321000",
            label: "高邮市",
            value: "321084"
          }
        ],
        pid: "320000",
        label: "扬州市",
        value: "321000"
      },
      {
        children: [
          {
            pid: "321100",
            label: "京口区",
            value: "321102"
          },
          {
            pid: "321100",
            label: "润州区",
            value: "321111"
          },
          {
            pid: "321100",
            label: "丹徒区",
            value: "321112"
          },
          {
            pid: "321100",
            label: "丹阳市",
            value: "321181"
          },
          {
            pid: "321100",
            label: "扬中市",
            value: "321182"
          },
          {
            pid: "321100",
            label: "句容市",
            value: "321183"
          }
        ],
        pid: "320000",
        label: "镇江市",
        value: "321100"
      },
      {
        children: [
          {
            pid: "321200",
            label: "海陵区",
            value: "321202"
          },
          {
            pid: "321200",
            label: "高港区",
            value: "321203"
          },
          {
            pid: "321200",
            label: "姜堰区",
            value: "321204"
          },
          {
            pid: "321200",
            label: "兴化市",
            value: "321281"
          },
          {
            pid: "321200",
            label: "靖江市",
            value: "321282"
          },
          {
            pid: "321200",
            label: "泰兴市",
            value: "321283"
          }
        ],
        pid: "320000",
        label: "泰州市",
        value: "321200"
      },
      {
        children: [
          {
            pid: "321300",
            label: "宿城区",
            value: "321302"
          },
          {
            pid: "321300",
            label: "宿豫区",
            value: "321311"
          },
          {
            pid: "321300",
            label: "沭阳县",
            value: "321322"
          },
          {
            pid: "321300",
            label: "泗阳县",
            value: "321323"
          },
          {
            pid: "321300",
            label: "泗洪县",
            value: "321324"
          }
        ],
        pid: "320000",
        label: "宿迁市",
        value: "321300"
      }
    ],
    pid: "-1",
    label: "江苏省",
    value: "320000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "330100",
            label: "上城区",
            value: "330102"
          },
          {
            pid: "330100",
            label: "下城区",
            value: "330103"
          },
          {
            pid: "330100",
            label: "江干区",
            value: "330104"
          },
          {
            pid: "330100",
            label: "拱墅区",
            value: "330105"
          },
          {
            pid: "330100",
            label: "西湖区",
            value: "330106"
          },
          {
            pid: "330100",
            label: "滨江区",
            value: "330108"
          },
          {
            pid: "330100",
            label: "萧山区",
            value: "330109"
          },
          {
            pid: "330100",
            label: "余杭区",
            value: "330110"
          },
          {
            pid: "330100",
            label: "富阳区",
            value: "330111"
          },
          {
            pid: "330100",
            label: "临安区",
            value: "330112"
          },
          {
            pid: "330100",
            label: "桐庐县",
            value: "330122"
          },
          {
            pid: "330100",
            label: "淳安县",
            value: "330127"
          },
          {
            pid: "330100",
            label: "建德市",
            value: "330182"
          }
        ],
        pid: "330000",
        label: "杭州市",
        value: "330100"
      },
      {
        children: [
          {
            pid: "330200",
            label: "海曙区",
            value: "330203"
          },
          {
            pid: "330200",
            label: "江北区",
            value: "330205"
          },
          {
            pid: "330200",
            label: "北仑区",
            value: "330206"
          },
          {
            pid: "330200",
            label: "镇海区",
            value: "330211"
          },
          {
            pid: "330200",
            label: "鄞州区",
            value: "330212"
          },
          {
            pid: "330200",
            label: "奉化区",
            value: "330213"
          },
          {
            pid: "330200",
            label: "象山县",
            value: "330225"
          },
          {
            pid: "330200",
            label: "宁海县",
            value: "330226"
          },
          {
            pid: "330200",
            label: "余姚市",
            value: "330281"
          },
          {
            pid: "330200",
            label: "慈溪市",
            value: "330282"
          }
        ],
        pid: "330000",
        label: "宁波市",
        value: "330200"
      },
      {
        children: [
          {
            pid: "330300",
            label: "鹿城区",
            value: "330302"
          },
          {
            pid: "330300",
            label: "龙湾区",
            value: "330303"
          },
          {
            pid: "330300",
            label: "瓯海区",
            value: "330304"
          },
          {
            pid: "330300",
            label: "洞头区",
            value: "330305"
          },
          {
            pid: "330300",
            label: "永嘉县",
            value: "330324"
          },
          {
            pid: "330300",
            label: "平阳县",
            value: "330326"
          },
          {
            pid: "330300",
            label: "苍南县",
            value: "330327"
          },
          {
            pid: "330300",
            label: "文成县",
            value: "330328"
          },
          {
            pid: "330300",
            label: "泰顺县",
            value: "330329"
          },
          {
            pid: "330300",
            label: "瑞安市",
            value: "330381"
          },
          {
            pid: "330300",
            label: "乐清市",
            value: "330382"
          }
        ],
        pid: "330000",
        label: "温州市",
        value: "330300"
      },
      {
        children: [
          {
            pid: "330400",
            label: "南湖区",
            value: "330402"
          },
          {
            pid: "330400",
            label: "秀洲区",
            value: "330411"
          },
          {
            pid: "330400",
            label: "嘉善县",
            value: "330421"
          },
          {
            pid: "330400",
            label: "海盐县",
            value: "330424"
          },
          {
            pid: "330400",
            label: "海宁市",
            value: "330481"
          },
          {
            pid: "330400",
            label: "平湖市",
            value: "330482"
          },
          {
            pid: "330400",
            label: "桐乡市",
            value: "330483"
          }
        ],
        pid: "330000",
        label: "嘉兴市",
        value: "330400"
      },
      {
        children: [
          {
            pid: "330500",
            label: "吴兴区",
            value: "330502"
          },
          {
            pid: "330500",
            label: "南浔区",
            value: "330503"
          },
          {
            pid: "330500",
            label: "德清县",
            value: "330521"
          },
          {
            pid: "330500",
            label: "长兴县",
            value: "330522"
          },
          {
            pid: "330500",
            label: "安吉县",
            value: "330523"
          }
        ],
        pid: "330000",
        label: "湖州市",
        value: "330500"
      },
      {
        children: [
          {
            pid: "330600",
            label: "越城区",
            value: "330602"
          },
          {
            pid: "330600",
            label: "柯桥区",
            value: "330603"
          },
          {
            pid: "330600",
            label: "上虞区",
            value: "330604"
          },
          {
            pid: "330600",
            label: "新昌县",
            value: "330624"
          },
          {
            pid: "330600",
            label: "诸暨市",
            value: "330681"
          },
          {
            pid: "330600",
            label: "嵊州市",
            value: "330683"
          }
        ],
        pid: "330000",
        label: "绍兴市",
        value: "330600"
      },
      {
        children: [
          {
            pid: "330700",
            label: "婺城区",
            value: "330702"
          },
          {
            pid: "330700",
            label: "金东区",
            value: "330703"
          },
          {
            pid: "330700",
            label: "武义县",
            value: "330723"
          },
          {
            pid: "330700",
            label: "浦江县",
            value: "330726"
          },
          {
            pid: "330700",
            label: "磐安县",
            value: "330727"
          },
          {
            pid: "330700",
            label: "兰溪市",
            value: "330781"
          },
          {
            pid: "330700",
            label: "义乌市",
            value: "330782"
          },
          {
            pid: "330700",
            label: "东阳市",
            value: "330783"
          },
          {
            pid: "330700",
            label: "永康市",
            value: "330784"
          }
        ],
        pid: "330000",
        label: "金华市",
        value: "330700"
      },
      {
        children: [
          {
            pid: "330800",
            label: "柯城区",
            value: "330802"
          },
          {
            pid: "330800",
            label: "衢江区",
            value: "330803"
          },
          {
            pid: "330800",
            label: "常山县",
            value: "330822"
          },
          {
            pid: "330800",
            label: "开化县",
            value: "330824"
          },
          {
            pid: "330800",
            label: "龙游县",
            value: "330825"
          },
          {
            pid: "330800",
            label: "江山市",
            value: "330881"
          }
        ],
        pid: "330000",
        label: "衢州市",
        value: "330800"
      },
      {
        children: [
          {
            pid: "330900",
            label: "定海区",
            value: "330902"
          },
          {
            pid: "330900",
            label: "普陀区",
            value: "330903"
          },
          {
            pid: "330900",
            label: "岱山县",
            value: "330921"
          },
          {
            pid: "330900",
            label: "嵊泗县",
            value: "330922"
          }
        ],
        pid: "330000",
        label: "舟山市",
        value: "330900"
      },
      {
        children: [
          {
            pid: "331000",
            label: "椒江区",
            value: "331002"
          },
          {
            pid: "331000",
            label: "黄岩区",
            value: "331003"
          },
          {
            pid: "331000",
            label: "路桥区",
            value: "331004"
          },
          {
            pid: "331000",
            label: "三门县",
            value: "331022"
          },
          {
            pid: "331000",
            label: "天台县",
            value: "331023"
          },
          {
            pid: "331000",
            label: "仙居县",
            value: "331024"
          },
          {
            pid: "331000",
            label: "温岭市",
            value: "331081"
          },
          {
            pid: "331000",
            label: "临海市",
            value: "331082"
          },
          {
            pid: "331000",
            label: "玉环市",
            value: "331083"
          }
        ],
        pid: "330000",
        label: "台州市",
        value: "331000"
      },
      {
        children: [
          {
            pid: "331100",
            label: "莲都区",
            value: "331102"
          },
          {
            pid: "331100",
            label: "青田县",
            value: "331121"
          },
          {
            pid: "331100",
            label: "缙云县",
            value: "331122"
          },
          {
            pid: "331100",
            label: "遂昌县",
            value: "331123"
          },
          {
            pid: "331100",
            label: "松阳县",
            value: "331124"
          },
          {
            pid: "331100",
            label: "云和县",
            value: "331125"
          },
          {
            pid: "331100",
            label: "庆元县",
            value: "331126"
          },
          {
            pid: "331100",
            label: "景宁畲族自治县",
            value: "331127"
          },
          {
            pid: "331100",
            label: "龙泉市",
            value: "331181"
          }
        ],
        pid: "330000",
        label: "丽水市",
        value: "331100"
      }
    ],
    pid: "-1",
    label: "浙江省",
    value: "330000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "340100",
            label: "瑶海区",
            value: "340102"
          },
          {
            pid: "340100",
            label: "庐阳区",
            value: "340103"
          },
          {
            pid: "340100",
            label: "蜀山区",
            value: "340104"
          },
          {
            pid: "340100",
            label: "包河区",
            value: "340111"
          },
          {
            pid: "340100",
            label: "长丰县",
            value: "340121"
          },
          {
            pid: "340100",
            label: "肥东县",
            value: "340122"
          },
          {
            pid: "340100",
            label: "肥西县",
            value: "340123"
          },
          {
            pid: "340100",
            label: "庐江县",
            value: "340124"
          },
          {
            pid: "340100",
            label: "巢湖市",
            value: "340181"
          }
        ],
        pid: "340000",
        label: "合肥市",
        value: "340100"
      },
      {
        children: [
          {
            pid: "340200",
            label: "镜湖区",
            value: "340202"
          },
          {
            pid: "340200",
            label: "弋江区",
            value: "340203"
          },
          {
            pid: "340200",
            label: "鸠江区",
            value: "340207"
          },
          {
            pid: "340200",
            label: "三山区",
            value: "340208"
          },
          {
            pid: "340200",
            label: "芜湖县",
            value: "340221"
          },
          {
            pid: "340200",
            label: "繁昌县",
            value: "340222"
          },
          {
            pid: "340200",
            label: "南陵县",
            value: "340223"
          },
          {
            pid: "340200",
            label: "无为县",
            value: "340225"
          }
        ],
        pid: "340000",
        label: "芜湖市",
        value: "340200"
      },
      {
        children: [
          {
            pid: "340300",
            label: "龙子湖区",
            value: "340302"
          },
          {
            pid: "340300",
            label: "蚌山区",
            value: "340303"
          },
          {
            pid: "340300",
            label: "禹会区",
            value: "340304"
          },
          {
            pid: "340300",
            label: "淮上区",
            value: "340311"
          },
          {
            pid: "340300",
            label: "怀远县",
            value: "340321"
          },
          {
            pid: "340300",
            label: "五河县",
            value: "340322"
          },
          {
            pid: "340300",
            label: "固镇县",
            value: "340323"
          }
        ],
        pid: "340000",
        label: "蚌埠市",
        value: "340300"
      },
      {
        children: [
          {
            pid: "340400",
            label: "大通区",
            value: "340402"
          },
          {
            pid: "340400",
            label: "田家庵区",
            value: "340403"
          },
          {
            pid: "340400",
            label: "谢家集区",
            value: "340404"
          },
          {
            pid: "340400",
            label: "八公山区",
            value: "340405"
          },
          {
            pid: "340400",
            label: "潘集区",
            value: "340406"
          },
          {
            pid: "340400",
            label: "凤台县",
            value: "340421"
          },
          {
            pid: "340400",
            label: "寿县",
            value: "340422"
          }
        ],
        pid: "340000",
        label: "淮南市",
        value: "340400"
      },
      {
        children: [
          {
            pid: "340500",
            label: "花山区",
            value: "340503"
          },
          {
            pid: "340500",
            label: "雨山区",
            value: "340504"
          },
          {
            pid: "340500",
            label: "博望区",
            value: "340506"
          },
          {
            pid: "340500",
            label: "当涂县",
            value: "340521"
          },
          {
            pid: "340500",
            label: "含山县",
            value: "340522"
          },
          {
            pid: "340500",
            label: "和县",
            value: "340523"
          }
        ],
        pid: "340000",
        label: "马鞍山市",
        value: "340500"
      },
      {
        children: [
          {
            pid: "340600",
            label: "杜集区",
            value: "340602"
          },
          {
            pid: "340600",
            label: "相山区",
            value: "340603"
          },
          {
            pid: "340600",
            label: "烈山区",
            value: "340604"
          },
          {
            pid: "340600",
            label: "濉溪县",
            value: "340621"
          }
        ],
        pid: "340000",
        label: "淮北市",
        value: "340600"
      },
      {
        children: [
          {
            pid: "340700",
            label: "铜官区",
            value: "340705"
          },
          {
            pid: "340700",
            label: "义安区",
            value: "340706"
          },
          {
            pid: "340700",
            label: "郊区",
            value: "340711"
          },
          {
            pid: "340700",
            label: "枞阳县",
            value: "340722"
          }
        ],
        pid: "340000",
        label: "铜陵市",
        value: "340700"
      },
      {
        children: [
          {
            pid: "340800",
            label: "迎江区",
            value: "340802"
          },
          {
            pid: "340800",
            label: "大观区",
            value: "340803"
          },
          {
            pid: "340800",
            label: "宜秀区",
            value: "340811"
          },
          {
            pid: "340800",
            label: "怀宁县",
            value: "340822"
          },
          {
            pid: "340800",
            label: "太湖县",
            value: "340825"
          },
          {
            pid: "340800",
            label: "宿松县",
            value: "340826"
          },
          {
            pid: "340800",
            label: "望江县",
            value: "340827"
          },
          {
            pid: "340800",
            label: "岳西县",
            value: "340828"
          },
          {
            pid: "340800",
            label: "桐城市",
            value: "340881"
          },
          {
            pid: "340800",
            label: "潜山市",
            value: "340882"
          }
        ],
        pid: "340000",
        label: "安庆市",
        value: "340800"
      },
      {
        children: [
          {
            pid: "341000",
            label: "屯溪区",
            value: "341002"
          },
          {
            pid: "341000",
            label: "黄山区",
            value: "341003"
          },
          {
            pid: "341000",
            label: "徽州区",
            value: "341004"
          },
          {
            pid: "341000",
            label: "歙县",
            value: "341021"
          },
          {
            pid: "341000",
            label: "休宁县",
            value: "341022"
          },
          {
            pid: "341000",
            label: "黟县",
            value: "341023"
          },
          {
            pid: "341000",
            label: "祁门县",
            value: "341024"
          }
        ],
        pid: "340000",
        label: "黄山市",
        value: "341000"
      },
      {
        children: [
          {
            pid: "341100",
            label: "琅琊区",
            value: "341102"
          },
          {
            pid: "341100",
            label: "南谯区",
            value: "341103"
          },
          {
            pid: "341100",
            label: "来安县",
            value: "341122"
          },
          {
            pid: "341100",
            label: "全椒县",
            value: "341124"
          },
          {
            pid: "341100",
            label: "定远县",
            value: "341125"
          },
          {
            pid: "341100",
            label: "凤阳县",
            value: "341126"
          },
          {
            pid: "341100",
            label: "天长市",
            value: "341181"
          },
          {
            pid: "341100",
            label: "明光市",
            value: "341182"
          }
        ],
        pid: "340000",
        label: "滁州市",
        value: "341100"
      },
      {
        children: [
          {
            pid: "341200",
            label: "颍州区",
            value: "341202"
          },
          {
            pid: "341200",
            label: "颍东区",
            value: "341203"
          },
          {
            pid: "341200",
            label: "颍泉区",
            value: "341204"
          },
          {
            pid: "341200",
            label: "临泉县",
            value: "341221"
          },
          {
            pid: "341200",
            label: "太和县",
            value: "341222"
          },
          {
            pid: "341200",
            label: "阜南县",
            value: "341225"
          },
          {
            pid: "341200",
            label: "颍上县",
            value: "341226"
          },
          {
            pid: "341200",
            label: "界首市",
            value: "341282"
          }
        ],
        pid: "340000",
        label: "阜阳市",
        value: "341200"
      },
      {
        children: [
          {
            pid: "341300",
            label: "埇桥区",
            value: "341302"
          },
          {
            pid: "341300",
            label: "砀山县",
            value: "341321"
          },
          {
            pid: "341300",
            label: "萧县",
            value: "341322"
          },
          {
            pid: "341300",
            label: "灵璧县",
            value: "341323"
          },
          {
            pid: "341300",
            label: "泗县",
            value: "341324"
          }
        ],
        pid: "340000",
        label: "宿州市",
        value: "341300"
      },
      {
        children: [
          {
            pid: "341500",
            label: "金安区",
            value: "341502"
          },
          {
            pid: "341500",
            label: "裕安区",
            value: "341503"
          },
          {
            pid: "341500",
            label: "叶集区",
            value: "341504"
          },
          {
            pid: "341500",
            label: "霍邱县",
            value: "341522"
          },
          {
            pid: "341500",
            label: "舒城县",
            value: "341523"
          },
          {
            pid: "341500",
            label: "金寨县",
            value: "341524"
          },
          {
            pid: "341500",
            label: "霍山县",
            value: "341525"
          }
        ],
        pid: "340000",
        label: "六安市",
        value: "341500"
      },
      {
        children: [
          {
            pid: "341600",
            label: "谯城区",
            value: "341602"
          },
          {
            pid: "341600",
            label: "涡阳县",
            value: "341621"
          },
          {
            pid: "341600",
            label: "蒙城县",
            value: "341622"
          },
          {
            pid: "341600",
            label: "利辛县",
            value: "341623"
          }
        ],
        pid: "340000",
        label: "亳州市",
        value: "341600"
      },
      {
        children: [
          {
            pid: "341700",
            label: "贵池区",
            value: "341702"
          },
          {
            pid: "341700",
            label: "东至县",
            value: "341721"
          },
          {
            pid: "341700",
            label: "石台县",
            value: "341722"
          },
          {
            pid: "341700",
            label: "青阳县",
            value: "341723"
          }
        ],
        pid: "340000",
        label: "池州市",
        value: "341700"
      },
      {
        children: [
          {
            pid: "341800",
            label: "宣州区",
            value: "341802"
          },
          {
            pid: "341800",
            label: "郎溪县",
            value: "341821"
          },
          {
            pid: "341800",
            label: "广德县",
            value: "341822"
          },
          {
            pid: "341800",
            label: "泾县",
            value: "341823"
          },
          {
            pid: "341800",
            label: "绩溪县",
            value: "341824"
          },
          {
            pid: "341800",
            label: "旌德县",
            value: "341825"
          },
          {
            pid: "341800",
            label: "宁国市",
            value: "341881"
          }
        ],
        pid: "340000",
        label: "宣城市",
        value: "341800"
      }
    ],
    pid: "-1",
    label: "安徽省",
    value: "340000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "350100",
            label: "鼓楼区",
            value: "350102"
          },
          {
            pid: "350100",
            label: "台江区",
            value: "350103"
          },
          {
            pid: "350100",
            label: "仓山区",
            value: "350104"
          },
          {
            pid: "350100",
            label: "马尾区",
            value: "350105"
          },
          {
            pid: "350100",
            label: "晋安区",
            value: "350111"
          },
          {
            pid: "350100",
            label: "长乐区",
            value: "350112"
          },
          {
            pid: "350100",
            label: "闽侯县",
            value: "350121"
          },
          {
            pid: "350100",
            label: "连江县",
            value: "350122"
          },
          {
            pid: "350100",
            label: "罗源县",
            value: "350123"
          },
          {
            pid: "350100",
            label: "闽清县",
            value: "350124"
          },
          {
            pid: "350100",
            label: "永泰县",
            value: "350125"
          },
          {
            pid: "350100",
            label: "平潭县",
            value: "350128"
          },
          {
            pid: "350100",
            label: "福清市",
            value: "350181"
          }
        ],
        pid: "350000",
        label: "福州市",
        value: "350100"
      },
      {
        children: [
          {
            pid: "350200",
            label: "思明区",
            value: "350203"
          },
          {
            pid: "350200",
            label: "海沧区",
            value: "350205"
          },
          {
            pid: "350200",
            label: "湖里区",
            value: "350206"
          },
          {
            pid: "350200",
            label: "集美区",
            value: "350211"
          },
          {
            pid: "350200",
            label: "同安区",
            value: "350212"
          },
          {
            pid: "350200",
            label: "翔安区",
            value: "350213"
          }
        ],
        pid: "350000",
        label: "厦门市",
        value: "350200"
      },
      {
        children: [
          {
            pid: "350300",
            label: "城厢区",
            value: "350302"
          },
          {
            pid: "350300",
            label: "涵江区",
            value: "350303"
          },
          {
            pid: "350300",
            label: "荔城区",
            value: "350304"
          },
          {
            pid: "350300",
            label: "秀屿区",
            value: "350305"
          },
          {
            pid: "350300",
            label: "仙游县",
            value: "350322"
          }
        ],
        pid: "350000",
        label: "莆田市",
        value: "350300"
      },
      {
        children: [
          {
            pid: "350400",
            label: "梅列区",
            value: "350402"
          },
          {
            pid: "350400",
            label: "三元区",
            value: "350403"
          },
          {
            pid: "350400",
            label: "明溪县",
            value: "350421"
          },
          {
            pid: "350400",
            label: "清流县",
            value: "350423"
          },
          {
            pid: "350400",
            label: "宁化县",
            value: "350424"
          },
          {
            pid: "350400",
            label: "大田县",
            value: "350425"
          },
          {
            pid: "350400",
            label: "尤溪县",
            value: "350426"
          },
          {
            pid: "350400",
            label: "沙县",
            value: "350427"
          },
          {
            pid: "350400",
            label: "将乐县",
            value: "350428"
          },
          {
            pid: "350400",
            label: "泰宁县",
            value: "350429"
          },
          {
            pid: "350400",
            label: "建宁县",
            value: "350430"
          },
          {
            pid: "350400",
            label: "永安市",
            value: "350481"
          }
        ],
        pid: "350000",
        label: "三明市",
        value: "350400"
      },
      {
        children: [
          {
            pid: "350500",
            label: "鲤城区",
            value: "350502"
          },
          {
            pid: "350500",
            label: "丰泽区",
            value: "350503"
          },
          {
            pid: "350500",
            label: "洛江区",
            value: "350504"
          },
          {
            pid: "350500",
            label: "泉港区",
            value: "350505"
          },
          {
            pid: "350500",
            label: "惠安县",
            value: "350521"
          },
          {
            pid: "350500",
            label: "安溪县",
            value: "350524"
          },
          {
            pid: "350500",
            label: "永春县",
            value: "350525"
          },
          {
            pid: "350500",
            label: "德化县",
            value: "350526"
          },
          {
            pid: "350500",
            label: "金门县",
            value: "350527"
          },
          {
            pid: "350500",
            label: "石狮市",
            value: "350581"
          },
          {
            pid: "350500",
            label: "晋江市",
            value: "350582"
          },
          {
            pid: "350500",
            label: "南安市",
            value: "350583"
          }
        ],
        pid: "350000",
        label: "泉州市",
        value: "350500"
      },
      {
        children: [
          {
            pid: "350600",
            label: "芗城区",
            value: "350602"
          },
          {
            pid: "350600",
            label: "龙文区",
            value: "350603"
          },
          {
            pid: "350600",
            label: "云霄县",
            value: "350622"
          },
          {
            pid: "350600",
            label: "漳浦县",
            value: "350623"
          },
          {
            pid: "350600",
            label: "诏安县",
            value: "350624"
          },
          {
            pid: "350600",
            label: "长泰县",
            value: "350625"
          },
          {
            pid: "350600",
            label: "东山县",
            value: "350626"
          },
          {
            pid: "350600",
            label: "南靖县",
            value: "350627"
          },
          {
            pid: "350600",
            label: "平和县",
            value: "350628"
          },
          {
            pid: "350600",
            label: "华安县",
            value: "350629"
          },
          {
            pid: "350600",
            label: "龙海市",
            value: "350681"
          }
        ],
        pid: "350000",
        label: "漳州市",
        value: "350600"
      },
      {
        children: [
          {
            pid: "350700",
            label: "延平区",
            value: "350702"
          },
          {
            pid: "350700",
            label: "建阳区",
            value: "350703"
          },
          {
            pid: "350700",
            label: "顺昌县",
            value: "350721"
          },
          {
            pid: "350700",
            label: "浦城县",
            value: "350722"
          },
          {
            pid: "350700",
            label: "光泽县",
            value: "350723"
          },
          {
            pid: "350700",
            label: "松溪县",
            value: "350724"
          },
          {
            pid: "350700",
            label: "政和县",
            value: "350725"
          },
          {
            pid: "350700",
            label: "邵武市",
            value: "350781"
          },
          {
            pid: "350700",
            label: "武夷山市",
            value: "350782"
          },
          {
            pid: "350700",
            label: "建瓯市",
            value: "350783"
          }
        ],
        pid: "350000",
        label: "南平市",
        value: "350700"
      },
      {
        children: [
          {
            pid: "350800",
            label: "新罗区",
            value: "350802"
          },
          {
            pid: "350800",
            label: "永定区",
            value: "350803"
          },
          {
            pid: "350800",
            label: "长汀县",
            value: "350821"
          },
          {
            pid: "350800",
            label: "上杭县",
            value: "350823"
          },
          {
            pid: "350800",
            label: "武平县",
            value: "350824"
          },
          {
            pid: "350800",
            label: "连城县",
            value: "350825"
          },
          {
            pid: "350800",
            label: "漳平市",
            value: "350881"
          }
        ],
        pid: "350000",
        label: "龙岩市",
        value: "350800"
      },
      {
        children: [
          {
            pid: "350900",
            label: "蕉城区",
            value: "350902"
          },
          {
            pid: "350900",
            label: "霞浦县",
            value: "350921"
          },
          {
            pid: "350900",
            label: "古田县",
            value: "350922"
          },
          {
            pid: "350900",
            label: "屏南县",
            value: "350923"
          },
          {
            pid: "350900",
            label: "寿宁县",
            value: "350924"
          },
          {
            pid: "350900",
            label: "周宁县",
            value: "350925"
          },
          {
            pid: "350900",
            label: "柘荣县",
            value: "350926"
          },
          {
            pid: "350900",
            label: "福安市",
            value: "350981"
          },
          {
            pid: "350900",
            label: "福鼎市",
            value: "350982"
          }
        ],
        pid: "350000",
        label: "宁德市",
        value: "350900"
      }
    ],
    pid: "-1",
    label: "福建省",
    value: "350000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "360100",
            label: "东湖区",
            value: "360102"
          },
          {
            pid: "360100",
            label: "西湖区",
            value: "360103"
          },
          {
            pid: "360100",
            label: "青云谱区",
            value: "360104"
          },
          {
            pid: "360100",
            label: "湾里区",
            value: "360105"
          },
          {
            pid: "360100",
            label: "青山湖区",
            value: "360111"
          },
          {
            pid: "360100",
            label: "新建区",
            value: "360112"
          },
          {
            pid: "360100",
            label: "南昌县",
            value: "360121"
          },
          {
            pid: "360100",
            label: "安义县",
            value: "360123"
          },
          {
            pid: "360100",
            label: "进贤县",
            value: "360124"
          }
        ],
        pid: "360000",
        label: "南昌市",
        value: "360100"
      },
      {
        children: [
          {
            pid: "360200",
            label: "昌江区",
            value: "360202"
          },
          {
            pid: "360200",
            label: "珠山区",
            value: "360203"
          },
          {
            pid: "360200",
            label: "浮梁县",
            value: "360222"
          },
          {
            pid: "360200",
            label: "乐平市",
            value: "360281"
          }
        ],
        pid: "360000",
        label: "景德镇市",
        value: "360200"
      },
      {
        children: [
          {
            pid: "360300",
            label: "安源区",
            value: "360302"
          },
          {
            pid: "360300",
            label: "湘东区",
            value: "360313"
          },
          {
            pid: "360300",
            label: "莲花县",
            value: "360321"
          },
          {
            pid: "360300",
            label: "上栗县",
            value: "360322"
          },
          {
            pid: "360300",
            label: "芦溪县",
            value: "360323"
          }
        ],
        pid: "360000",
        label: "萍乡市",
        value: "360300"
      },
      {
        children: [
          {
            pid: "360400",
            label: "濂溪区",
            value: "360402"
          },
          {
            pid: "360400",
            label: "浔阳区",
            value: "360403"
          },
          {
            pid: "360400",
            label: "柴桑区",
            value: "360404"
          },
          {
            pid: "360400",
            label: "武宁县",
            value: "360423"
          },
          {
            pid: "360400",
            label: "修水县",
            value: "360424"
          },
          {
            pid: "360400",
            label: "永修县",
            value: "360425"
          },
          {
            pid: "360400",
            label: "德安县",
            value: "360426"
          },
          {
            pid: "360400",
            label: "都昌县",
            value: "360428"
          },
          {
            pid: "360400",
            label: "湖口县",
            value: "360429"
          },
          {
            pid: "360400",
            label: "彭泽县",
            value: "360430"
          },
          {
            pid: "360400",
            label: "瑞昌市",
            value: "360481"
          },
          {
            pid: "360400",
            label: "共青城市",
            value: "360482"
          },
          {
            pid: "360400",
            label: "庐山市",
            value: "360483"
          }
        ],
        pid: "360000",
        label: "九江市",
        value: "360400"
      },
      {
        children: [
          {
            pid: "360500",
            label: "渝水区",
            value: "360502"
          },
          {
            pid: "360500",
            label: "分宜县",
            value: "360521"
          }
        ],
        pid: "360000",
        label: "新余市",
        value: "360500"
      },
      {
        children: [
          {
            pid: "360600",
            label: "月湖区",
            value: "360602"
          },
          {
            pid: "360600",
            label: "余江区",
            value: "360603"
          },
          {
            pid: "360600",
            label: "贵溪市",
            value: "360681"
          }
        ],
        pid: "360000",
        label: "鹰潭市",
        value: "360600"
      },
      {
        children: [
          {
            pid: "360700",
            label: "章贡区",
            value: "360702"
          },
          {
            pid: "360700",
            label: "南康区",
            value: "360703"
          },
          {
            pid: "360700",
            label: "赣县区",
            value: "360704"
          },
          {
            pid: "360700",
            label: "信丰县",
            value: "360722"
          },
          {
            pid: "360700",
            label: "大余县",
            value: "360723"
          },
          {
            pid: "360700",
            label: "上犹县",
            value: "360724"
          },
          {
            pid: "360700",
            label: "崇义县",
            value: "360725"
          },
          {
            pid: "360700",
            label: "安远县",
            value: "360726"
          },
          {
            pid: "360700",
            label: "龙南县",
            value: "360727"
          },
          {
            pid: "360700",
            label: "定南县",
            value: "360728"
          },
          {
            pid: "360700",
            label: "全南县",
            value: "360729"
          },
          {
            pid: "360700",
            label: "宁都县",
            value: "360730"
          },
          {
            pid: "360700",
            label: "于都县",
            value: "360731"
          },
          {
            pid: "360700",
            label: "兴国县",
            value: "360732"
          },
          {
            pid: "360700",
            label: "会昌县",
            value: "360733"
          },
          {
            pid: "360700",
            label: "寻乌县",
            value: "360734"
          },
          {
            pid: "360700",
            label: "石城县",
            value: "360735"
          },
          {
            pid: "360700",
            label: "瑞金市",
            value: "360781"
          }
        ],
        pid: "360000",
        label: "赣州市",
        value: "360700"
      },
      {
        children: [
          {
            pid: "360800",
            label: "吉州区",
            value: "360802"
          },
          {
            pid: "360800",
            label: "青原区",
            value: "360803"
          },
          {
            pid: "360800",
            label: "吉安县",
            value: "360821"
          },
          {
            pid: "360800",
            label: "吉水县",
            value: "360822"
          },
          {
            pid: "360800",
            label: "峡江县",
            value: "360823"
          },
          {
            pid: "360800",
            label: "新干县",
            value: "360824"
          },
          {
            pid: "360800",
            label: "永丰县",
            value: "360825"
          },
          {
            pid: "360800",
            label: "泰和县",
            value: "360826"
          },
          {
            pid: "360800",
            label: "遂川县",
            value: "360827"
          },
          {
            pid: "360800",
            label: "万安县",
            value: "360828"
          },
          {
            pid: "360800",
            label: "安福县",
            value: "360829"
          },
          {
            pid: "360800",
            label: "永新县",
            value: "360830"
          },
          {
            pid: "360800",
            label: "井冈山市",
            value: "360881"
          }
        ],
        pid: "360000",
        label: "吉安市",
        value: "360800"
      },
      {
        children: [
          {
            pid: "360900",
            label: "袁州区",
            value: "360902"
          },
          {
            pid: "360900",
            label: "奉新县",
            value: "360921"
          },
          {
            pid: "360900",
            label: "万载县",
            value: "360922"
          },
          {
            pid: "360900",
            label: "上高县",
            value: "360923"
          },
          {
            pid: "360900",
            label: "宜丰县",
            value: "360924"
          },
          {
            pid: "360900",
            label: "靖安县",
            value: "360925"
          },
          {
            pid: "360900",
            label: "铜鼓县",
            value: "360926"
          },
          {
            pid: "360900",
            label: "丰城市",
            value: "360981"
          },
          {
            pid: "360900",
            label: "樟树市",
            value: "360982"
          },
          {
            pid: "360900",
            label: "高安市",
            value: "360983"
          }
        ],
        pid: "360000",
        label: "宜春市",
        value: "360900"
      },
      {
        children: [
          {
            pid: "361000",
            label: "临川区",
            value: "361002"
          },
          {
            pid: "361000",
            label: "东乡区",
            value: "361003"
          },
          {
            pid: "361000",
            label: "南城县",
            value: "361021"
          },
          {
            pid: "361000",
            label: "黎川县",
            value: "361022"
          },
          {
            pid: "361000",
            label: "南丰县",
            value: "361023"
          },
          {
            pid: "361000",
            label: "崇仁县",
            value: "361024"
          },
          {
            pid: "361000",
            label: "乐安县",
            value: "361025"
          },
          {
            pid: "361000",
            label: "宜黄县",
            value: "361026"
          },
          {
            pid: "361000",
            label: "金溪县",
            value: "361027"
          },
          {
            pid: "361000",
            label: "资溪县",
            value: "361028"
          },
          {
            pid: "361000",
            label: "广昌县",
            value: "361030"
          }
        ],
        pid: "360000",
        label: "抚州市",
        value: "361000"
      },
      {
        children: [
          {
            pid: "361100",
            label: "信州区",
            value: "361102"
          },
          {
            pid: "361100",
            label: "广丰区",
            value: "361103"
          },
          {
            pid: "361100",
            label: "上饶县",
            value: "361121"
          },
          {
            pid: "361100",
            label: "玉山县",
            value: "361123"
          },
          {
            pid: "361100",
            label: "铅山县",
            value: "361124"
          },
          {
            pid: "361100",
            label: "横峰县",
            value: "361125"
          },
          {
            pid: "361100",
            label: "弋阳县",
            value: "361126"
          },
          {
            pid: "361100",
            label: "余干县",
            value: "361127"
          },
          {
            pid: "361100",
            label: "鄱阳县",
            value: "361128"
          },
          {
            pid: "361100",
            label: "万年县",
            value: "361129"
          },
          {
            pid: "361100",
            label: "婺源县",
            value: "361130"
          },
          {
            pid: "361100",
            label: "德兴市",
            value: "361181"
          }
        ],
        pid: "360000",
        label: "上饶市",
        value: "361100"
      }
    ],
    pid: "-1",
    label: "江西省",
    value: "360000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "370100",
            label: "历下区",
            value: "370102"
          },
          {
            pid: "370100",
            label: "市中区",
            value: "370103"
          },
          {
            pid: "370100",
            label: "槐荫区",
            value: "370104"
          },
          {
            pid: "370100",
            label: "天桥区",
            value: "370105"
          },
          {
            pid: "370100",
            label: "历城区",
            value: "370112"
          },
          {
            pid: "370100",
            label: "长清区",
            value: "370113"
          },
          {
            pid: "370100",
            label: "章丘区",
            value: "370114"
          },
          {
            pid: "370100",
            label: "济阳区",
            value: "370115"
          },
          {
            pid: "370100",
            label: "平阴县",
            value: "370124"
          },
          {
            pid: "370100",
            label: "商河县",
            value: "370126"
          }
        ],
        pid: "370000",
        label: "济南市",
        value: "370100"
      },
      {
        children: [
          {
            pid: "370200",
            label: "市南区",
            value: "370202"
          },
          {
            pid: "370200",
            label: "市北区",
            value: "370203"
          },
          {
            pid: "370200",
            label: "黄岛区",
            value: "370211"
          },
          {
            pid: "370200",
            label: "崂山区",
            value: "370212"
          },
          {
            pid: "370200",
            label: "李沧区",
            value: "370213"
          },
          {
            pid: "370200",
            label: "城阳区",
            value: "370214"
          },
          {
            pid: "370200",
            label: "即墨区",
            value: "370215"
          },
          {
            pid: "370200",
            label: "胶州市",
            value: "370281"
          },
          {
            pid: "370200",
            label: "平度市",
            value: "370283"
          },
          {
            pid: "370200",
            label: "莱西市",
            value: "370285"
          }
        ],
        pid: "370000",
        label: "青岛市",
        value: "370200"
      },
      {
        children: [
          {
            pid: "370300",
            label: "淄川区",
            value: "370302"
          },
          {
            pid: "370300",
            label: "张店区",
            value: "370303"
          },
          {
            pid: "370300",
            label: "博山区",
            value: "370304"
          },
          {
            pid: "370300",
            label: "临淄区",
            value: "370305"
          },
          {
            pid: "370300",
            label: "周村区",
            value: "370306"
          },
          {
            pid: "370300",
            label: "桓台县",
            value: "370321"
          },
          {
            pid: "370300",
            label: "高青县",
            value: "370322"
          },
          {
            pid: "370300",
            label: "沂源县",
            value: "370323"
          }
        ],
        pid: "370000",
        label: "淄博市",
        value: "370300"
      },
      {
        children: [
          {
            pid: "370400",
            label: "市中区",
            value: "370402"
          },
          {
            pid: "370400",
            label: "薛城区",
            value: "370403"
          },
          {
            pid: "370400",
            label: "峄城区",
            value: "370404"
          },
          {
            pid: "370400",
            label: "台儿庄区",
            value: "370405"
          },
          {
            pid: "370400",
            label: "山亭区",
            value: "370406"
          },
          {
            pid: "370400",
            label: "滕州市",
            value: "370481"
          }
        ],
        pid: "370000",
        label: "枣庄市",
        value: "370400"
      },
      {
        children: [
          {
            pid: "370500",
            label: "东营区",
            value: "370502"
          },
          {
            pid: "370500",
            label: "河口区",
            value: "370503"
          },
          {
            pid: "370500",
            label: "垦利区",
            value: "370505"
          },
          {
            pid: "370500",
            label: "利津县",
            value: "370522"
          },
          {
            pid: "370500",
            label: "广饶县",
            value: "370523"
          }
        ],
        pid: "370000",
        label: "东营市",
        value: "370500"
      },
      {
        children: [
          {
            pid: "370600",
            label: "芝罘区",
            value: "370602"
          },
          {
            pid: "370600",
            label: "福山区",
            value: "370611"
          },
          {
            pid: "370600",
            label: "牟平区",
            value: "370612"
          },
          {
            pid: "370600",
            label: "莱山区",
            value: "370613"
          },
          {
            pid: "370600",
            label: "长岛县",
            value: "370634"
          },
          {
            pid: "370600",
            label: "龙口市",
            value: "370681"
          },
          {
            pid: "370600",
            label: "莱阳市",
            value: "370682"
          },
          {
            pid: "370600",
            label: "莱州市",
            value: "370683"
          },
          {
            pid: "370600",
            label: "蓬莱市",
            value: "370684"
          },
          {
            pid: "370600",
            label: "招远市",
            value: "370685"
          },
          {
            pid: "370600",
            label: "栖霞市",
            value: "370686"
          },
          {
            pid: "370600",
            label: "海阳市",
            value: "370687"
          }
        ],
        pid: "370000",
        label: "烟台市",
        value: "370600"
      },
      {
        children: [
          {
            pid: "370700",
            label: "潍城区",
            value: "370702"
          },
          {
            pid: "370700",
            label: "寒亭区",
            value: "370703"
          },
          {
            pid: "370700",
            label: "坊子区",
            value: "370704"
          },
          {
            pid: "370700",
            label: "奎文区",
            value: "370705"
          },
          {
            pid: "370700",
            label: "临朐县",
            value: "370724"
          },
          {
            pid: "370700",
            label: "昌乐县",
            value: "370725"
          },
          {
            pid: "370700",
            label: "青州市",
            value: "370781"
          },
          {
            pid: "370700",
            label: "诸城市",
            value: "370782"
          },
          {
            pid: "370700",
            label: "寿光市",
            value: "370783"
          },
          {
            pid: "370700",
            label: "安丘市",
            value: "370784"
          },
          {
            pid: "370700",
            label: "高密市",
            value: "370785"
          },
          {
            pid: "370700",
            label: "昌邑市",
            value: "370786"
          }
        ],
        pid: "370000",
        label: "潍坊市",
        value: "370700"
      },
      {
        children: [
          {
            pid: "370800",
            label: "任城区",
            value: "370811"
          },
          {
            pid: "370800",
            label: "兖州区",
            value: "370812"
          },
          {
            pid: "370800",
            label: "微山县",
            value: "370826"
          },
          {
            pid: "370800",
            label: "鱼台县",
            value: "370827"
          },
          {
            pid: "370800",
            label: "金乡县",
            value: "370828"
          },
          {
            pid: "370800",
            label: "嘉祥县",
            value: "370829"
          },
          {
            pid: "370800",
            label: "汶上县",
            value: "370830"
          },
          {
            pid: "370800",
            label: "泗水县",
            value: "370831"
          },
          {
            pid: "370800",
            label: "梁山县",
            value: "370832"
          },
          {
            pid: "370800",
            label: "曲阜市",
            value: "370881"
          },
          {
            pid: "370800",
            label: "邹城市",
            value: "370883"
          }
        ],
        pid: "370000",
        label: "济宁市",
        value: "370800"
      },
      {
        children: [
          {
            pid: "370900",
            label: "泰山区",
            value: "370902"
          },
          {
            pid: "370900",
            label: "岱岳区",
            value: "370911"
          },
          {
            pid: "370900",
            label: "宁阳县",
            value: "370921"
          },
          {
            pid: "370900",
            label: "东平县",
            value: "370923"
          },
          {
            pid: "370900",
            label: "新泰市",
            value: "370982"
          },
          {
            pid: "370900",
            label: "肥城市",
            value: "370983"
          }
        ],
        pid: "370000",
        label: "泰安市",
        value: "370900"
      },
      {
        children: [
          {
            pid: "371000",
            label: "环翠区",
            value: "371002"
          },
          {
            pid: "371000",
            label: "文登区",
            value: "371003"
          },
          {
            pid: "371000",
            label: "荣成市",
            value: "371082"
          },
          {
            pid: "371000",
            label: "乳山市",
            value: "371083"
          }
        ],
        pid: "370000",
        label: "威海市",
        value: "371000"
      },
      {
        children: [
          {
            pid: "371100",
            label: "东港区",
            value: "371102"
          },
          {
            pid: "371100",
            label: "岚山区",
            value: "371103"
          },
          {
            pid: "371100",
            label: "五莲县",
            value: "371121"
          },
          {
            pid: "371100",
            label: "莒县",
            value: "371122"
          }
        ],
        pid: "370000",
        label: "日照市",
        value: "371100"
      },
      {
        children: [
          {
            pid: "371200",
            label: "莱城区",
            value: "371202"
          },
          {
            pid: "371200",
            label: "钢城区",
            value: "371203"
          }
        ],
        pid: "370000",
        label: "莱芜市",
        value: "371200"
      },
      {
        children: [
          {
            pid: "371300",
            label: "兰山区",
            value: "371302"
          },
          {
            pid: "371300",
            label: "罗庄区",
            value: "371311"
          },
          {
            pid: "371300",
            label: "河东区",
            value: "371312"
          },
          {
            pid: "371300",
            label: "沂南县",
            value: "371321"
          },
          {
            pid: "371300",
            label: "郯城县",
            value: "371322"
          },
          {
            pid: "371300",
            label: "沂水县",
            value: "371323"
          },
          {
            pid: "371300",
            label: "兰陵县",
            value: "371324"
          },
          {
            pid: "371300",
            label: "费县",
            value: "371325"
          },
          {
            pid: "371300",
            label: "平邑县",
            value: "371326"
          },
          {
            pid: "371300",
            label: "莒南县",
            value: "371327"
          },
          {
            pid: "371300",
            label: "蒙阴县",
            value: "371328"
          },
          {
            pid: "371300",
            label: "临沭县",
            value: "371329"
          }
        ],
        pid: "370000",
        label: "临沂市",
        value: "371300"
      },
      {
        children: [
          {
            pid: "371400",
            label: "德城区",
            value: "371402"
          },
          {
            pid: "371400",
            label: "陵城区",
            value: "371403"
          },
          {
            pid: "371400",
            label: "宁津县",
            value: "371422"
          },
          {
            pid: "371400",
            label: "庆云县",
            value: "371423"
          },
          {
            pid: "371400",
            label: "临邑县",
            value: "371424"
          },
          {
            pid: "371400",
            label: "齐河县",
            value: "371425"
          },
          {
            pid: "371400",
            label: "平原县",
            value: "371426"
          },
          {
            pid: "371400",
            label: "夏津县",
            value: "371427"
          },
          {
            pid: "371400",
            label: "武城县",
            value: "371428"
          },
          {
            pid: "371400",
            label: "乐陵市",
            value: "371481"
          },
          {
            pid: "371400",
            label: "禹城市",
            value: "371482"
          }
        ],
        pid: "370000",
        label: "德州市",
        value: "371400"
      },
      {
        children: [
          {
            pid: "371500",
            label: "东昌府区",
            value: "371502"
          },
          {
            pid: "371500",
            label: "阳谷县",
            value: "371521"
          },
          {
            pid: "371500",
            label: "莘县",
            value: "371522"
          },
          {
            pid: "371500",
            label: "茌平县",
            value: "371523"
          },
          {
            pid: "371500",
            label: "东阿县",
            value: "371524"
          },
          {
            pid: "371500",
            label: "冠县",
            value: "371525"
          },
          {
            pid: "371500",
            label: "高唐县",
            value: "371526"
          },
          {
            pid: "371500",
            label: "临清市",
            value: "371581"
          }
        ],
        pid: "370000",
        label: "聊城市",
        value: "371500"
      },
      {
        children: [
          {
            pid: "371600",
            label: "滨城区",
            value: "371602"
          },
          {
            pid: "371600",
            label: "沾化区",
            value: "371603"
          },
          {
            pid: "371600",
            label: "惠民县",
            value: "371621"
          },
          {
            pid: "371600",
            label: "阳信县",
            value: "371622"
          },
          {
            pid: "371600",
            label: "无棣县",
            value: "371623"
          },
          {
            pid: "371600",
            label: "博兴县",
            value: "371625"
          },
          {
            pid: "371600",
            label: "邹平市",
            value: "371681"
          }
        ],
        pid: "370000",
        label: "滨州市",
        value: "371600"
      },
      {
        children: [
          {
            pid: "371700",
            label: "牡丹区",
            value: "371702"
          },
          {
            pid: "371700",
            label: "定陶区",
            value: "371703"
          },
          {
            pid: "371700",
            label: "曹县",
            value: "371721"
          },
          {
            pid: "371700",
            label: "单县",
            value: "371722"
          },
          {
            pid: "371700",
            label: "成武县",
            value: "371723"
          },
          {
            pid: "371700",
            label: "巨野县",
            value: "371724"
          },
          {
            pid: "371700",
            label: "郓城县",
            value: "371725"
          },
          {
            pid: "371700",
            label: "鄄城县",
            value: "371726"
          },
          {
            pid: "371700",
            label: "东明县",
            value: "371728"
          }
        ],
        pid: "370000",
        label: "菏泽市",
        value: "371700"
      }
    ],
    pid: "-1",
    label: "山东省",
    value: "370000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "410100",
            label: "中原区",
            value: "410102"
          },
          {
            pid: "410100",
            label: "二七区",
            value: "410103"
          },
          {
            pid: "410100",
            label: "管城回族区",
            value: "410104"
          },
          {
            pid: "410100",
            label: "金水区",
            value: "410105"
          },
          {
            pid: "410100",
            label: "上街区",
            value: "410106"
          },
          {
            pid: "410100",
            label: "惠济区",
            value: "410108"
          },
          {
            pid: "410100",
            label: "中牟县",
            value: "410122"
          },
          {
            pid: "410100",
            label: "巩义市",
            value: "410181"
          },
          {
            pid: "410100",
            label: "荥阳市",
            value: "410182"
          },
          {
            pid: "410100",
            label: "新密市",
            value: "410183"
          },
          {
            pid: "410100",
            label: "新郑市",
            value: "410184"
          },
          {
            pid: "410100",
            label: "登封市",
            value: "410185"
          }
        ],
        pid: "410000",
        label: "郑州市",
        value: "410100"
      },
      {
        children: [
          {
            pid: "410200",
            label: "龙亭区",
            value: "410202"
          },
          {
            pid: "410200",
            label: "顺河回族区",
            value: "410203"
          },
          {
            pid: "410200",
            label: "鼓楼区",
            value: "410204"
          },
          {
            pid: "410200",
            label: "禹王台区",
            value: "410205"
          },
          {
            pid: "410200",
            label: "祥符区",
            value: "410212"
          },
          {
            pid: "410200",
            label: "杞县",
            value: "410221"
          },
          {
            pid: "410200",
            label: "通许县",
            value: "410222"
          },
          {
            pid: "410200",
            label: "尉氏县",
            value: "410223"
          },
          {
            pid: "410200",
            label: "兰考县",
            value: "410225"
          }
        ],
        pid: "410000",
        label: "开封市",
        value: "410200"
      },
      {
        children: [
          {
            pid: "410300",
            label: "老城区",
            value: "410302"
          },
          {
            pid: "410300",
            label: "西工区",
            value: "410303"
          },
          {
            pid: "410300",
            label: "瀍河回族区",
            value: "410304"
          },
          {
            pid: "410300",
            label: "涧西区",
            value: "410305"
          },
          {
            pid: "410300",
            label: "吉利区",
            value: "410306"
          },
          {
            pid: "410300",
            label: "洛龙区",
            value: "410311"
          },
          {
            pid: "410300",
            label: "孟津县",
            value: "410322"
          },
          {
            pid: "410300",
            label: "新安县",
            value: "410323"
          },
          {
            pid: "410300",
            label: "栾川县",
            value: "410324"
          },
          {
            pid: "410300",
            label: "嵩县",
            value: "410325"
          },
          {
            pid: "410300",
            label: "汝阳县",
            value: "410326"
          },
          {
            pid: "410300",
            label: "宜阳县",
            value: "410327"
          },
          {
            pid: "410300",
            label: "洛宁县",
            value: "410328"
          },
          {
            pid: "410300",
            label: "伊川县",
            value: "410329"
          },
          {
            pid: "410300",
            label: "偃师市",
            value: "410381"
          }
        ],
        pid: "410000",
        label: "洛阳市",
        value: "410300"
      },
      {
        children: [
          {
            pid: "410400",
            label: "新华区",
            value: "410402"
          },
          {
            pid: "410400",
            label: "卫东区",
            value: "410403"
          },
          {
            pid: "410400",
            label: "石龙区",
            value: "410404"
          },
          {
            pid: "410400",
            label: "湛河区",
            value: "410411"
          },
          {
            pid: "410400",
            label: "宝丰县",
            value: "410421"
          },
          {
            pid: "410400",
            label: "叶县",
            value: "410422"
          },
          {
            pid: "410400",
            label: "鲁山县",
            value: "410423"
          },
          {
            pid: "410400",
            label: "郏县",
            value: "410425"
          },
          {
            pid: "410400",
            label: "舞钢市",
            value: "410481"
          },
          {
            pid: "410400",
            label: "汝州市",
            value: "410482"
          }
        ],
        pid: "410000",
        label: "平顶山市",
        value: "410400"
      },
      {
        children: [
          {
            pid: "410500",
            label: "文峰区",
            value: "410502"
          },
          {
            pid: "410500",
            label: "北关区",
            value: "410503"
          },
          {
            pid: "410500",
            label: "殷都区",
            value: "410505"
          },
          {
            pid: "410500",
            label: "龙安区",
            value: "410506"
          },
          {
            pid: "410500",
            label: "安阳县",
            value: "410522"
          },
          {
            pid: "410500",
            label: "汤阴县",
            value: "410523"
          },
          {
            pid: "410500",
            label: "滑县",
            value: "410526"
          },
          {
            pid: "410500",
            label: "内黄县",
            value: "410527"
          },
          {
            pid: "410500",
            label: "林州市",
            value: "410581"
          }
        ],
        pid: "410000",
        label: "安阳市",
        value: "410500"
      },
      {
        children: [
          {
            pid: "410600",
            label: "鹤山区",
            value: "410602"
          },
          {
            pid: "410600",
            label: "山城区",
            value: "410603"
          },
          {
            pid: "410600",
            label: "淇滨区",
            value: "410611"
          },
          {
            pid: "410600",
            label: "浚县",
            value: "410621"
          },
          {
            pid: "410600",
            label: "淇县",
            value: "410622"
          }
        ],
        pid: "410000",
        label: "鹤壁市",
        value: "410600"
      },
      {
        children: [
          {
            pid: "410700",
            label: "红旗区",
            value: "410702"
          },
          {
            pid: "410700",
            label: "卫滨区",
            value: "410703"
          },
          {
            pid: "410700",
            label: "凤泉区",
            value: "410704"
          },
          {
            pid: "410700",
            label: "牧野区",
            value: "410711"
          },
          {
            pid: "410700",
            label: "新乡县",
            value: "410721"
          },
          {
            pid: "410700",
            label: "获嘉县",
            value: "410724"
          },
          {
            pid: "410700",
            label: "原阳县",
            value: "410725"
          },
          {
            pid: "410700",
            label: "延津县",
            value: "410726"
          },
          {
            pid: "410700",
            label: "封丘县",
            value: "410727"
          },
          {
            pid: "410700",
            label: "长垣县",
            value: "410728"
          },
          {
            pid: "410700",
            label: "卫辉市",
            value: "410781"
          },
          {
            pid: "410700",
            label: "辉县市",
            value: "410782"
          }
        ],
        pid: "410000",
        label: "新乡市",
        value: "410700"
      },
      {
        children: [
          {
            pid: "410800",
            label: "解放区",
            value: "410802"
          },
          {
            pid: "410800",
            label: "中站区",
            value: "410803"
          },
          {
            pid: "410800",
            label: "马村区",
            value: "410804"
          },
          {
            pid: "410800",
            label: "山阳区",
            value: "410811"
          },
          {
            pid: "410800",
            label: "修武县",
            value: "410821"
          },
          {
            pid: "410800",
            label: "博爱县",
            value: "410822"
          },
          {
            pid: "410800",
            label: "武陟县",
            value: "410823"
          },
          {
            pid: "410800",
            label: "温县",
            value: "410825"
          },
          {
            pid: "410800",
            label: "沁阳市",
            value: "410882"
          },
          {
            pid: "410800",
            label: "孟州市",
            value: "410883"
          }
        ],
        pid: "410000",
        label: "焦作市",
        value: "410800"
      },
      {
        children: [
          {
            pid: "410900",
            label: "华龙区",
            value: "410902"
          },
          {
            pid: "410900",
            label: "清丰县",
            value: "410922"
          },
          {
            pid: "410900",
            label: "南乐县",
            value: "410923"
          },
          {
            pid: "410900",
            label: "范县",
            value: "410926"
          },
          {
            pid: "410900",
            label: "台前县",
            value: "410927"
          },
          {
            pid: "410900",
            label: "濮阳县",
            value: "410928"
          }
        ],
        pid: "410000",
        label: "濮阳市",
        value: "410900"
      },
      {
        children: [
          {
            pid: "411000",
            label: "魏都区",
            value: "411002"
          },
          {
            pid: "411000",
            label: "建安区",
            value: "411003"
          },
          {
            pid: "411000",
            label: "鄢陵县",
            value: "411024"
          },
          {
            pid: "411000",
            label: "襄城县",
            value: "411025"
          },
          {
            pid: "411000",
            label: "禹州市",
            value: "411081"
          },
          {
            pid: "411000",
            label: "长葛市",
            value: "411082"
          }
        ],
        pid: "410000",
        label: "许昌市",
        value: "411000"
      },
      {
        children: [
          {
            pid: "411100",
            label: "源汇区",
            value: "411102"
          },
          {
            pid: "411100",
            label: "郾城区",
            value: "411103"
          },
          {
            pid: "411100",
            label: "召陵区",
            value: "411104"
          },
          {
            pid: "411100",
            label: "舞阳县",
            value: "411121"
          },
          {
            pid: "411100",
            label: "临颍县",
            value: "411122"
          }
        ],
        pid: "410000",
        label: "漯河市",
        value: "411100"
      },
      {
        children: [
          {
            pid: "411200",
            label: "湖滨区",
            value: "411202"
          },
          {
            pid: "411200",
            label: "陕州区",
            value: "411203"
          },
          {
            pid: "411200",
            label: "渑池县",
            value: "411221"
          },
          {
            pid: "411200",
            label: "卢氏县",
            value: "411224"
          },
          {
            pid: "411200",
            label: "义马市",
            value: "411281"
          },
          {
            pid: "411200",
            label: "灵宝市",
            value: "411282"
          }
        ],
        pid: "410000",
        label: "三门峡市",
        value: "411200"
      },
      {
        children: [
          {
            pid: "411300",
            label: "宛城区",
            value: "411302"
          },
          {
            pid: "411300",
            label: "卧龙区",
            value: "411303"
          },
          {
            pid: "411300",
            label: "南召县",
            value: "411321"
          },
          {
            pid: "411300",
            label: "方城县",
            value: "411322"
          },
          {
            pid: "411300",
            label: "西峡县",
            value: "411323"
          },
          {
            pid: "411300",
            label: "镇平县",
            value: "411324"
          },
          {
            pid: "411300",
            label: "内乡县",
            value: "411325"
          },
          {
            pid: "411300",
            label: "淅川县",
            value: "411326"
          },
          {
            pid: "411300",
            label: "社旗县",
            value: "411327"
          },
          {
            pid: "411300",
            label: "唐河县",
            value: "411328"
          },
          {
            pid: "411300",
            label: "新野县",
            value: "411329"
          },
          {
            pid: "411300",
            label: "桐柏县",
            value: "411330"
          },
          {
            pid: "411300",
            label: "邓州市",
            value: "411381"
          }
        ],
        pid: "410000",
        label: "南阳市",
        value: "411300"
      },
      {
        children: [
          {
            pid: "411400",
            label: "梁园区",
            value: "411402"
          },
          {
            pid: "411400",
            label: "睢阳区",
            value: "411403"
          },
          {
            pid: "411400",
            label: "民权县",
            value: "411421"
          },
          {
            pid: "411400",
            label: "睢县",
            value: "411422"
          },
          {
            pid: "411400",
            label: "宁陵县",
            value: "411423"
          },
          {
            pid: "411400",
            label: "柘城县",
            value: "411424"
          },
          {
            pid: "411400",
            label: "虞城县",
            value: "411425"
          },
          {
            pid: "411400",
            label: "夏邑县",
            value: "411426"
          },
          {
            pid: "411400",
            label: "永城市",
            value: "411481"
          }
        ],
        pid: "410000",
        label: "商丘市",
        value: "411400"
      },
      {
        children: [
          {
            pid: "411500",
            label: "浉河区",
            value: "411502"
          },
          {
            pid: "411500",
            label: "平桥区",
            value: "411503"
          },
          {
            pid: "411500",
            label: "罗山县",
            value: "411521"
          },
          {
            pid: "411500",
            label: "光山县",
            value: "411522"
          },
          {
            pid: "411500",
            label: "新县",
            value: "411523"
          },
          {
            pid: "411500",
            label: "商城县",
            value: "411524"
          },
          {
            pid: "411500",
            label: "固始县",
            value: "411525"
          },
          {
            pid: "411500",
            label: "潢川县",
            value: "411526"
          },
          {
            pid: "411500",
            label: "淮滨县",
            value: "411527"
          },
          {
            pid: "411500",
            label: "息县",
            value: "411528"
          }
        ],
        pid: "410000",
        label: "信阳市",
        value: "411500"
      },
      {
        children: [
          {
            pid: "411600",
            label: "川汇区",
            value: "411602"
          },
          {
            pid: "411600",
            label: "扶沟县",
            value: "411621"
          },
          {
            pid: "411600",
            label: "西华县",
            value: "411622"
          },
          {
            pid: "411600",
            label: "商水县",
            value: "411623"
          },
          {
            pid: "411600",
            label: "沈丘县",
            value: "411624"
          },
          {
            pid: "411600",
            label: "郸城县",
            value: "411625"
          },
          {
            pid: "411600",
            label: "淮阳县",
            value: "411626"
          },
          {
            pid: "411600",
            label: "太康县",
            value: "411627"
          },
          {
            pid: "411600",
            label: "鹿邑县",
            value: "411628"
          },
          {
            pid: "411600",
            label: "项城市",
            value: "411681"
          }
        ],
        pid: "410000",
        label: "周口市",
        value: "411600"
      },
      {
        children: [
          {
            pid: "411700",
            label: "驿城区",
            value: "411702"
          },
          {
            pid: "411700",
            label: "西平县",
            value: "411721"
          },
          {
            pid: "411700",
            label: "上蔡县",
            value: "411722"
          },
          {
            pid: "411700",
            label: "平舆县",
            value: "411723"
          },
          {
            pid: "411700",
            label: "正阳县",
            value: "411724"
          },
          {
            pid: "411700",
            label: "确山县",
            value: "411725"
          },
          {
            pid: "411700",
            label: "泌阳县",
            value: "411726"
          },
          {
            pid: "411700",
            label: "汝南县",
            value: "411727"
          },
          {
            pid: "411700",
            label: "遂平县",
            value: "411728"
          },
          {
            pid: "411700",
            label: "新蔡县",
            value: "411729"
          }
        ],
        pid: "410000",
        label: "驻马店市",
        value: "411700"
      }
    ],
    pid: "-1",
    label: "河南省",
    value: "410000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "420100",
            label: "江岸区",
            value: "420102"
          },
          {
            pid: "420100",
            label: "江汉区",
            value: "420103"
          },
          {
            pid: "420100",
            label: "硚口区",
            value: "420104"
          },
          {
            pid: "420100",
            label: "汉阳区",
            value: "420105"
          },
          {
            pid: "420100",
            label: "武昌区",
            value: "420106"
          },
          {
            pid: "420100",
            label: "青山区",
            value: "420107"
          },
          {
            pid: "420100",
            label: "洪山区",
            value: "420111"
          },
          {
            pid: "420100",
            label: "东西湖区",
            value: "420112"
          },
          {
            pid: "420100",
            label: "汉南区",
            value: "420113"
          },
          {
            pid: "420100",
            label: "蔡甸区",
            value: "420114"
          },
          {
            pid: "420100",
            label: "江夏区",
            value: "420115"
          },
          {
            pid: "420100",
            label: "黄陂区",
            value: "420116"
          },
          {
            pid: "420100",
            label: "新洲区",
            value: "420117"
          }
        ],
        pid: "420000",
        label: "武汉市",
        value: "420100"
      },
      {
        children: [
          {
            pid: "420200",
            label: "黄石港区",
            value: "420202"
          },
          {
            pid: "420200",
            label: "西塞山区",
            value: "420203"
          },
          {
            pid: "420200",
            label: "下陆区",
            value: "420204"
          },
          {
            pid: "420200",
            label: "铁山区",
            value: "420205"
          },
          {
            pid: "420200",
            label: "阳新县",
            value: "420222"
          },
          {
            pid: "420200",
            label: "大冶市",
            value: "420281"
          }
        ],
        pid: "420000",
        label: "黄石市",
        value: "420200"
      },
      {
        children: [
          {
            pid: "420300",
            label: "茅箭区",
            value: "420302"
          },
          {
            pid: "420300",
            label: "张湾区",
            value: "420303"
          },
          {
            pid: "420300",
            label: "郧阳区",
            value: "420304"
          },
          {
            pid: "420300",
            label: "郧西县",
            value: "420322"
          },
          {
            pid: "420300",
            label: "竹山县",
            value: "420323"
          },
          {
            pid: "420300",
            label: "竹溪县",
            value: "420324"
          },
          {
            pid: "420300",
            label: "房县",
            value: "420325"
          },
          {
            pid: "420300",
            label: "丹江口市",
            value: "420381"
          }
        ],
        pid: "420000",
        label: "十堰市",
        value: "420300"
      },
      {
        children: [
          {
            pid: "420500",
            label: "西陵区",
            value: "420502"
          },
          {
            pid: "420500",
            label: "伍家岗区",
            value: "420503"
          },
          {
            pid: "420500",
            label: "点军区",
            value: "420504"
          },
          {
            pid: "420500",
            label: "猇亭区",
            value: "420505"
          },
          {
            pid: "420500",
            label: "夷陵区",
            value: "420506"
          },
          {
            pid: "420500",
            label: "远安县",
            value: "420525"
          },
          {
            pid: "420500",
            label: "兴山县",
            value: "420526"
          },
          {
            pid: "420500",
            label: "秭归县",
            value: "420527"
          },
          {
            pid: "420500",
            label: "长阳土家族自治县",
            value: "420528"
          },
          {
            pid: "420500",
            label: "五峰土家族自治县",
            value: "420529"
          },
          {
            pid: "420500",
            label: "宜都市",
            value: "420581"
          },
          {
            pid: "420500",
            label: "当阳市",
            value: "420582"
          },
          {
            pid: "420500",
            label: "枝江市",
            value: "420583"
          }
        ],
        pid: "420000",
        label: "宜昌市",
        value: "420500"
      },
      {
        children: [
          {
            pid: "420600",
            label: "襄城区",
            value: "420602"
          },
          {
            pid: "420600",
            label: "樊城区",
            value: "420606"
          },
          {
            pid: "420600",
            label: "襄州区",
            value: "420607"
          },
          {
            pid: "420600",
            label: "南漳县",
            value: "420624"
          },
          {
            pid: "420600",
            label: "谷城县",
            value: "420625"
          },
          {
            pid: "420600",
            label: "保康县",
            value: "420626"
          },
          {
            pid: "420600",
            label: "老河口市",
            value: "420682"
          },
          {
            pid: "420600",
            label: "枣阳市",
            value: "420683"
          },
          {
            pid: "420600",
            label: "宜城市",
            value: "420684"
          }
        ],
        pid: "420000",
        label: "襄阳市",
        value: "420600"
      },
      {
        children: [
          {
            pid: "420700",
            label: "梁子湖区",
            value: "420702"
          },
          {
            pid: "420700",
            label: "华容区",
            value: "420703"
          },
          {
            pid: "420700",
            label: "鄂城区",
            value: "420704"
          }
        ],
        pid: "420000",
        label: "鄂州市",
        value: "420700"
      },
      {
        children: [
          {
            pid: "420800",
            label: "东宝区",
            value: "420802"
          },
          {
            pid: "420800",
            label: "掇刀区",
            value: "420804"
          },
          {
            pid: "420800",
            label: "沙洋县",
            value: "420822"
          },
          {
            pid: "420800",
            label: "钟祥市",
            value: "420881"
          },
          {
            pid: "420800",
            label: "京山市",
            value: "420882"
          }
        ],
        pid: "420000",
        label: "荆门市",
        value: "420800"
      },
      {
        children: [
          {
            pid: "420900",
            label: "孝南区",
            value: "420902"
          },
          {
            pid: "420900",
            label: "孝昌县",
            value: "420921"
          },
          {
            pid: "420900",
            label: "大悟县",
            value: "420922"
          },
          {
            pid: "420900",
            label: "云梦县",
            value: "420923"
          },
          {
            pid: "420900",
            label: "应城市",
            value: "420981"
          },
          {
            pid: "420900",
            label: "安陆市",
            value: "420982"
          },
          {
            pid: "420900",
            label: "汉川市",
            value: "420984"
          }
        ],
        pid: "420000",
        label: "孝感市",
        value: "420900"
      },
      {
        children: [
          {
            pid: "421000",
            label: "沙市区",
            value: "421002"
          },
          {
            pid: "421000",
            label: "荆州区",
            value: "421003"
          },
          {
            pid: "421000",
            label: "公安县",
            value: "421022"
          },
          {
            pid: "421000",
            label: "监利县",
            value: "421023"
          },
          {
            pid: "421000",
            label: "江陵县",
            value: "421024"
          },
          {
            pid: "421000",
            label: "石首市",
            value: "421081"
          },
          {
            pid: "421000",
            label: "洪湖市",
            value: "421083"
          },
          {
            pid: "421000",
            label: "松滋市",
            value: "421087"
          }
        ],
        pid: "420000",
        label: "荆州市",
        value: "421000"
      },
      {
        children: [
          {
            pid: "421100",
            label: "黄州区",
            value: "421102"
          },
          {
            pid: "421100",
            label: "团风县",
            value: "421121"
          },
          {
            pid: "421100",
            label: "红安县",
            value: "421122"
          },
          {
            pid: "421100",
            label: "罗田县",
            value: "421123"
          },
          {
            pid: "421100",
            label: "英山县",
            value: "421124"
          },
          {
            pid: "421100",
            label: "浠水县",
            value: "421125"
          },
          {
            pid: "421100",
            label: "蕲春县",
            value: "421126"
          },
          {
            pid: "421100",
            label: "黄梅县",
            value: "421127"
          },
          {
            pid: "421100",
            label: "麻城市",
            value: "421181"
          },
          {
            pid: "421100",
            label: "武穴市",
            value: "421182"
          }
        ],
        pid: "420000",
        label: "黄冈市",
        value: "421100"
      },
      {
        children: [
          {
            pid: "421200",
            label: "咸安区",
            value: "421202"
          },
          {
            pid: "421200",
            label: "嘉鱼县",
            value: "421221"
          },
          {
            pid: "421200",
            label: "通城县",
            value: "421222"
          },
          {
            pid: "421200",
            label: "崇阳县",
            value: "421223"
          },
          {
            pid: "421200",
            label: "通山县",
            value: "421224"
          },
          {
            pid: "421200",
            label: "赤壁市",
            value: "421281"
          }
        ],
        pid: "420000",
        label: "咸宁市",
        value: "421200"
      },
      {
        children: [
          {
            pid: "421300",
            label: "曾都区",
            value: "421303"
          },
          {
            pid: "421300",
            label: "随县",
            value: "421321"
          },
          {
            pid: "421300",
            label: "广水市",
            value: "421381"
          }
        ],
        pid: "420000",
        label: "随州市",
        value: "421300"
      },
      {
        children: [
          {
            pid: "422800",
            label: "恩施市",
            value: "422801"
          },
          {
            pid: "422800",
            label: "利川市",
            value: "422802"
          },
          {
            pid: "422800",
            label: "建始县",
            value: "422822"
          },
          {
            pid: "422800",
            label: "巴东县",
            value: "422823"
          },
          {
            pid: "422800",
            label: "宣恩县",
            value: "422825"
          },
          {
            pid: "422800",
            label: "咸丰县",
            value: "422826"
          },
          {
            pid: "422800",
            label: "来凤县",
            value: "422827"
          },
          {
            pid: "422800",
            label: "鹤峰县",
            value: "422828"
          }
        ],
        pid: "420000",
        label: "恩施土家族苗族自治州",
        value: "422800"
      }
    ],
    pid: "-1",
    label: "湖北省",
    value: "420000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "430100",
            label: "芙蓉区",
            value: "430102"
          },
          {
            pid: "430100",
            label: "天心区",
            value: "430103"
          },
          {
            pid: "430100",
            label: "岳麓区",
            value: "430104"
          },
          {
            pid: "430100",
            label: "开福区",
            value: "430105"
          },
          {
            pid: "430100",
            label: "雨花区",
            value: "430111"
          },
          {
            pid: "430100",
            label: "望城区",
            value: "430112"
          },
          {
            pid: "430100",
            label: "长沙县",
            value: "430121"
          },
          {
            pid: "430100",
            label: "浏阳市",
            value: "430181"
          },
          {
            pid: "430100",
            label: "宁乡市",
            value: "430182"
          }
        ],
        pid: "430000",
        label: "长沙市",
        value: "430100"
      },
      {
        children: [
          {
            pid: "430200",
            label: "荷塘区",
            value: "430202"
          },
          {
            pid: "430200",
            label: "芦淞区",
            value: "430203"
          },
          {
            pid: "430200",
            label: "石峰区",
            value: "430204"
          },
          {
            pid: "430200",
            label: "天元区",
            value: "430211"
          },
          {
            pid: "430200",
            label: "渌口区",
            value: "430212"
          },
          {
            pid: "430200",
            label: "攸县",
            value: "430223"
          },
          {
            pid: "430200",
            label: "茶陵县",
            value: "430224"
          },
          {
            pid: "430200",
            label: "炎陵县",
            value: "430225"
          },
          {
            pid: "430200",
            label: "醴陵市",
            value: "430281"
          }
        ],
        pid: "430000",
        label: "株洲市",
        value: "430200"
      },
      {
        children: [
          {
            pid: "430300",
            label: "雨湖区",
            value: "430302"
          },
          {
            pid: "430300",
            label: "岳塘区",
            value: "430304"
          },
          {
            pid: "430300",
            label: "湘潭县",
            value: "430321"
          },
          {
            pid: "430300",
            label: "湘乡市",
            value: "430381"
          },
          {
            pid: "430300",
            label: "韶山市",
            value: "430382"
          }
        ],
        pid: "430000",
        label: "湘潭市",
        value: "430300"
      },
      {
        children: [
          {
            pid: "430400",
            label: "珠晖区",
            value: "430405"
          },
          {
            pid: "430400",
            label: "雁峰区",
            value: "430406"
          },
          {
            pid: "430400",
            label: "石鼓区",
            value: "430407"
          },
          {
            pid: "430400",
            label: "蒸湘区",
            value: "430408"
          },
          {
            pid: "430400",
            label: "南岳区",
            value: "430412"
          },
          {
            pid: "430400",
            label: "衡阳县",
            value: "430421"
          },
          {
            pid: "430400",
            label: "衡南县",
            value: "430422"
          },
          {
            pid: "430400",
            label: "衡山县",
            value: "430423"
          },
          {
            pid: "430400",
            label: "衡东县",
            value: "430424"
          },
          {
            pid: "430400",
            label: "祁东县",
            value: "430426"
          },
          {
            pid: "430400",
            label: "耒阳市",
            value: "430481"
          },
          {
            pid: "430400",
            label: "常宁市",
            value: "430482"
          }
        ],
        pid: "430000",
        label: "衡阳市",
        value: "430400"
      },
      {
        children: [
          {
            pid: "430500",
            label: "双清区",
            value: "430502"
          },
          {
            pid: "430500",
            label: "大祥区",
            value: "430503"
          },
          {
            pid: "430500",
            label: "北塔区",
            value: "430511"
          },
          {
            pid: "430500",
            label: "邵东县",
            value: "430521"
          },
          {
            pid: "430500",
            label: "新邵县",
            value: "430522"
          },
          {
            pid: "430500",
            label: "邵阳县",
            value: "430523"
          },
          {
            pid: "430500",
            label: "隆回县",
            value: "430524"
          },
          {
            pid: "430500",
            label: "洞口县",
            value: "430525"
          },
          {
            pid: "430500",
            label: "绥宁县",
            value: "430527"
          },
          {
            pid: "430500",
            label: "新宁县",
            value: "430528"
          },
          {
            pid: "430500",
            label: "城步苗族自治县",
            value: "430529"
          },
          {
            pid: "430500",
            label: "武冈市",
            value: "430581"
          }
        ],
        pid: "430000",
        label: "邵阳市",
        value: "430500"
      },
      {
        children: [
          {
            pid: "430600",
            label: "岳阳楼区",
            value: "430602"
          },
          {
            pid: "430600",
            label: "云溪区",
            value: "430603"
          },
          {
            pid: "430600",
            label: "君山区",
            value: "430611"
          },
          {
            pid: "430600",
            label: "岳阳县",
            value: "430621"
          },
          {
            pid: "430600",
            label: "华容县",
            value: "430623"
          },
          {
            pid: "430600",
            label: "湘阴县",
            value: "430624"
          },
          {
            pid: "430600",
            label: "平江县",
            value: "430626"
          },
          {
            pid: "430600",
            label: "汨罗市",
            value: "430681"
          },
          {
            pid: "430600",
            label: "临湘市",
            value: "430682"
          }
        ],
        pid: "430000",
        label: "岳阳市",
        value: "430600"
      },
      {
        children: [
          {
            pid: "430700",
            label: "武陵区",
            value: "430702"
          },
          {
            pid: "430700",
            label: "鼎城区",
            value: "430703"
          },
          {
            pid: "430700",
            label: "安乡县",
            value: "430721"
          },
          {
            pid: "430700",
            label: "汉寿县",
            value: "430722"
          },
          {
            pid: "430700",
            label: "澧县",
            value: "430723"
          },
          {
            pid: "430700",
            label: "临澧县",
            value: "430724"
          },
          {
            pid: "430700",
            label: "桃源县",
            value: "430725"
          },
          {
            pid: "430700",
            label: "石门县",
            value: "430726"
          },
          {
            pid: "430700",
            label: "津市市",
            value: "430781"
          }
        ],
        pid: "430000",
        label: "常德市",
        value: "430700"
      },
      {
        children: [
          {
            pid: "430800",
            label: "永定区",
            value: "430802"
          },
          {
            pid: "430800",
            label: "武陵源区",
            value: "430811"
          },
          {
            pid: "430800",
            label: "慈利县",
            value: "430821"
          },
          {
            pid: "430800",
            label: "桑植县",
            value: "430822"
          }
        ],
        pid: "430000",
        label: "张家界市",
        value: "430800"
      },
      {
        children: [
          {
            pid: "430900",
            label: "资阳区",
            value: "430902"
          },
          {
            pid: "430900",
            label: "赫山区",
            value: "430903"
          },
          {
            pid: "430900",
            label: "南县",
            value: "430921"
          },
          {
            pid: "430900",
            label: "桃江县",
            value: "430922"
          },
          {
            pid: "430900",
            label: "安化县",
            value: "430923"
          },
          {
            pid: "430900",
            label: "沅江市",
            value: "430981"
          }
        ],
        pid: "430000",
        label: "益阳市",
        value: "430900"
      },
      {
        children: [
          {
            pid: "431000",
            label: "北湖区",
            value: "431002"
          },
          {
            pid: "431000",
            label: "苏仙区",
            value: "431003"
          },
          {
            pid: "431000",
            label: "桂阳县",
            value: "431021"
          },
          {
            pid: "431000",
            label: "宜章县",
            value: "431022"
          },
          {
            pid: "431000",
            label: "永兴县",
            value: "431023"
          },
          {
            pid: "431000",
            label: "嘉禾县",
            value: "431024"
          },
          {
            pid: "431000",
            label: "临武县",
            value: "431025"
          },
          {
            pid: "431000",
            label: "汝城县",
            value: "431026"
          },
          {
            pid: "431000",
            label: "桂东县",
            value: "431027"
          },
          {
            pid: "431000",
            label: "安仁县",
            value: "431028"
          },
          {
            pid: "431000",
            label: "资兴市",
            value: "431081"
          }
        ],
        pid: "430000",
        label: "郴州市",
        value: "431000"
      },
      {
        children: [
          {
            pid: "431100",
            label: "零陵区",
            value: "431102"
          },
          {
            pid: "431100",
            label: "冷水滩区",
            value: "431103"
          },
          {
            pid: "431100",
            label: "祁阳县",
            value: "431121"
          },
          {
            pid: "431100",
            label: "东安县",
            value: "431122"
          },
          {
            pid: "431100",
            label: "双牌县",
            value: "431123"
          },
          {
            pid: "431100",
            label: "道县",
            value: "431124"
          },
          {
            pid: "431100",
            label: "江永县",
            value: "431125"
          },
          {
            pid: "431100",
            label: "宁远县",
            value: "431126"
          },
          {
            pid: "431100",
            label: "蓝山县",
            value: "431127"
          },
          {
            pid: "431100",
            label: "新田县",
            value: "431128"
          },
          {
            pid: "431100",
            label: "江华瑶族自治县",
            value: "431129"
          }
        ],
        pid: "430000",
        label: "永州市",
        value: "431100"
      },
      {
        children: [
          {
            pid: "431200",
            label: "鹤城区",
            value: "431202"
          },
          {
            pid: "431200",
            label: "中方县",
            value: "431221"
          },
          {
            pid: "431200",
            label: "沅陵县",
            value: "431222"
          },
          {
            pid: "431200",
            label: "辰溪县",
            value: "431223"
          },
          {
            pid: "431200",
            label: "溆浦县",
            value: "431224"
          },
          {
            pid: "431200",
            label: "会同县",
            value: "431225"
          },
          {
            pid: "431200",
            label: "麻阳苗族自治县",
            value: "431226"
          },
          {
            pid: "431200",
            label: "新晃侗族自治县",
            value: "431227"
          },
          {
            pid: "431200",
            label: "芷江侗族自治县",
            value: "431228"
          },
          {
            pid: "431200",
            label: "靖州苗族侗族自治县",
            value: "431229"
          },
          {
            pid: "431200",
            label: "通道侗族自治县",
            value: "431230"
          },
          {
            pid: "431200",
            label: "洪江市",
            value: "431281"
          }
        ],
        pid: "430000",
        label: "怀化市",
        value: "431200"
      },
      {
        children: [
          {
            pid: "431300",
            label: "娄星区",
            value: "431302"
          },
          {
            pid: "431300",
            label: "双峰县",
            value: "431321"
          },
          {
            pid: "431300",
            label: "新化县",
            value: "431322"
          },
          {
            pid: "431300",
            label: "冷水江市",
            value: "431381"
          },
          {
            pid: "431300",
            label: "涟源市",
            value: "431382"
          }
        ],
        pid: "430000",
        label: "娄底市",
        value: "431300"
      },
      {
        children: [
          {
            pid: "433100",
            label: "吉首市",
            value: "433101"
          },
          {
            pid: "433100",
            label: "泸溪县",
            value: "433122"
          },
          {
            pid: "433100",
            label: "凤凰县",
            value: "433123"
          },
          {
            pid: "433100",
            label: "花垣县",
            value: "433124"
          },
          {
            pid: "433100",
            label: "保靖县",
            value: "433125"
          },
          {
            pid: "433100",
            label: "古丈县",
            value: "433126"
          },
          {
            pid: "433100",
            label: "永顺县",
            value: "433127"
          },
          {
            pid: "433100",
            label: "龙山县",
            value: "433130"
          }
        ],
        pid: "430000",
        label: "湘西土家族苗族自治州",
        value: "433100"
      }
    ],
    pid: "-1",
    label: "湖南省",
    value: "430000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "440100",
            label: "荔湾区",
            value: "440103"
          },
          {
            pid: "440100",
            label: "越秀区",
            value: "440104"
          },
          {
            pid: "440100",
            label: "海珠区",
            value: "440105"
          },
          {
            pid: "440100",
            label: "天河区",
            value: "440106"
          },
          {
            pid: "440100",
            label: "白云区",
            value: "440111"
          },
          {
            pid: "440100",
            label: "黄埔区",
            value: "440112"
          },
          {
            pid: "440100",
            label: "番禺区",
            value: "440113"
          },
          {
            pid: "440100",
            label: "花都区",
            value: "440114"
          },
          {
            pid: "440100",
            label: "南沙区",
            value: "440115"
          },
          {
            pid: "440100",
            label: "从化区",
            value: "440117"
          },
          {
            pid: "440100",
            label: "增城区",
            value: "440118"
          }
        ],
        pid: "440000",
        label: "广州市",
        value: "440100"
      },
      {
        children: [
          {
            pid: "440200",
            label: "武江区",
            value: "440203"
          },
          {
            pid: "440200",
            label: "浈江区",
            value: "440204"
          },
          {
            pid: "440200",
            label: "曲江区",
            value: "440205"
          },
          {
            pid: "440200",
            label: "始兴县",
            value: "440222"
          },
          {
            pid: "440200",
            label: "仁化县",
            value: "440224"
          },
          {
            pid: "440200",
            label: "翁源县",
            value: "440229"
          },
          {
            pid: "440200",
            label: "乳源瑶族自治县",
            value: "440232"
          },
          {
            pid: "440200",
            label: "新丰县",
            value: "440233"
          },
          {
            pid: "440200",
            label: "乐昌市",
            value: "440281"
          },
          {
            pid: "440200",
            label: "南雄市",
            value: "440282"
          }
        ],
        pid: "440000",
        label: "韶关市",
        value: "440200"
      },
      {
        children: [
          {
            pid: "440300",
            label: "罗湖区",
            value: "440303"
          },
          {
            pid: "440300",
            label: "福田区",
            value: "440304"
          },
          {
            pid: "440300",
            label: "南山区",
            value: "440305"
          },
          {
            pid: "440300",
            label: "宝安区",
            value: "440306"
          },
          {
            pid: "440300",
            label: "龙岗区",
            value: "440307"
          },
          {
            pid: "440300",
            label: "盐田区",
            value: "440308"
          },
          {
            pid: "440300",
            label: "龙华区",
            value: "440309"
          },
          {
            pid: "440300",
            label: "坪山区",
            value: "440310"
          },
          {
            pid: "440300",
            label: "光明区",
            value: "440311"
          }
        ],
        pid: "440000",
        label: "深圳市",
        value: "440300"
      },
      {
        children: [
          {
            pid: "440400",
            label: "香洲区",
            value: "440402"
          },
          {
            pid: "440400",
            label: "斗门区",
            value: "440403"
          },
          {
            pid: "440400",
            label: "金湾区",
            value: "440404"
          }
        ],
        pid: "440000",
        label: "珠海市",
        value: "440400"
      },
      {
        children: [
          {
            pid: "440500",
            label: "龙湖区",
            value: "440507"
          },
          {
            pid: "440500",
            label: "金平区",
            value: "440511"
          },
          {
            pid: "440500",
            label: "濠江区",
            value: "440512"
          },
          {
            pid: "440500",
            label: "潮阳区",
            value: "440513"
          },
          {
            pid: "440500",
            label: "潮南区",
            value: "440514"
          },
          {
            pid: "440500",
            label: "澄海区",
            value: "440515"
          },
          {
            pid: "440500",
            label: "南澳县",
            value: "440523"
          }
        ],
        pid: "440000",
        label: "汕头市",
        value: "440500"
      },
      {
        children: [
          {
            pid: "440600",
            label: "禅城区",
            value: "440604"
          },
          {
            pid: "440600",
            label: "南海区",
            value: "440605"
          },
          {
            pid: "440600",
            label: "顺德区",
            value: "440606"
          },
          {
            pid: "440600",
            label: "三水区",
            value: "440607"
          },
          {
            pid: "440600",
            label: "高明区",
            value: "440608"
          }
        ],
        pid: "440000",
        label: "佛山市",
        value: "440600"
      },
      {
        children: [
          {
            pid: "440700",
            label: "蓬江区",
            value: "440703"
          },
          {
            pid: "440700",
            label: "江海区",
            value: "440704"
          },
          {
            pid: "440700",
            label: "新会区",
            value: "440705"
          },
          {
            pid: "440700",
            label: "台山市",
            value: "440781"
          },
          {
            pid: "440700",
            label: "开平市",
            value: "440783"
          },
          {
            pid: "440700",
            label: "鹤山市",
            value: "440784"
          },
          {
            pid: "440700",
            label: "恩平市",
            value: "440785"
          }
        ],
        pid: "440000",
        label: "江门市",
        value: "440700"
      },
      {
        children: [
          {
            pid: "440800",
            label: "赤坎区",
            value: "440802"
          },
          {
            pid: "440800",
            label: "霞山区",
            value: "440803"
          },
          {
            pid: "440800",
            label: "坡头区",
            value: "440804"
          },
          {
            pid: "440800",
            label: "麻章区",
            value: "440811"
          },
          {
            pid: "440800",
            label: "遂溪县",
            value: "440823"
          },
          {
            pid: "440800",
            label: "徐闻县",
            value: "440825"
          },
          {
            pid: "440800",
            label: "廉江市",
            value: "440881"
          },
          {
            pid: "440800",
            label: "雷州市",
            value: "440882"
          },
          {
            pid: "440800",
            label: "吴川市",
            value: "440883"
          }
        ],
        pid: "440000",
        label: "湛江市",
        value: "440800"
      },
      {
        children: [
          {
            pid: "440900",
            label: "茂南区",
            value: "440902"
          },
          {
            pid: "440900",
            label: "电白区",
            value: "440904"
          },
          {
            pid: "440900",
            label: "高州市",
            value: "440981"
          },
          {
            pid: "440900",
            label: "化州市",
            value: "440982"
          },
          {
            pid: "440900",
            label: "信宜市",
            value: "440983"
          }
        ],
        pid: "440000",
        label: "茂名市",
        value: "440900"
      },
      {
        children: [
          {
            pid: "441200",
            label: "端州区",
            value: "441202"
          },
          {
            pid: "441200",
            label: "鼎湖区",
            value: "441203"
          },
          {
            pid: "441200",
            label: "高要区",
            value: "441204"
          },
          {
            pid: "441200",
            label: "广宁县",
            value: "441223"
          },
          {
            pid: "441200",
            label: "怀集县",
            value: "441224"
          },
          {
            pid: "441200",
            label: "封开县",
            value: "441225"
          },
          {
            pid: "441200",
            label: "德庆县",
            value: "441226"
          },
          {
            pid: "441200",
            label: "四会市",
            value: "441284"
          }
        ],
        pid: "440000",
        label: "肇庆市",
        value: "441200"
      },
      {
        children: [
          {
            pid: "441300",
            label: "惠城区",
            value: "441302"
          },
          {
            pid: "441300",
            label: "惠阳区",
            value: "441303"
          },
          {
            pid: "441300",
            label: "博罗县",
            value: "441322"
          },
          {
            pid: "441300",
            label: "惠东县",
            value: "441323"
          },
          {
            pid: "441300",
            label: "龙门县",
            value: "441324"
          }
        ],
        pid: "440000",
        label: "惠州市",
        value: "441300"
      },
      {
        children: [
          {
            pid: "441400",
            label: "梅江区",
            value: "441402"
          },
          {
            pid: "441400",
            label: "梅县区",
            value: "441403"
          },
          {
            pid: "441400",
            label: "大埔县",
            value: "441422"
          },
          {
            pid: "441400",
            label: "丰顺县",
            value: "441423"
          },
          {
            pid: "441400",
            label: "五华县",
            value: "441424"
          },
          {
            pid: "441400",
            label: "平远县",
            value: "441426"
          },
          {
            pid: "441400",
            label: "蕉岭县",
            value: "441427"
          },
          {
            pid: "441400",
            label: "兴宁市",
            value: "441481"
          }
        ],
        pid: "440000",
        label: "梅州市",
        value: "441400"
      },
      {
        children: [
          {
            pid: "441500",
            label: "城区",
            value: "441502"
          },
          {
            pid: "441500",
            label: "海丰县",
            value: "441521"
          },
          {
            pid: "441500",
            label: "陆河县",
            value: "441523"
          },
          {
            pid: "441500",
            label: "陆丰市",
            value: "441581"
          }
        ],
        pid: "440000",
        label: "汕尾市",
        value: "441500"
      },
      {
        children: [
          {
            pid: "441600",
            label: "源城区",
            value: "441602"
          },
          {
            pid: "441600",
            label: "紫金县",
            value: "441621"
          },
          {
            pid: "441600",
            label: "龙川县",
            value: "441622"
          },
          {
            pid: "441600",
            label: "连平县",
            value: "441623"
          },
          {
            pid: "441600",
            label: "和平县",
            value: "441624"
          },
          {
            pid: "441600",
            label: "东源县",
            value: "441625"
          }
        ],
        pid: "440000",
        label: "河源市",
        value: "441600"
      },
      {
        children: [
          {
            pid: "441700",
            label: "江城区",
            value: "441702"
          },
          {
            pid: "441700",
            label: "阳东区",
            value: "441704"
          },
          {
            pid: "441700",
            label: "阳西县",
            value: "441721"
          },
          {
            pid: "441700",
            label: "阳春市",
            value: "441781"
          }
        ],
        pid: "440000",
        label: "阳江市",
        value: "441700"
      },
      {
        children: [
          {
            pid: "441800",
            label: "清城区",
            value: "441802"
          },
          {
            pid: "441800",
            label: "清新区",
            value: "441803"
          },
          {
            pid: "441800",
            label: "佛冈县",
            value: "441821"
          },
          {
            pid: "441800",
            label: "阳山县",
            value: "441823"
          },
          {
            pid: "441800",
            label: "连山壮族瑶族自治县",
            value: "441825"
          },
          {
            pid: "441800",
            label: "连南瑶族自治县",
            value: "441826"
          },
          {
            pid: "441800",
            label: "英德市",
            value: "441881"
          },
          {
            pid: "441800",
            label: "连州市",
            value: "441882"
          }
        ],
        pid: "440000",
        label: "清远市",
        value: "441800"
      },
      {
        children: [
          {
            pid: "441900",
            label: "东莞区/县",
            value: "441999"
          }
        ],
        pid: "440000",
        label: "东莞市",
        value: "441900"
      },
      {
        children: [
          {
            pid: "442000",
            label: "中山区/县",
            value: "442099"
          }
        ],
        pid: "440000",
        label: "中山市",
        value: "442000"
      },
      {
        children: [
          {
            pid: "445100",
            label: "湘桥区",
            value: "445102"
          },
          {
            pid: "445100",
            label: "潮安区",
            value: "445103"
          },
          {
            pid: "445100",
            label: "饶平县",
            value: "445122"
          }
        ],
        pid: "440000",
        label: "潮州市",
        value: "445100"
      },
      {
        children: [
          {
            pid: "445200",
            label: "榕城区",
            value: "445202"
          },
          {
            pid: "445200",
            label: "揭东区",
            value: "445203"
          },
          {
            pid: "445200",
            label: "揭西县",
            value: "445222"
          },
          {
            pid: "445200",
            label: "惠来县",
            value: "445224"
          },
          {
            pid: "445200",
            label: "普宁市",
            value: "445281"
          }
        ],
        pid: "440000",
        label: "揭阳市",
        value: "445200"
      },
      {
        children: [
          {
            pid: "445300",
            label: "云城区",
            value: "445302"
          },
          {
            pid: "445300",
            label: "云安区",
            value: "445303"
          },
          {
            pid: "445300",
            label: "新兴县",
            value: "445321"
          },
          {
            pid: "445300",
            label: "郁南县",
            value: "445322"
          },
          {
            pid: "445300",
            label: "罗定市",
            value: "445381"
          }
        ],
        pid: "440000",
        label: "云浮市",
        value: "445300"
      }
    ],
    pid: "-1",
    label: "广东省",
    value: "440000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "450100",
            label: "兴宁区",
            value: "450102"
          },
          {
            pid: "450100",
            label: "青秀区",
            value: "450103"
          },
          {
            pid: "450100",
            label: "江南区",
            value: "450105"
          },
          {
            pid: "450100",
            label: "西乡塘区",
            value: "450107"
          },
          {
            pid: "450100",
            label: "良庆区",
            value: "450108"
          },
          {
            pid: "450100",
            label: "邕宁区",
            value: "450109"
          },
          {
            pid: "450100",
            label: "武鸣区",
            value: "450110"
          },
          {
            pid: "450100",
            label: "隆安县",
            value: "450123"
          },
          {
            pid: "450100",
            label: "马山县",
            value: "450124"
          },
          {
            pid: "450100",
            label: "上林县",
            value: "450125"
          },
          {
            pid: "450100",
            label: "宾阳县",
            value: "450126"
          },
          {
            pid: "450100",
            label: "横县",
            value: "450127"
          }
        ],
        pid: "450000",
        label: "南宁市",
        value: "450100"
      },
      {
        children: [
          {
            pid: "450200",
            label: "城中区",
            value: "450202"
          },
          {
            pid: "450200",
            label: "鱼峰区",
            value: "450203"
          },
          {
            pid: "450200",
            label: "柳南区",
            value: "450204"
          },
          {
            pid: "450200",
            label: "柳北区",
            value: "450205"
          },
          {
            pid: "450200",
            label: "柳江区",
            value: "450206"
          },
          {
            pid: "450200",
            label: "柳城县",
            value: "450222"
          },
          {
            pid: "450200",
            label: "鹿寨县",
            value: "450223"
          },
          {
            pid: "450200",
            label: "融安县",
            value: "450224"
          },
          {
            pid: "450200",
            label: "融水苗族自治县",
            value: "450225"
          },
          {
            pid: "450200",
            label: "三江侗族自治县",
            value: "450226"
          }
        ],
        pid: "450000",
        label: "柳州市",
        value: "450200"
      },
      {
        children: [
          {
            pid: "450300",
            label: "秀峰区",
            value: "450302"
          },
          {
            pid: "450300",
            label: "叠彩区",
            value: "450303"
          },
          {
            pid: "450300",
            label: "象山区",
            value: "450304"
          },
          {
            pid: "450300",
            label: "七星区",
            value: "450305"
          },
          {
            pid: "450300",
            label: "雁山区",
            value: "450311"
          },
          {
            pid: "450300",
            label: "临桂区",
            value: "450312"
          },
          {
            pid: "450300",
            label: "阳朔县",
            value: "450321"
          },
          {
            pid: "450300",
            label: "灵川县",
            value: "450323"
          },
          {
            pid: "450300",
            label: "全州县",
            value: "450324"
          },
          {
            pid: "450300",
            label: "兴安县",
            value: "450325"
          },
          {
            pid: "450300",
            label: "永福县",
            value: "450326"
          },
          {
            pid: "450300",
            label: "灌阳县",
            value: "450327"
          },
          {
            pid: "450300",
            label: "龙胜各族自治县",
            value: "450328"
          },
          {
            pid: "450300",
            label: "资源县",
            value: "450329"
          },
          {
            pid: "450300",
            label: "平乐县",
            value: "450330"
          },
          {
            pid: "450300",
            label: "恭城瑶族自治县",
            value: "450332"
          },
          {
            pid: "450300",
            label: "荔浦市",
            value: "450381"
          }
        ],
        pid: "450000",
        label: "桂林市",
        value: "450300"
      },
      {
        children: [
          {
            pid: "450400",
            label: "万秀区",
            value: "450403"
          },
          {
            pid: "450400",
            label: "长洲区",
            value: "450405"
          },
          {
            pid: "450400",
            label: "龙圩区",
            value: "450406"
          },
          {
            pid: "450400",
            label: "苍梧县",
            value: "450421"
          },
          {
            pid: "450400",
            label: "藤县",
            value: "450422"
          },
          {
            pid: "450400",
            label: "蒙山县",
            value: "450423"
          },
          {
            pid: "450400",
            label: "岑溪市",
            value: "450481"
          }
        ],
        pid: "450000",
        label: "梧州市",
        value: "450400"
      },
      {
        children: [
          {
            pid: "450500",
            label: "海城区",
            value: "450502"
          },
          {
            pid: "450500",
            label: "银海区",
            value: "450503"
          },
          {
            pid: "450500",
            label: "铁山港区",
            value: "450512"
          },
          {
            pid: "450500",
            label: "合浦县",
            value: "450521"
          }
        ],
        pid: "450000",
        label: "北海市",
        value: "450500"
      },
      {
        children: [
          {
            pid: "450600",
            label: "港口区",
            value: "450602"
          },
          {
            pid: "450600",
            label: "防城区",
            value: "450603"
          },
          {
            pid: "450600",
            label: "上思县",
            value: "450621"
          },
          {
            pid: "450600",
            label: "东兴市",
            value: "450681"
          }
        ],
        pid: "450000",
        label: "防城港市",
        value: "450600"
      },
      {
        children: [
          {
            pid: "450700",
            label: "钦南区",
            value: "450702"
          },
          {
            pid: "450700",
            label: "钦北区",
            value: "450703"
          },
          {
            pid: "450700",
            label: "灵山县",
            value: "450721"
          },
          {
            pid: "450700",
            label: "浦北县",
            value: "450722"
          }
        ],
        pid: "450000",
        label: "钦州市",
        value: "450700"
      },
      {
        children: [
          {
            pid: "450800",
            label: "港北区",
            value: "450802"
          },
          {
            pid: "450800",
            label: "港南区",
            value: "450803"
          },
          {
            pid: "450800",
            label: "覃塘区",
            value: "450804"
          },
          {
            pid: "450800",
            label: "平南县",
            value: "450821"
          },
          {
            pid: "450800",
            label: "桂平市",
            value: "450881"
          }
        ],
        pid: "450000",
        label: "贵港市",
        value: "450800"
      },
      {
        children: [
          {
            pid: "450900",
            label: "玉州区",
            value: "450902"
          },
          {
            pid: "450900",
            label: "福绵区",
            value: "450903"
          },
          {
            pid: "450900",
            label: "容县",
            value: "450921"
          },
          {
            pid: "450900",
            label: "陆川县",
            value: "450922"
          },
          {
            pid: "450900",
            label: "博白县",
            value: "450923"
          },
          {
            pid: "450900",
            label: "兴业县",
            value: "450924"
          },
          {
            pid: "450900",
            label: "北流市",
            value: "450981"
          }
        ],
        pid: "450000",
        label: "玉林市",
        value: "450900"
      },
      {
        children: [
          {
            pid: "451000",
            label: "右江区",
            value: "451002"
          },
          {
            pid: "451000",
            label: "田阳县",
            value: "451021"
          },
          {
            pid: "451000",
            label: "田东县",
            value: "451022"
          },
          {
            pid: "451000",
            label: "平果县",
            value: "451023"
          },
          {
            pid: "451000",
            label: "德保县",
            value: "451024"
          },
          {
            pid: "451000",
            label: "那坡县",
            value: "451026"
          },
          {
            pid: "451000",
            label: "凌云县",
            value: "451027"
          },
          {
            pid: "451000",
            label: "乐业县",
            value: "451028"
          },
          {
            pid: "451000",
            label: "田林县",
            value: "451029"
          },
          {
            pid: "451000",
            label: "西林县",
            value: "451030"
          },
          {
            pid: "451000",
            label: "隆林各族自治县",
            value: "451031"
          },
          {
            pid: "451000",
            label: "靖西市",
            value: "451081"
          }
        ],
        pid: "450000",
        label: "百色市",
        value: "451000"
      },
      {
        children: [
          {
            pid: "451100",
            label: "八步区",
            value: "451102"
          },
          {
            pid: "451100",
            label: "平桂区",
            value: "451103"
          },
          {
            pid: "451100",
            label: "昭平县",
            value: "451121"
          },
          {
            pid: "451100",
            label: "钟山县",
            value: "451122"
          },
          {
            pid: "451100",
            label: "富川瑶族自治县",
            value: "451123"
          }
        ],
        pid: "450000",
        label: "贺州市",
        value: "451100"
      },
      {
        children: [
          {
            pid: "451200",
            label: "金城江区",
            value: "451202"
          },
          {
            pid: "451200",
            label: "宜州区",
            value: "451203"
          },
          {
            pid: "451200",
            label: "南丹县",
            value: "451221"
          },
          {
            pid: "451200",
            label: "天峨县",
            value: "451222"
          },
          {
            pid: "451200",
            label: "凤山县",
            value: "451223"
          },
          {
            pid: "451200",
            label: "东兰县",
            value: "451224"
          },
          {
            pid: "451200",
            label: "罗城仫佬族自治县",
            value: "451225"
          },
          {
            pid: "451200",
            label: "环江毛南族自治县",
            value: "451226"
          },
          {
            pid: "451200",
            label: "巴马瑶族自治县",
            value: "451227"
          },
          {
            pid: "451200",
            label: "都安瑶族自治县",
            value: "451228"
          },
          {
            pid: "451200",
            label: "大化瑶族自治县",
            value: "451229"
          }
        ],
        pid: "450000",
        label: "河池市",
        value: "451200"
      },
      {
        children: [
          {
            pid: "451300",
            label: "兴宾区",
            value: "451302"
          },
          {
            pid: "451300",
            label: "忻城县",
            value: "451321"
          },
          {
            pid: "451300",
            label: "象州县",
            value: "451322"
          },
          {
            pid: "451300",
            label: "武宣县",
            value: "451323"
          },
          {
            pid: "451300",
            label: "金秀瑶族自治县",
            value: "451324"
          },
          {
            pid: "451300",
            label: "合山市",
            value: "451381"
          }
        ],
        pid: "450000",
        label: "来宾市",
        value: "451300"
      },
      {
        children: [
          {
            pid: "451400",
            label: "江州区",
            value: "451402"
          },
          {
            pid: "451400",
            label: "扶绥县",
            value: "451421"
          },
          {
            pid: "451400",
            label: "宁明县",
            value: "451422"
          },
          {
            pid: "451400",
            label: "龙州县",
            value: "451423"
          },
          {
            pid: "451400",
            label: "大新县",
            value: "451424"
          },
          {
            pid: "451400",
            label: "天等县",
            value: "451425"
          },
          {
            pid: "451400",
            label: "凭祥市",
            value: "451481"
          }
        ],
        pid: "450000",
        label: "崇左市",
        value: "451400"
      }
    ],
    pid: "-1",
    label: "广西壮族自治区",
    value: "450000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "460100",
            label: "秀英区",
            value: "460105"
          },
          {
            pid: "460100",
            label: "龙华区",
            value: "460106"
          },
          {
            pid: "460100",
            label: "琼山区",
            value: "460107"
          },
          {
            pid: "460100",
            label: "美兰区",
            value: "460108"
          }
        ],
        pid: "460000",
        label: "海口市",
        value: "460100"
      },
      {
        children: [
          {
            pid: "460200",
            label: "海棠区",
            value: "460202"
          },
          {
            pid: "460200",
            label: "吉阳区",
            value: "460203"
          },
          {
            pid: "460200",
            label: "天涯区",
            value: "460204"
          },
          {
            pid: "460200",
            label: "崖州区",
            value: "460205"
          }
        ],
        pid: "460000",
        label: "三亚市",
        value: "460200"
      },
      {
        pid: "460000",
        label: "三沙市",
        value: "460300"
      },
      {
        pid: "460000",
        label: "儋州市",
        value: "460400"
      }
    ],
    pid: "-1",
    label: "海南省",
    value: "460000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "500100",
            label: "万州区",
            value: "500101"
          },
          {
            pid: "500100",
            label: "涪陵区",
            value: "500102"
          },
          {
            pid: "500100",
            label: "渝中区",
            value: "500103"
          },
          {
            pid: "500100",
            label: "大渡口区",
            value: "500104"
          },
          {
            pid: "500100",
            label: "江北区",
            value: "500105"
          },
          {
            pid: "500100",
            label: "沙坪坝区",
            value: "500106"
          },
          {
            pid: "500100",
            label: "九龙坡区",
            value: "500107"
          },
          {
            pid: "500100",
            label: "南岸区",
            value: "500108"
          },
          {
            pid: "500100",
            label: "北碚区",
            value: "500109"
          },
          {
            pid: "500100",
            label: "綦江区",
            value: "500110"
          },
          {
            pid: "500100",
            label: "大足区",
            value: "500111"
          },
          {
            pid: "500100",
            label: "渝北区",
            value: "500112"
          },
          {
            pid: "500100",
            label: "巴南区",
            value: "500113"
          },
          {
            pid: "500100",
            label: "黔江区",
            value: "500114"
          },
          {
            pid: "500100",
            label: "长寿区",
            value: "500115"
          },
          {
            pid: "500100",
            label: "江津区",
            value: "500116"
          },
          {
            pid: "500100",
            label: "合川区",
            value: "500117"
          },
          {
            pid: "500100",
            label: "永川区",
            value: "500118"
          },
          {
            pid: "500100",
            label: "南川区",
            value: "500119"
          },
          {
            pid: "500100",
            label: "璧山区",
            value: "500120"
          },
          {
            pid: "500100",
            label: "铜梁区",
            value: "500151"
          },
          {
            pid: "500100",
            label: "潼南区",
            value: "500152"
          },
          {
            pid: "500100",
            label: "荣昌区",
            value: "500153"
          },
          {
            pid: "500100",
            label: "开州区",
            value: "500154"
          },
          {
            pid: "500100",
            label: "梁平区",
            value: "500155"
          },
          {
            pid: "500100",
            label: "武隆区",
            value: "500156"
          },
          {
            pid: "500100",
            label: "城口县",
            value: "500229"
          },
          {
            pid: "500100",
            label: "丰都县",
            value: "500230"
          },
          {
            pid: "500100",
            label: "垫江县",
            value: "500231"
          },
          {
            pid: "500100",
            label: "忠县",
            value: "500233"
          },
          {
            pid: "500100",
            label: "云阳县",
            value: "500235"
          },
          {
            pid: "500100",
            label: "奉节县",
            value: "500236"
          },
          {
            pid: "500100",
            label: "巫山县",
            value: "500237"
          },
          {
            pid: "500100",
            label: "巫溪县",
            value: "500238"
          },
          {
            pid: "500100",
            label: "石柱土家族自治县",
            value: "500240"
          },
          {
            pid: "500100",
            label: "秀山土家族苗族自治县",
            value: "500241"
          },
          {
            pid: "500100",
            label: "酉阳土家族苗族自治县",
            value: "500242"
          },
          {
            pid: "500100",
            label: "彭水苗族土家族自治县",
            value: "500243"
          }
        ],
        pid: "500000",
        label: "重庆市",
        value: "500100"
      }
    ],
    pid: "-1",
    label: "重庆市",
    value: "500000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "510100",
            label: "锦江区",
            value: "510104"
          },
          {
            pid: "510100",
            label: "青羊区",
            value: "510105"
          },
          {
            pid: "510100",
            label: "金牛区",
            value: "510106"
          },
          {
            pid: "510100",
            label: "武侯区",
            value: "510107"
          },
          {
            pid: "510100",
            label: "成华区",
            value: "510108"
          },
          {
            pid: "510100",
            label: "龙泉驿区",
            value: "510112"
          },
          {
            pid: "510100",
            label: "青白江区",
            value: "510113"
          },
          {
            pid: "510100",
            label: "新都区",
            value: "510114"
          },
          {
            pid: "510100",
            label: "温江区",
            value: "510115"
          },
          {
            pid: "510100",
            label: "双流区",
            value: "510116"
          },
          {
            pid: "510100",
            label: "郫都区",
            value: "510117"
          },
          {
            pid: "510100",
            label: "金堂县",
            value: "510121"
          },
          {
            pid: "510100",
            label: "大邑县",
            value: "510129"
          },
          {
            pid: "510100",
            label: "蒲江县",
            value: "510131"
          },
          {
            pid: "510100",
            label: "新津县",
            value: "510132"
          },
          {
            pid: "510100",
            label: "都江堰市",
            value: "510181"
          },
          {
            pid: "510100",
            label: "彭州市",
            value: "510182"
          },
          {
            pid: "510100",
            label: "邛崃市",
            value: "510183"
          },
          {
            pid: "510100",
            label: "崇州市",
            value: "510184"
          },
          {
            pid: "510100",
            label: "简阳市",
            value: "510185"
          }
        ],
        pid: "510000",
        label: "成都市",
        value: "510100"
      },
      {
        children: [
          {
            pid: "510300",
            label: "自流井区",
            value: "510302"
          },
          {
            pid: "510300",
            label: "贡井区",
            value: "510303"
          },
          {
            pid: "510300",
            label: "大安区",
            value: "510304"
          },
          {
            pid: "510300",
            label: "沿滩区",
            value: "510311"
          },
          {
            pid: "510300",
            label: "荣县",
            value: "510321"
          },
          {
            pid: "510300",
            label: "富顺县",
            value: "510322"
          }
        ],
        pid: "510000",
        label: "自贡市",
        value: "510300"
      },
      {
        children: [
          {
            pid: "510400",
            label: "东区",
            value: "510402"
          },
          {
            pid: "510400",
            label: "西区",
            value: "510403"
          },
          {
            pid: "510400",
            label: "仁和区",
            value: "510411"
          },
          {
            pid: "510400",
            label: "米易县",
            value: "510421"
          },
          {
            pid: "510400",
            label: "盐边县",
            value: "510422"
          }
        ],
        pid: "510000",
        label: "攀枝花市",
        value: "510400"
      },
      {
        children: [
          {
            pid: "510500",
            label: "江阳区",
            value: "510502"
          },
          {
            pid: "510500",
            label: "纳溪区",
            value: "510503"
          },
          {
            pid: "510500",
            label: "龙马潭区",
            value: "510504"
          },
          {
            pid: "510500",
            label: "泸县",
            value: "510521"
          },
          {
            pid: "510500",
            label: "合江县",
            value: "510522"
          },
          {
            pid: "510500",
            label: "叙永县",
            value: "510524"
          },
          {
            pid: "510500",
            label: "古蔺县",
            value: "510525"
          }
        ],
        pid: "510000",
        label: "泸州市",
        value: "510500"
      },
      {
        children: [
          {
            pid: "510600",
            label: "旌阳区",
            value: "510603"
          },
          {
            pid: "510600",
            label: "罗江区",
            value: "510604"
          },
          {
            pid: "510600",
            label: "中江县",
            value: "510623"
          },
          {
            pid: "510600",
            label: "广汉市",
            value: "510681"
          },
          {
            pid: "510600",
            label: "什邡市",
            value: "510682"
          },
          {
            pid: "510600",
            label: "绵竹市",
            value: "510683"
          }
        ],
        pid: "510000",
        label: "德阳市",
        value: "510600"
      },
      {
        children: [
          {
            pid: "510700",
            label: "涪城区",
            value: "510703"
          },
          {
            pid: "510700",
            label: "游仙区",
            value: "510704"
          },
          {
            pid: "510700",
            label: "安州区",
            value: "510705"
          },
          {
            pid: "510700",
            label: "三台县",
            value: "510722"
          },
          {
            pid: "510700",
            label: "盐亭县",
            value: "510723"
          },
          {
            pid: "510700",
            label: "梓潼县",
            value: "510725"
          },
          {
            pid: "510700",
            label: "北川羌族自治县",
            value: "510726"
          },
          {
            pid: "510700",
            label: "平武县",
            value: "510727"
          },
          {
            pid: "510700",
            label: "江油市",
            value: "510781"
          }
        ],
        pid: "510000",
        label: "绵阳市",
        value: "510700"
      },
      {
        children: [
          {
            pid: "510800",
            label: "利州区",
            value: "510802"
          },
          {
            pid: "510800",
            label: "昭化区",
            value: "510811"
          },
          {
            pid: "510800",
            label: "朝天区",
            value: "510812"
          },
          {
            pid: "510800",
            label: "旺苍县",
            value: "510821"
          },
          {
            pid: "510800",
            label: "青川县",
            value: "510822"
          },
          {
            pid: "510800",
            label: "剑阁县",
            value: "510823"
          },
          {
            pid: "510800",
            label: "苍溪县",
            value: "510824"
          }
        ],
        pid: "510000",
        label: "广元市",
        value: "510800"
      },
      {
        children: [
          {
            pid: "510900",
            label: "船山区",
            value: "510903"
          },
          {
            pid: "510900",
            label: "安居区",
            value: "510904"
          },
          {
            pid: "510900",
            label: "蓬溪县",
            value: "510921"
          },
          {
            pid: "510900",
            label: "射洪县",
            value: "510922"
          },
          {
            pid: "510900",
            label: "大英县",
            value: "510923"
          }
        ],
        pid: "510000",
        label: "遂宁市",
        value: "510900"
      },
      {
        children: [
          {
            pid: "511000",
            label: "市中区",
            value: "511002"
          },
          {
            pid: "511000",
            label: "东兴区",
            value: "511011"
          },
          {
            pid: "511000",
            label: "威远县",
            value: "511024"
          },
          {
            pid: "511000",
            label: "资中县",
            value: "511025"
          },
          {
            pid: "511000",
            label: "隆昌市",
            value: "511083"
          }
        ],
        pid: "510000",
        label: "内江市",
        value: "511000"
      },
      {
        children: [
          {
            pid: "511100",
            label: "市中区",
            value: "511102"
          },
          {
            pid: "511100",
            label: "沙湾区",
            value: "511111"
          },
          {
            pid: "511100",
            label: "五通桥区",
            value: "511112"
          },
          {
            pid: "511100",
            label: "金口河区",
            value: "511113"
          },
          {
            pid: "511100",
            label: "犍为县",
            value: "511123"
          },
          {
            pid: "511100",
            label: "井研县",
            value: "511124"
          },
          {
            pid: "511100",
            label: "夹江县",
            value: "511126"
          },
          {
            pid: "511100",
            label: "沐川县",
            value: "511129"
          },
          {
            pid: "511100",
            label: "峨边彝族自治县",
            value: "511132"
          },
          {
            pid: "511100",
            label: "马边彝族自治县",
            value: "511133"
          },
          {
            pid: "511100",
            label: "峨眉山市",
            value: "511181"
          }
        ],
        pid: "510000",
        label: "乐山市",
        value: "511100"
      },
      {
        children: [
          {
            pid: "511300",
            label: "顺庆区",
            value: "511302"
          },
          {
            pid: "511300",
            label: "高坪区",
            value: "511303"
          },
          {
            pid: "511300",
            label: "嘉陵区",
            value: "511304"
          },
          {
            pid: "511300",
            label: "南部县",
            value: "511321"
          },
          {
            pid: "511300",
            label: "营山县",
            value: "511322"
          },
          {
            pid: "511300",
            label: "蓬安县",
            value: "511323"
          },
          {
            pid: "511300",
            label: "仪陇县",
            value: "511324"
          },
          {
            pid: "511300",
            label: "西充县",
            value: "511325"
          },
          {
            pid: "511300",
            label: "阆中市",
            value: "511381"
          }
        ],
        pid: "510000",
        label: "南充市",
        value: "511300"
      },
      {
        children: [
          {
            pid: "511400",
            label: "东坡区",
            value: "511402"
          },
          {
            pid: "511400",
            label: "彭山区",
            value: "511403"
          },
          {
            pid: "511400",
            label: "仁寿县",
            value: "511421"
          },
          {
            pid: "511400",
            label: "洪雅县",
            value: "511423"
          },
          {
            pid: "511400",
            label: "丹棱县",
            value: "511424"
          },
          {
            pid: "511400",
            label: "青神县",
            value: "511425"
          }
        ],
        pid: "510000",
        label: "眉山市",
        value: "511400"
      },
      {
        children: [
          {
            pid: "511500",
            label: "翠屏区",
            value: "511502"
          },
          {
            pid: "511500",
            label: "南溪区",
            value: "511503"
          },
          {
            pid: "511500",
            label: "叙州区",
            value: "511504"
          },
          {
            pid: "511500",
            label: "江安县",
            value: "511523"
          },
          {
            pid: "511500",
            label: "长宁县",
            value: "511524"
          },
          {
            pid: "511500",
            label: "高县",
            value: "511525"
          },
          {
            pid: "511500",
            label: "珙县",
            value: "511526"
          },
          {
            pid: "511500",
            label: "筠连县",
            value: "511527"
          },
          {
            pid: "511500",
            label: "兴文县",
            value: "511528"
          },
          {
            pid: "511500",
            label: "屏山县",
            value: "511529"
          }
        ],
        pid: "510000",
        label: "宜宾市",
        value: "511500"
      },
      {
        children: [
          {
            pid: "511600",
            label: "广安区",
            value: "511602"
          },
          {
            pid: "511600",
            label: "前锋区",
            value: "511603"
          },
          {
            pid: "511600",
            label: "岳池县",
            value: "511621"
          },
          {
            pid: "511600",
            label: "武胜县",
            value: "511622"
          },
          {
            pid: "511600",
            label: "邻水县",
            value: "511623"
          },
          {
            pid: "511600",
            label: "华蓥市",
            value: "511681"
          }
        ],
        pid: "510000",
        label: "广安市",
        value: "511600"
      },
      {
        children: [
          {
            pid: "511700",
            label: "通川区",
            value: "511702"
          },
          {
            pid: "511700",
            label: "达川区",
            value: "511703"
          },
          {
            pid: "511700",
            label: "宣汉县",
            value: "511722"
          },
          {
            pid: "511700",
            label: "开江县",
            value: "511723"
          },
          {
            pid: "511700",
            label: "大竹县",
            value: "511724"
          },
          {
            pid: "511700",
            label: "渠县",
            value: "511725"
          },
          {
            pid: "511700",
            label: "万源市",
            value: "511781"
          }
        ],
        pid: "510000",
        label: "达州市",
        value: "511700"
      },
      {
        children: [
          {
            pid: "511800",
            label: "雨城区",
            value: "511802"
          },
          {
            pid: "511800",
            label: "名山区",
            value: "511803"
          },
          {
            pid: "511800",
            label: "荥经县",
            value: "511822"
          },
          {
            pid: "511800",
            label: "汉源县",
            value: "511823"
          },
          {
            pid: "511800",
            label: "石棉县",
            value: "511824"
          },
          {
            pid: "511800",
            label: "天全县",
            value: "511825"
          },
          {
            pid: "511800",
            label: "芦山县",
            value: "511826"
          },
          {
            pid: "511800",
            label: "宝兴县",
            value: "511827"
          }
        ],
        pid: "510000",
        label: "雅安市",
        value: "511800"
      },
      {
        children: [
          {
            pid: "511900",
            label: "巴州区",
            value: "511902"
          },
          {
            pid: "511900",
            label: "恩阳区",
            value: "511903"
          },
          {
            pid: "511900",
            label: "通江县",
            value: "511921"
          },
          {
            pid: "511900",
            label: "南江县",
            value: "511922"
          },
          {
            pid: "511900",
            label: "平昌县",
            value: "511923"
          }
        ],
        pid: "510000",
        label: "巴中市",
        value: "511900"
      },
      {
        children: [
          {
            pid: "512000",
            label: "雁江区",
            value: "512002"
          },
          {
            pid: "512000",
            label: "安岳县",
            value: "512021"
          },
          {
            pid: "512000",
            label: "乐至县",
            value: "512022"
          }
        ],
        pid: "510000",
        label: "资阳市",
        value: "512000"
      },
      {
        children: [
          {
            pid: "513200",
            label: "马尔康市",
            value: "513201"
          },
          {
            pid: "513200",
            label: "汶川县",
            value: "513221"
          },
          {
            pid: "513200",
            label: "理县",
            value: "513222"
          },
          {
            pid: "513200",
            label: "茂县",
            value: "513223"
          },
          {
            pid: "513200",
            label: "松潘县",
            value: "513224"
          },
          {
            pid: "513200",
            label: "九寨沟县",
            value: "513225"
          },
          {
            pid: "513200",
            label: "金川县",
            value: "513226"
          },
          {
            pid: "513200",
            label: "小金县",
            value: "513227"
          },
          {
            pid: "513200",
            label: "黑水县",
            value: "513228"
          },
          {
            pid: "513200",
            label: "壤塘县",
            value: "513230"
          },
          {
            pid: "513200",
            label: "阿坝县",
            value: "513231"
          },
          {
            pid: "513200",
            label: "若尔盖县",
            value: "513232"
          },
          {
            pid: "513200",
            label: "红原县",
            value: "513233"
          }
        ],
        pid: "510000",
        label: "阿坝藏族羌族自治州",
        value: "513200"
      },
      {
        children: [
          {
            pid: "513300",
            label: "康定市",
            value: "513301"
          },
          {
            pid: "513300",
            label: "泸定县",
            value: "513322"
          },
          {
            pid: "513300",
            label: "丹巴县",
            value: "513323"
          },
          {
            pid: "513300",
            label: "九龙县",
            value: "513324"
          },
          {
            pid: "513300",
            label: "雅江县",
            value: "513325"
          },
          {
            pid: "513300",
            label: "道孚县",
            value: "513326"
          },
          {
            pid: "513300",
            label: "炉霍县",
            value: "513327"
          },
          {
            pid: "513300",
            label: "甘孜县",
            value: "513328"
          },
          {
            pid: "513300",
            label: "新龙县",
            value: "513329"
          },
          {
            pid: "513300",
            label: "德格县",
            value: "513330"
          },
          {
            pid: "513300",
            label: "白玉县",
            value: "513331"
          },
          {
            pid: "513300",
            label: "石渠县",
            value: "513332"
          },
          {
            pid: "513300",
            label: "色达县",
            value: "513333"
          },
          {
            pid: "513300",
            label: "理塘县",
            value: "513334"
          },
          {
            pid: "513300",
            label: "巴塘县",
            value: "513335"
          },
          {
            pid: "513300",
            label: "乡城县",
            value: "513336"
          },
          {
            pid: "513300",
            label: "稻城县",
            value: "513337"
          },
          {
            pid: "513300",
            label: "得荣县",
            value: "513338"
          }
        ],
        pid: "510000",
        label: "甘孜藏族自治州",
        value: "513300"
      },
      {
        children: [
          {
            pid: "513400",
            label: "西昌市",
            value: "513401"
          },
          {
            pid: "513400",
            label: "木里藏族自治县",
            value: "513422"
          },
          {
            pid: "513400",
            label: "盐源县",
            value: "513423"
          },
          {
            pid: "513400",
            label: "德昌县",
            value: "513424"
          },
          {
            pid: "513400",
            label: "会理县",
            value: "513425"
          },
          {
            pid: "513400",
            label: "会东县",
            value: "513426"
          },
          {
            pid: "513400",
            label: "宁南县",
            value: "513427"
          },
          {
            pid: "513400",
            label: "普格县",
            value: "513428"
          },
          {
            pid: "513400",
            label: "布拖县",
            value: "513429"
          },
          {
            pid: "513400",
            label: "金阳县",
            value: "513430"
          },
          {
            pid: "513400",
            label: "昭觉县",
            value: "513431"
          },
          {
            pid: "513400",
            label: "喜德县",
            value: "513432"
          },
          {
            pid: "513400",
            label: "冕宁县",
            value: "513433"
          },
          {
            pid: "513400",
            label: "越西县",
            value: "513434"
          },
          {
            pid: "513400",
            label: "甘洛县",
            value: "513435"
          },
          {
            pid: "513400",
            label: "美姑县",
            value: "513436"
          },
          {
            pid: "513400",
            label: "雷波县",
            value: "513437"
          }
        ],
        pid: "510000",
        label: "凉山彝族自治州",
        value: "513400"
      }
    ],
    pid: "-1",
    label: "四川省",
    value: "510000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "520100",
            label: "南明区",
            value: "520102"
          },
          {
            pid: "520100",
            label: "云岩区",
            value: "520103"
          },
          {
            pid: "520100",
            label: "花溪区",
            value: "520111"
          },
          {
            pid: "520100",
            label: "乌当区",
            value: "520112"
          },
          {
            pid: "520100",
            label: "白云区",
            value: "520113"
          },
          {
            pid: "520100",
            label: "观山湖区",
            value: "520115"
          },
          {
            pid: "520100",
            label: "开阳县",
            value: "520121"
          },
          {
            pid: "520100",
            label: "息烽县",
            value: "520122"
          },
          {
            pid: "520100",
            label: "修文县",
            value: "520123"
          },
          {
            pid: "520100",
            label: "清镇市",
            value: "520181"
          }
        ],
        pid: "520000",
        label: "贵阳市",
        value: "520100"
      },
      {
        children: [
          {
            pid: "520200",
            label: "钟山区",
            value: "520201"
          },
          {
            pid: "520200",
            label: "六枝特区",
            value: "520203"
          },
          {
            pid: "520200",
            label: "水城县",
            value: "520221"
          },
          {
            pid: "520200",
            label: "盘州市",
            value: "520281"
          }
        ],
        pid: "520000",
        label: "六盘水市",
        value: "520200"
      },
      {
        children: [
          {
            pid: "520300",
            label: "红花岗区",
            value: "520302"
          },
          {
            pid: "520300",
            label: "汇川区",
            value: "520303"
          },
          {
            pid: "520300",
            label: "播州区",
            value: "520304"
          },
          {
            pid: "520300",
            label: "桐梓县",
            value: "520322"
          },
          {
            pid: "520300",
            label: "绥阳县",
            value: "520323"
          },
          {
            pid: "520300",
            label: "正安县",
            value: "520324"
          },
          {
            pid: "520300",
            label: "道真仡佬族苗族自治县",
            value: "520325"
          },
          {
            pid: "520300",
            label: "务川仡佬族苗族自治县",
            value: "520326"
          },
          {
            pid: "520300",
            label: "凤冈县",
            value: "520327"
          },
          {
            pid: "520300",
            label: "湄潭县",
            value: "520328"
          },
          {
            pid: "520300",
            label: "余庆县",
            value: "520329"
          },
          {
            pid: "520300",
            label: "习水县",
            value: "520330"
          },
          {
            pid: "520300",
            label: "赤水市",
            value: "520381"
          },
          {
            pid: "520300",
            label: "仁怀市",
            value: "520382"
          }
        ],
        pid: "520000",
        label: "遵义市",
        value: "520300"
      },
      {
        children: [
          {
            pid: "520400",
            label: "西秀区",
            value: "520402"
          },
          {
            pid: "520400",
            label: "平坝区",
            value: "520403"
          },
          {
            pid: "520400",
            label: "普定县",
            value: "520422"
          },
          {
            pid: "520400",
            label: "镇宁布依族苗族自治县",
            value: "520423"
          },
          {
            pid: "520400",
            label: "关岭布依族苗族自治县",
            value: "520424"
          },
          {
            pid: "520400",
            label: "紫云苗族布依族自治县",
            value: "520425"
          }
        ],
        pid: "520000",
        label: "安顺市",
        value: "520400"
      },
      {
        children: [
          {
            pid: "520500",
            label: "七星关区",
            value: "520502"
          },
          {
            pid: "520500",
            label: "大方县",
            value: "520521"
          },
          {
            pid: "520500",
            label: "黔西县",
            value: "520522"
          },
          {
            pid: "520500",
            label: "金沙县",
            value: "520523"
          },
          {
            pid: "520500",
            label: "织金县",
            value: "520524"
          },
          {
            pid: "520500",
            label: "纳雍县",
            value: "520525"
          },
          {
            pid: "520500",
            label: "威宁彝族回族苗族自治县",
            value: "520526"
          },
          {
            pid: "520500",
            label: "赫章县",
            value: "520527"
          }
        ],
        pid: "520000",
        label: "毕节市",
        value: "520500"
      },
      {
        children: [
          {
            pid: "520600",
            label: "碧江区",
            value: "520602"
          },
          {
            pid: "520600",
            label: "万山区",
            value: "520603"
          },
          {
            pid: "520600",
            label: "江口县",
            value: "520621"
          },
          {
            pid: "520600",
            label: "玉屏侗族自治县",
            value: "520622"
          },
          {
            pid: "520600",
            label: "石阡县",
            value: "520623"
          },
          {
            pid: "520600",
            label: "思南县",
            value: "520624"
          },
          {
            pid: "520600",
            label: "印江土家族苗族自治县",
            value: "520625"
          },
          {
            pid: "520600",
            label: "德江县",
            value: "520626"
          },
          {
            pid: "520600",
            label: "沿河土家族自治县",
            value: "520627"
          },
          {
            pid: "520600",
            label: "松桃苗族自治县",
            value: "520628"
          }
        ],
        pid: "520000",
        label: "铜仁市",
        value: "520600"
      },
      {
        children: [
          {
            pid: "522300",
            label: "兴义市",
            value: "522301"
          },
          {
            pid: "522300",
            label: "兴仁市",
            value: "522302"
          },
          {
            pid: "522300",
            label: "普安县",
            value: "522323"
          },
          {
            pid: "522300",
            label: "晴隆县",
            value: "522324"
          },
          {
            pid: "522300",
            label: "贞丰县",
            value: "522325"
          },
          {
            pid: "522300",
            label: "望谟县",
            value: "522326"
          },
          {
            pid: "522300",
            label: "册亨县",
            value: "522327"
          },
          {
            pid: "522300",
            label: "安龙县",
            value: "522328"
          }
        ],
        pid: "520000",
        label: "黔西南布依族苗族自治州",
        value: "522300"
      },
      {
        children: [
          {
            pid: "522600",
            label: "凯里市",
            value: "522601"
          },
          {
            pid: "522600",
            label: "黄平县",
            value: "522622"
          },
          {
            pid: "522600",
            label: "施秉县",
            value: "522623"
          },
          {
            pid: "522600",
            label: "三穗县",
            value: "522624"
          },
          {
            pid: "522600",
            label: "镇远县",
            value: "522625"
          },
          {
            pid: "522600",
            label: "岑巩县",
            value: "522626"
          },
          {
            pid: "522600",
            label: "天柱县",
            value: "522627"
          },
          {
            pid: "522600",
            label: "锦屏县",
            value: "522628"
          },
          {
            pid: "522600",
            label: "剑河县",
            value: "522629"
          },
          {
            pid: "522600",
            label: "台江县",
            value: "522630"
          },
          {
            pid: "522600",
            label: "黎平县",
            value: "522631"
          },
          {
            pid: "522600",
            label: "榕江县",
            value: "522632"
          },
          {
            pid: "522600",
            label: "从江县",
            value: "522633"
          },
          {
            pid: "522600",
            label: "雷山县",
            value: "522634"
          },
          {
            pid: "522600",
            label: "麻江县",
            value: "522635"
          },
          {
            pid: "522600",
            label: "丹寨县",
            value: "522636"
          }
        ],
        pid: "520000",
        label: "黔东南苗族侗族自治州",
        value: "522600"
      },
      {
        children: [
          {
            pid: "522700",
            label: "都匀市",
            value: "522701"
          },
          {
            pid: "522700",
            label: "福泉市",
            value: "522702"
          },
          {
            pid: "522700",
            label: "荔波县",
            value: "522722"
          },
          {
            pid: "522700",
            label: "贵定县",
            value: "522723"
          },
          {
            pid: "522700",
            label: "瓮安县",
            value: "522725"
          },
          {
            pid: "522700",
            label: "独山县",
            value: "522726"
          },
          {
            pid: "522700",
            label: "平塘县",
            value: "522727"
          },
          {
            pid: "522700",
            label: "罗甸县",
            value: "522728"
          },
          {
            pid: "522700",
            label: "长顺县",
            value: "522729"
          },
          {
            pid: "522700",
            label: "龙里县",
            value: "522730"
          },
          {
            pid: "522700",
            label: "惠水县",
            value: "522731"
          },
          {
            pid: "522700",
            label: "三都水族自治县",
            value: "522732"
          }
        ],
        pid: "520000",
        label: "黔南布依族苗族自治州",
        value: "522700"
      }
    ],
    pid: "-1",
    label: "贵州省",
    value: "520000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "530100",
            label: "五华区",
            value: "530102"
          },
          {
            pid: "530100",
            label: "盘龙区",
            value: "530103"
          },
          {
            pid: "530100",
            label: "官渡区",
            value: "530111"
          },
          {
            pid: "530100",
            label: "西山区",
            value: "530112"
          },
          {
            pid: "530100",
            label: "东川区",
            value: "530113"
          },
          {
            pid: "530100",
            label: "呈贡区",
            value: "530114"
          },
          {
            pid: "530100",
            label: "晋宁区",
            value: "530115"
          },
          {
            pid: "530100",
            label: "富民县",
            value: "530124"
          },
          {
            pid: "530100",
            label: "宜良县",
            value: "530125"
          },
          {
            pid: "530100",
            label: "石林彝族自治县",
            value: "530126"
          },
          {
            pid: "530100",
            label: "嵩明县",
            value: "530127"
          },
          {
            pid: "530100",
            label: "禄劝彝族苗族自治县",
            value: "530128"
          },
          {
            pid: "530100",
            label: "寻甸回族彝族自治县",
            value: "530129"
          },
          {
            pid: "530100",
            label: "安宁市",
            value: "530181"
          }
        ],
        pid: "530000",
        label: "昆明市",
        value: "530100"
      },
      {
        children: [
          {
            pid: "530300",
            label: "麒麟区",
            value: "530302"
          },
          {
            pid: "530300",
            label: "沾益区",
            value: "530303"
          },
          {
            pid: "530300",
            label: "马龙区",
            value: "530304"
          },
          {
            pid: "530300",
            label: "陆良县",
            value: "530322"
          },
          {
            pid: "530300",
            label: "师宗县",
            value: "530323"
          },
          {
            pid: "530300",
            label: "罗平县",
            value: "530324"
          },
          {
            pid: "530300",
            label: "富源县",
            value: "530325"
          },
          {
            pid: "530300",
            label: "会泽县",
            value: "530326"
          },
          {
            pid: "530300",
            label: "宣威市",
            value: "530381"
          }
        ],
        pid: "530000",
        label: "曲靖市",
        value: "530300"
      },
      {
        children: [
          {
            pid: "530400",
            label: "红塔区",
            value: "530402"
          },
          {
            pid: "530400",
            label: "江川区",
            value: "530403"
          },
          {
            pid: "530400",
            label: "澄江县",
            value: "530422"
          },
          {
            pid: "530400",
            label: "通海县",
            value: "530423"
          },
          {
            pid: "530400",
            label: "华宁县",
            value: "530424"
          },
          {
            pid: "530400",
            label: "易门县",
            value: "530425"
          },
          {
            pid: "530400",
            label: "峨山彝族自治县",
            value: "530426"
          },
          {
            pid: "530400",
            label: "新平彝族傣族自治县",
            value: "530427"
          },
          {
            pid: "530400",
            label: "元江哈尼族彝族傣族自治县",
            value: "530428"
          }
        ],
        pid: "530000",
        label: "玉溪市",
        value: "530400"
      },
      {
        children: [
          {
            pid: "530500",
            label: "隆阳区",
            value: "530502"
          },
          {
            pid: "530500",
            label: "施甸县",
            value: "530521"
          },
          {
            pid: "530500",
            label: "龙陵县",
            value: "530523"
          },
          {
            pid: "530500",
            label: "昌宁县",
            value: "530524"
          },
          {
            pid: "530500",
            label: "腾冲市",
            value: "530581"
          }
        ],
        pid: "530000",
        label: "保山市",
        value: "530500"
      },
      {
        children: [
          {
            pid: "530600",
            label: "昭阳区",
            value: "530602"
          },
          {
            pid: "530600",
            label: "鲁甸县",
            value: "530621"
          },
          {
            pid: "530600",
            label: "巧家县",
            value: "530622"
          },
          {
            pid: "530600",
            label: "盐津县",
            value: "530623"
          },
          {
            pid: "530600",
            label: "大关县",
            value: "530624"
          },
          {
            pid: "530600",
            label: "永善县",
            value: "530625"
          },
          {
            pid: "530600",
            label: "绥江县",
            value: "530626"
          },
          {
            pid: "530600",
            label: "镇雄县",
            value: "530627"
          },
          {
            pid: "530600",
            label: "彝良县",
            value: "530628"
          },
          {
            pid: "530600",
            label: "威信县",
            value: "530629"
          },
          {
            pid: "530600",
            label: "水富市",
            value: "530681"
          }
        ],
        pid: "530000",
        label: "昭通市",
        value: "530600"
      },
      {
        children: [
          {
            pid: "530700",
            label: "古城区",
            value: "530702"
          },
          {
            pid: "530700",
            label: "玉龙纳西族自治县",
            value: "530721"
          },
          {
            pid: "530700",
            label: "永胜县",
            value: "530722"
          },
          {
            pid: "530700",
            label: "华坪县",
            value: "530723"
          },
          {
            pid: "530700",
            label: "宁蒗彝族自治县",
            value: "530724"
          }
        ],
        pid: "530000",
        label: "丽江市",
        value: "530700"
      },
      {
        children: [
          {
            pid: "530800",
            label: "思茅区",
            value: "530802"
          },
          {
            pid: "530800",
            label: "宁洱哈尼族彝族自治县",
            value: "530821"
          },
          {
            pid: "530800",
            label: "墨江哈尼族自治县",
            value: "530822"
          },
          {
            pid: "530800",
            label: "景东彝族自治县",
            value: "530823"
          },
          {
            pid: "530800",
            label: "景谷傣族彝族自治县",
            value: "530824"
          },
          {
            pid: "530800",
            label: "镇沅彝族哈尼族拉祜族自治县",
            value: "530825"
          },
          {
            pid: "530800",
            label: "江城哈尼族彝族自治县",
            value: "530826"
          },
          {
            pid: "530800",
            label: "孟连傣族拉祜族佤族自治县",
            value: "530827"
          },
          {
            pid: "530800",
            label: "澜沧拉祜族自治县",
            value: "530828"
          },
          {
            pid: "530800",
            label: "西盟佤族自治县",
            value: "530829"
          }
        ],
        pid: "530000",
        label: "普洱市",
        value: "530800"
      },
      {
        children: [
          {
            pid: "530900",
            label: "临翔区",
            value: "530902"
          },
          {
            pid: "530900",
            label: "凤庆县",
            value: "530921"
          },
          {
            pid: "530900",
            label: "云县",
            value: "530922"
          },
          {
            pid: "530900",
            label: "永德县",
            value: "530923"
          },
          {
            pid: "530900",
            label: "镇康县",
            value: "530924"
          },
          {
            pid: "530900",
            label: "双江拉祜族佤族布朗族傣族自治县",
            value: "530925"
          },
          {
            pid: "530900",
            label: "耿马傣族佤族自治县",
            value: "530926"
          },
          {
            pid: "530900",
            label: "沧源佤族自治县",
            value: "530927"
          }
        ],
        pid: "530000",
        label: "临沧市",
        value: "530900"
      },
      {
        children: [
          {
            pid: "532300",
            label: "楚雄市",
            value: "532301"
          },
          {
            pid: "532300",
            label: "双柏县",
            value: "532322"
          },
          {
            pid: "532300",
            label: "牟定县",
            value: "532323"
          },
          {
            pid: "532300",
            label: "南华县",
            value: "532324"
          },
          {
            pid: "532300",
            label: "姚安县",
            value: "532325"
          },
          {
            pid: "532300",
            label: "大姚县",
            value: "532326"
          },
          {
            pid: "532300",
            label: "永仁县",
            value: "532327"
          },
          {
            pid: "532300",
            label: "元谋县",
            value: "532328"
          },
          {
            pid: "532300",
            label: "武定县",
            value: "532329"
          },
          {
            pid: "532300",
            label: "禄丰县",
            value: "532331"
          }
        ],
        pid: "530000",
        label: "楚雄彝族自治州",
        value: "532300"
      },
      {
        children: [
          {
            pid: "532500",
            label: "个旧市",
            value: "532501"
          },
          {
            pid: "532500",
            label: "开远市",
            value: "532502"
          },
          {
            pid: "532500",
            label: "蒙自市",
            value: "532503"
          },
          {
            pid: "532500",
            label: "弥勒市",
            value: "532504"
          },
          {
            pid: "532500",
            label: "屏边苗族自治县",
            value: "532523"
          },
          {
            pid: "532500",
            label: "建水县",
            value: "532524"
          },
          {
            pid: "532500",
            label: "石屏县",
            value: "532525"
          },
          {
            pid: "532500",
            label: "泸西县",
            value: "532527"
          },
          {
            pid: "532500",
            label: "元阳县",
            value: "532528"
          },
          {
            pid: "532500",
            label: "红河县",
            value: "532529"
          },
          {
            pid: "532500",
            label: "金平苗族瑶族傣族自治县",
            value: "532530"
          },
          {
            pid: "532500",
            label: "绿春县",
            value: "532531"
          },
          {
            pid: "532500",
            label: "河口瑶族自治县",
            value: "532532"
          }
        ],
        pid: "530000",
        label: "红河哈尼族彝族自治州",
        value: "532500"
      },
      {
        children: [
          {
            pid: "532600",
            label: "文山市",
            value: "532601"
          },
          {
            pid: "532600",
            label: "砚山县",
            value: "532622"
          },
          {
            pid: "532600",
            label: "西畴县",
            value: "532623"
          },
          {
            pid: "532600",
            label: "麻栗坡县",
            value: "532624"
          },
          {
            pid: "532600",
            label: "马关县",
            value: "532625"
          },
          {
            pid: "532600",
            label: "丘北县",
            value: "532626"
          },
          {
            pid: "532600",
            label: "广南县",
            value: "532627"
          },
          {
            pid: "532600",
            label: "富宁县",
            value: "532628"
          }
        ],
        pid: "530000",
        label: "文山壮族苗族自治州",
        value: "532600"
      },
      {
        children: [
          {
            pid: "532800",
            label: "景洪市",
            value: "532801"
          },
          {
            pid: "532800",
            label: "勐海县",
            value: "532822"
          },
          {
            pid: "532800",
            label: "勐腊县",
            value: "532823"
          }
        ],
        pid: "530000",
        label: "西双版纳傣族自治州",
        value: "532800"
      },
      {
        children: [
          {
            pid: "532900",
            label: "大理市",
            value: "532901"
          },
          {
            pid: "532900",
            label: "漾濞彝族自治县",
            value: "532922"
          },
          {
            pid: "532900",
            label: "祥云县",
            value: "532923"
          },
          {
            pid: "532900",
            label: "宾川县",
            value: "532924"
          },
          {
            pid: "532900",
            label: "弥渡县",
            value: "532925"
          },
          {
            pid: "532900",
            label: "南涧彝族自治县",
            value: "532926"
          },
          {
            pid: "532900",
            label: "巍山彝族回族自治县",
            value: "532927"
          },
          {
            pid: "532900",
            label: "永平县",
            value: "532928"
          },
          {
            pid: "532900",
            label: "云龙县",
            value: "532929"
          },
          {
            pid: "532900",
            label: "洱源县",
            value: "532930"
          },
          {
            pid: "532900",
            label: "剑川县",
            value: "532931"
          },
          {
            pid: "532900",
            label: "鹤庆县",
            value: "532932"
          }
        ],
        pid: "530000",
        label: "大理白族自治州",
        value: "532900"
      },
      {
        children: [
          {
            pid: "533100",
            label: "瑞丽市",
            value: "533102"
          },
          {
            pid: "533100",
            label: "芒市",
            value: "533103"
          },
          {
            pid: "533100",
            label: "梁河县",
            value: "533122"
          },
          {
            pid: "533100",
            label: "盈江县",
            value: "533123"
          },
          {
            pid: "533100",
            label: "陇川县",
            value: "533124"
          }
        ],
        pid: "530000",
        label: "德宏傣族景颇族自治州",
        value: "533100"
      },
      {
        children: [
          {
            pid: "533300",
            label: "泸水市",
            value: "533301"
          },
          {
            pid: "533300",
            label: "福贡县",
            value: "533323"
          },
          {
            pid: "533300",
            label: "贡山独龙族怒族自治县",
            value: "533324"
          },
          {
            pid: "533300",
            label: "兰坪白族普米族自治县",
            value: "533325"
          }
        ],
        pid: "530000",
        label: "怒江傈僳族自治州",
        value: "533300"
      },
      {
        children: [
          {
            pid: "533400",
            label: "香格里拉市",
            value: "533401"
          },
          {
            pid: "533400",
            label: "德钦县",
            value: "533422"
          },
          {
            pid: "533400",
            label: "维西傈僳族自治县",
            value: "533423"
          }
        ],
        pid: "530000",
        label: "迪庆藏族自治州",
        value: "533400"
      }
    ],
    pid: "-1",
    label: "云南省",
    value: "530000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "540100",
            label: "城关区",
            value: "540102"
          },
          {
            pid: "540100",
            label: "堆龙德庆区",
            value: "540103"
          },
          {
            pid: "540100",
            label: "达孜区",
            value: "540104"
          },
          {
            pid: "540100",
            label: "林周县",
            value: "540121"
          },
          {
            pid: "540100",
            label: "当雄县",
            value: "540122"
          },
          {
            pid: "540100",
            label: "尼木县",
            value: "540123"
          },
          {
            pid: "540100",
            label: "曲水县",
            value: "540124"
          },
          {
            pid: "540100",
            label: "墨竹工卡县",
            value: "540127"
          }
        ],
        pid: "540000",
        label: "拉萨市",
        value: "540100"
      },
      {
        children: [
          {
            pid: "540200",
            label: "桑珠孜区",
            value: "540202"
          },
          {
            pid: "540200",
            label: "南木林县",
            value: "540221"
          },
          {
            pid: "540200",
            label: "江孜县",
            value: "540222"
          },
          {
            pid: "540200",
            label: "定日县",
            value: "540223"
          },
          {
            pid: "540200",
            label: "萨迦县",
            value: "540224"
          },
          {
            pid: "540200",
            label: "拉孜县",
            value: "540225"
          },
          {
            pid: "540200",
            label: "昂仁县",
            value: "540226"
          },
          {
            pid: "540200",
            label: "谢通门县",
            value: "540227"
          },
          {
            pid: "540200",
            label: "白朗县",
            value: "540228"
          },
          {
            pid: "540200",
            label: "仁布县",
            value: "540229"
          },
          {
            pid: "540200",
            label: "康马县",
            value: "540230"
          },
          {
            pid: "540200",
            label: "定结县",
            value: "540231"
          },
          {
            pid: "540200",
            label: "仲巴县",
            value: "540232"
          },
          {
            pid: "540200",
            label: "亚东县",
            value: "540233"
          },
          {
            pid: "540200",
            label: "吉隆县",
            value: "540234"
          },
          {
            pid: "540200",
            label: "聂拉木县",
            value: "540235"
          },
          {
            pid: "540200",
            label: "萨嘎县",
            value: "540236"
          },
          {
            pid: "540200",
            label: "岗巴县",
            value: "540237"
          }
        ],
        pid: "540000",
        label: "日喀则市",
        value: "540200"
      },
      {
        children: [
          {
            pid: "540300",
            label: "卡若区",
            value: "540302"
          },
          {
            pid: "540300",
            label: "江达县",
            value: "540321"
          },
          {
            pid: "540300",
            label: "贡觉县",
            value: "540322"
          },
          {
            pid: "540300",
            label: "类乌齐县",
            value: "540323"
          },
          {
            pid: "540300",
            label: "丁青县",
            value: "540324"
          },
          {
            pid: "540300",
            label: "察雅县",
            value: "540325"
          },
          {
            pid: "540300",
            label: "八宿县",
            value: "540326"
          },
          {
            pid: "540300",
            label: "左贡县",
            value: "540327"
          },
          {
            pid: "540300",
            label: "芒康县",
            value: "540328"
          },
          {
            pid: "540300",
            label: "洛隆县",
            value: "540329"
          },
          {
            pid: "540300",
            label: "边坝县",
            value: "540330"
          }
        ],
        pid: "540000",
        label: "昌都市",
        value: "540300"
      },
      {
        children: [
          {
            pid: "540400",
            label: "巴宜区",
            value: "540402"
          },
          {
            pid: "540400",
            label: "工布江达县",
            value: "540421"
          },
          {
            pid: "540400",
            label: "米林县",
            value: "540422"
          },
          {
            pid: "540400",
            label: "墨脱县",
            value: "540423"
          },
          {
            pid: "540400",
            label: "波密县",
            value: "540424"
          },
          {
            pid: "540400",
            label: "察隅县",
            value: "540425"
          },
          {
            pid: "540400",
            label: "朗县",
            value: "540426"
          }
        ],
        pid: "540000",
        label: "林芝市",
        value: "540400"
      },
      {
        children: [
          {
            pid: "540500",
            label: "乃东区",
            value: "540502"
          },
          {
            pid: "540500",
            label: "扎囊县",
            value: "540521"
          },
          {
            pid: "540500",
            label: "贡嘎县",
            value: "540522"
          },
          {
            pid: "540500",
            label: "桑日县",
            value: "540523"
          },
          {
            pid: "540500",
            label: "琼结县",
            value: "540524"
          },
          {
            pid: "540500",
            label: "曲松县",
            value: "540525"
          },
          {
            pid: "540500",
            label: "措美县",
            value: "540526"
          },
          {
            pid: "540500",
            label: "洛扎县",
            value: "540527"
          },
          {
            pid: "540500",
            label: "加查县",
            value: "540528"
          },
          {
            pid: "540500",
            label: "隆子县",
            value: "540529"
          },
          {
            pid: "540500",
            label: "错那县",
            value: "540530"
          },
          {
            pid: "540500",
            label: "浪卡子县",
            value: "540531"
          }
        ],
        pid: "540000",
        label: "山南市",
        value: "540500"
      },
      {
        children: [
          {
            pid: "540600",
            label: "色尼区",
            value: "540602"
          },
          {
            pid: "540600",
            label: "嘉黎县 ",
            value: "540621"
          },
          {
            pid: "540600",
            label: "比如县 ",
            value: "540622"
          },
          {
            pid: "540600",
            label: "聂荣县 ",
            value: "540623"
          },
          {
            pid: "540600",
            label: "安多县 ",
            value: "540624"
          },
          {
            pid: "540600",
            label: "申扎县 ",
            value: "540625"
          },
          {
            pid: "540600",
            label: "索县   ",
            value: "540626"
          },
          {
            pid: "540600",
            label: "班戈县 ",
            value: "540627"
          },
          {
            pid: "540600",
            label: "巴青县 ",
            value: "540628"
          },
          {
            pid: "540600",
            label: "尼玛县 ",
            value: "540629"
          },
          {
            pid: "540600",
            label: "双湖县 ",
            value: "540630"
          }
        ],
        pid: "540000",
        label: "那曲市",
        value: "540600"
      },
      {
        children: [
          {
            pid: "542500",
            label: "普兰县",
            value: "542521"
          },
          {
            pid: "542500",
            label: "札达县",
            value: "542522"
          },
          {
            pid: "542500",
            label: "噶尔县",
            value: "542523"
          },
          {
            pid: "542500",
            label: "日土县",
            value: "542524"
          },
          {
            pid: "542500",
            label: "革吉县",
            value: "542525"
          },
          {
            pid: "542500",
            label: "改则县",
            value: "542526"
          },
          {
            pid: "542500",
            label: "措勤县",
            value: "542527"
          }
        ],
        pid: "540000",
        label: "阿里地区",
        value: "542500"
      }
    ],
    pid: "-1",
    label: "西藏自治区",
    value: "540000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "610100",
            label: "新城区",
            value: "610102"
          },
          {
            pid: "610100",
            label: "碑林区",
            value: "610103"
          },
          {
            pid: "610100",
            label: "莲湖区",
            value: "610104"
          },
          {
            pid: "610100",
            label: "灞桥区",
            value: "610111"
          },
          {
            pid: "610100",
            label: "未央区",
            value: "610112"
          },
          {
            pid: "610100",
            label: "雁塔区",
            value: "610113"
          },
          {
            pid: "610100",
            label: "阎良区",
            value: "610114"
          },
          {
            pid: "610100",
            label: "临潼区",
            value: "610115"
          },
          {
            pid: "610100",
            label: "长安区",
            value: "610116"
          },
          {
            pid: "610100",
            label: "高陵区",
            value: "610117"
          },
          {
            pid: "610100",
            label: "鄠邑区",
            value: "610118"
          },
          {
            pid: "610100",
            label: "蓝田县",
            value: "610122"
          },
          {
            pid: "610100",
            label: "周至县",
            value: "610124"
          }
        ],
        pid: "610000",
        label: "西安市",
        value: "610100"
      },
      {
        children: [
          {
            pid: "610200",
            label: "王益区",
            value: "610202"
          },
          {
            pid: "610200",
            label: "印台区",
            value: "610203"
          },
          {
            pid: "610200",
            label: "耀州区",
            value: "610204"
          },
          {
            pid: "610200",
            label: "宜君县",
            value: "610222"
          }
        ],
        pid: "610000",
        label: "铜川市",
        value: "610200"
      },
      {
        children: [
          {
            pid: "610300",
            label: "渭滨区",
            value: "610302"
          },
          {
            pid: "610300",
            label: "金台区",
            value: "610303"
          },
          {
            pid: "610300",
            label: "陈仓区",
            value: "610304"
          },
          {
            pid: "610300",
            label: "凤翔县",
            value: "610322"
          },
          {
            pid: "610300",
            label: "岐山县",
            value: "610323"
          },
          {
            pid: "610300",
            label: "扶风县",
            value: "610324"
          },
          {
            pid: "610300",
            label: "眉县",
            value: "610326"
          },
          {
            pid: "610300",
            label: "陇县",
            value: "610327"
          },
          {
            pid: "610300",
            label: "千阳县",
            value: "610328"
          },
          {
            pid: "610300",
            label: "麟游县",
            value: "610329"
          },
          {
            pid: "610300",
            label: "凤县",
            value: "610330"
          },
          {
            pid: "610300",
            label: "太白县",
            value: "610331"
          }
        ],
        pid: "610000",
        label: "宝鸡市",
        value: "610300"
      },
      {
        children: [
          {
            pid: "610400",
            label: "秦都区",
            value: "610402"
          },
          {
            pid: "610400",
            label: "杨陵区",
            value: "610403"
          },
          {
            pid: "610400",
            label: "渭城区",
            value: "610404"
          },
          {
            pid: "610400",
            label: "三原县",
            value: "610422"
          },
          {
            pid: "610400",
            label: "泾阳县",
            value: "610423"
          },
          {
            pid: "610400",
            label: "乾县",
            value: "610424"
          },
          {
            pid: "610400",
            label: "礼泉县",
            value: "610425"
          },
          {
            pid: "610400",
            label: "永寿县",
            value: "610426"
          },
          {
            pid: "610400",
            label: "长武县",
            value: "610428"
          },
          {
            pid: "610400",
            label: "旬邑县",
            value: "610429"
          },
          {
            pid: "610400",
            label: "淳化县",
            value: "610430"
          },
          {
            pid: "610400",
            label: "武功县",
            value: "610431"
          },
          {
            pid: "610400",
            label: "兴平市",
            value: "610481"
          },
          {
            pid: "610400",
            label: "彬州市",
            value: "610482"
          }
        ],
        pid: "610000",
        label: "咸阳市",
        value: "610400"
      },
      {
        children: [
          {
            pid: "610500",
            label: "临渭区",
            value: "610502"
          },
          {
            pid: "610500",
            label: "华州区",
            value: "610503"
          },
          {
            pid: "610500",
            label: "潼关县",
            value: "610522"
          },
          {
            pid: "610500",
            label: "大荔县",
            value: "610523"
          },
          {
            pid: "610500",
            label: "合阳县",
            value: "610524"
          },
          {
            pid: "610500",
            label: "澄城县",
            value: "610525"
          },
          {
            pid: "610500",
            label: "蒲城县",
            value: "610526"
          },
          {
            pid: "610500",
            label: "白水县",
            value: "610527"
          },
          {
            pid: "610500",
            label: "富平县",
            value: "610528"
          },
          {
            pid: "610500",
            label: "韩城市",
            value: "610581"
          },
          {
            pid: "610500",
            label: "华阴市",
            value: "610582"
          }
        ],
        pid: "610000",
        label: "渭南市",
        value: "610500"
      },
      {
        children: [
          {
            pid: "610600",
            label: "宝塔区",
            value: "610602"
          },
          {
            pid: "610600",
            label: "安塞区",
            value: "610603"
          },
          {
            pid: "610600",
            label: "延长县",
            value: "610621"
          },
          {
            pid: "610600",
            label: "延川县",
            value: "610622"
          },
          {
            pid: "610600",
            label: "子长县",
            value: "610623"
          },
          {
            pid: "610600",
            label: "志丹县",
            value: "610625"
          },
          {
            pid: "610600",
            label: "吴起县",
            value: "610626"
          },
          {
            pid: "610600",
            label: "甘泉县",
            value: "610627"
          },
          {
            pid: "610600",
            label: "富县",
            value: "610628"
          },
          {
            pid: "610600",
            label: "洛川县",
            value: "610629"
          },
          {
            pid: "610600",
            label: "宜川县",
            value: "610630"
          },
          {
            pid: "610600",
            label: "黄龙县",
            value: "610631"
          },
          {
            pid: "610600",
            label: "黄陵县",
            value: "610632"
          }
        ],
        pid: "610000",
        label: "延安市",
        value: "610600"
      },
      {
        children: [
          {
            pid: "610700",
            label: "汉台区",
            value: "610702"
          },
          {
            pid: "610700",
            label: "南郑区",
            value: "610703"
          },
          {
            pid: "610700",
            label: "城固县",
            value: "610722"
          },
          {
            pid: "610700",
            label: "洋县",
            value: "610723"
          },
          {
            pid: "610700",
            label: "西乡县",
            value: "610724"
          },
          {
            pid: "610700",
            label: "勉县",
            value: "610725"
          },
          {
            pid: "610700",
            label: "宁强县",
            value: "610726"
          },
          {
            pid: "610700",
            label: "略阳县",
            value: "610727"
          },
          {
            pid: "610700",
            label: "镇巴县",
            value: "610728"
          },
          {
            pid: "610700",
            label: "留坝县",
            value: "610729"
          },
          {
            pid: "610700",
            label: "佛坪县",
            value: "610730"
          }
        ],
        pid: "610000",
        label: "汉中市",
        value: "610700"
      },
      {
        children: [
          {
            pid: "610800",
            label: "榆阳区",
            value: "610802"
          },
          {
            pid: "610800",
            label: "横山区",
            value: "610803"
          },
          {
            pid: "610800",
            label: "府谷县",
            value: "610822"
          },
          {
            pid: "610800",
            label: "靖边县",
            value: "610824"
          },
          {
            pid: "610800",
            label: "定边县",
            value: "610825"
          },
          {
            pid: "610800",
            label: "绥德县",
            value: "610826"
          },
          {
            pid: "610800",
            label: "米脂县",
            value: "610827"
          },
          {
            pid: "610800",
            label: "佳县",
            value: "610828"
          },
          {
            pid: "610800",
            label: "吴堡县",
            value: "610829"
          },
          {
            pid: "610800",
            label: "清涧县",
            value: "610830"
          },
          {
            pid: "610800",
            label: "子洲县",
            value: "610831"
          },
          {
            pid: "610800",
            label: "神木市",
            value: "610881"
          }
        ],
        pid: "610000",
        label: "榆林市",
        value: "610800"
      },
      {
        children: [
          {
            pid: "610900",
            label: "汉滨区",
            value: "610902"
          },
          {
            pid: "610900",
            label: "汉阴县",
            value: "610921"
          },
          {
            pid: "610900",
            label: "石泉县",
            value: "610922"
          },
          {
            pid: "610900",
            label: "宁陕县",
            value: "610923"
          },
          {
            pid: "610900",
            label: "紫阳县",
            value: "610924"
          },
          {
            pid: "610900",
            label: "岚皋县",
            value: "610925"
          },
          {
            pid: "610900",
            label: "平利县",
            value: "610926"
          },
          {
            pid: "610900",
            label: "镇坪县",
            value: "610927"
          },
          {
            pid: "610900",
            label: "旬阳县",
            value: "610928"
          },
          {
            pid: "610900",
            label: "白河县",
            value: "610929"
          }
        ],
        pid: "610000",
        label: "安康市",
        value: "610900"
      },
      {
        children: [
          {
            pid: "611000",
            label: "商州区",
            value: "611002"
          },
          {
            pid: "611000",
            label: "洛南县",
            value: "611021"
          },
          {
            pid: "611000",
            label: "丹凤县",
            value: "611022"
          },
          {
            pid: "611000",
            label: "商南县",
            value: "611023"
          },
          {
            pid: "611000",
            label: "山阳县",
            value: "611024"
          },
          {
            pid: "611000",
            label: "镇安县",
            value: "611025"
          },
          {
            pid: "611000",
            label: "柞水县",
            value: "611026"
          }
        ],
        pid: "610000",
        label: "商洛市",
        value: "611000"
      }
    ],
    pid: "-1",
    label: "陕西省",
    value: "610000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "620100",
            label: "城关区",
            value: "620102"
          },
          {
            pid: "620100",
            label: "七里河区",
            value: "620103"
          },
          {
            pid: "620100",
            label: "西固区",
            value: "620104"
          },
          {
            pid: "620100",
            label: "安宁区",
            value: "620105"
          },
          {
            pid: "620100",
            label: "红古区",
            value: "620111"
          },
          {
            pid: "620100",
            label: "永登县",
            value: "620121"
          },
          {
            pid: "620100",
            label: "皋兰县",
            value: "620122"
          },
          {
            pid: "620100",
            label: "榆中县",
            value: "620123"
          }
        ],
        pid: "620000",
        label: "兰州市",
        value: "620100"
      },
      {
        pid: "620000",
        label: "嘉峪关市",
        value: "620200"
      },
      {
        children: [
          {
            pid: "620300",
            label: "金川区",
            value: "620302"
          },
          {
            pid: "620300",
            label: "永昌县",
            value: "620321"
          }
        ],
        pid: "620000",
        label: "金昌市",
        value: "620300"
      },
      {
        children: [
          {
            pid: "620400",
            label: "白银区",
            value: "620402"
          },
          {
            pid: "620400",
            label: "平川区",
            value: "620403"
          },
          {
            pid: "620400",
            label: "靖远县",
            value: "620421"
          },
          {
            pid: "620400",
            label: "会宁县",
            value: "620422"
          },
          {
            pid: "620400",
            label: "景泰县",
            value: "620423"
          }
        ],
        pid: "620000",
        label: "白银市",
        value: "620400"
      },
      {
        children: [
          {
            pid: "620500",
            label: "秦州区",
            value: "620502"
          },
          {
            pid: "620500",
            label: "麦积区",
            value: "620503"
          },
          {
            pid: "620500",
            label: "清水县",
            value: "620521"
          },
          {
            pid: "620500",
            label: "秦安县",
            value: "620522"
          },
          {
            pid: "620500",
            label: "甘谷县",
            value: "620523"
          },
          {
            pid: "620500",
            label: "武山县",
            value: "620524"
          },
          {
            pid: "620500",
            label: "张家川回族自治县",
            value: "620525"
          }
        ],
        pid: "620000",
        label: "天水市",
        value: "620500"
      },
      {
        children: [
          {
            pid: "620600",
            label: "凉州区",
            value: "620602"
          },
          {
            pid: "620600",
            label: "民勤县",
            value: "620621"
          },
          {
            pid: "620600",
            label: "古浪县",
            value: "620622"
          },
          {
            pid: "620600",
            label: "天祝藏族自治县",
            value: "620623"
          }
        ],
        pid: "620000",
        label: "武威市",
        value: "620600"
      },
      {
        children: [
          {
            pid: "620700",
            label: "甘州区",
            value: "620702"
          },
          {
            pid: "620700",
            label: "肃南裕固族自治县",
            value: "620721"
          },
          {
            pid: "620700",
            label: "民乐县",
            value: "620722"
          },
          {
            pid: "620700",
            label: "临泽县",
            value: "620723"
          },
          {
            pid: "620700",
            label: "高台县",
            value: "620724"
          },
          {
            pid: "620700",
            label: "山丹县",
            value: "620725"
          }
        ],
        pid: "620000",
        label: "张掖市",
        value: "620700"
      },
      {
        children: [
          {
            pid: "620800",
            label: "崆峒区",
            value: "620802"
          },
          {
            pid: "620800",
            label: "泾川县",
            value: "620821"
          },
          {
            pid: "620800",
            label: "灵台县",
            value: "620822"
          },
          {
            pid: "620800",
            label: "崇信县",
            value: "620823"
          },
          {
            pid: "620800",
            label: "庄浪县",
            value: "620825"
          },
          {
            pid: "620800",
            label: "静宁县",
            value: "620826"
          },
          {
            pid: "620800",
            label: "华亭市",
            value: "620881"
          }
        ],
        pid: "620000",
        label: "平凉市",
        value: "620800"
      },
      {
        children: [
          {
            pid: "620900",
            label: "肃州区",
            value: "620902"
          },
          {
            pid: "620900",
            label: "金塔县",
            value: "620921"
          },
          {
            pid: "620900",
            label: "瓜州县",
            value: "620922"
          },
          {
            pid: "620900",
            label: "肃北蒙古族自治县",
            value: "620923"
          },
          {
            pid: "620900",
            label: "阿克塞哈萨克族自治县",
            value: "620924"
          },
          {
            pid: "620900",
            label: "玉门市",
            value: "620981"
          },
          {
            pid: "620900",
            label: "敦煌市",
            value: "620982"
          }
        ],
        pid: "620000",
        label: "酒泉市",
        value: "620900"
      },
      {
        children: [
          {
            pid: "621000",
            label: "西峰区",
            value: "621002"
          },
          {
            pid: "621000",
            label: "庆城县",
            value: "621021"
          },
          {
            pid: "621000",
            label: "环县",
            value: "621022"
          },
          {
            pid: "621000",
            label: "华池县",
            value: "621023"
          },
          {
            pid: "621000",
            label: "合水县",
            value: "621024"
          },
          {
            pid: "621000",
            label: "正宁县",
            value: "621025"
          },
          {
            pid: "621000",
            label: "宁县",
            value: "621026"
          },
          {
            pid: "621000",
            label: "镇原县",
            value: "621027"
          }
        ],
        pid: "620000",
        label: "庆阳市",
        value: "621000"
      },
      {
        children: [
          {
            pid: "621100",
            label: "安定区",
            value: "621102"
          },
          {
            pid: "621100",
            label: "通渭县",
            value: "621121"
          },
          {
            pid: "621100",
            label: "陇西县",
            value: "621122"
          },
          {
            pid: "621100",
            label: "渭源县",
            value: "621123"
          },
          {
            pid: "621100",
            label: "临洮县",
            value: "621124"
          },
          {
            pid: "621100",
            label: "漳县",
            value: "621125"
          },
          {
            pid: "621100",
            label: "岷县",
            value: "621126"
          }
        ],
        pid: "620000",
        label: "定西市",
        value: "621100"
      },
      {
        children: [
          {
            pid: "621200",
            label: "武都区",
            value: "621202"
          },
          {
            pid: "621200",
            label: "成县",
            value: "621221"
          },
          {
            pid: "621200",
            label: "文县",
            value: "621222"
          },
          {
            pid: "621200",
            label: "宕昌县",
            value: "621223"
          },
          {
            pid: "621200",
            label: "康县",
            value: "621224"
          },
          {
            pid: "621200",
            label: "西和县",
            value: "621225"
          },
          {
            pid: "621200",
            label: "礼县",
            value: "621226"
          },
          {
            pid: "621200",
            label: "徽县",
            value: "621227"
          },
          {
            pid: "621200",
            label: "两当县",
            value: "621228"
          }
        ],
        pid: "620000",
        label: "陇南市",
        value: "621200"
      },
      {
        children: [
          {
            pid: "622900",
            label: "临夏市",
            value: "622901"
          },
          {
            pid: "622900",
            label: "临夏县",
            value: "622921"
          },
          {
            pid: "622900",
            label: "康乐县",
            value: "622922"
          },
          {
            pid: "622900",
            label: "永靖县",
            value: "622923"
          },
          {
            pid: "622900",
            label: "广河县",
            value: "622924"
          },
          {
            pid: "622900",
            label: "和政县",
            value: "622925"
          },
          {
            pid: "622900",
            label: "东乡族自治县",
            value: "622926"
          },
          {
            pid: "622900",
            label: "积石山保安族东乡族撒拉族自治县",
            value: "622927"
          }
        ],
        pid: "620000",
        label: "临夏回族自治州",
        value: "622900"
      },
      {
        children: [
          {
            pid: "623000",
            label: "合作市",
            value: "623001"
          },
          {
            pid: "623000",
            label: "临潭县",
            value: "623021"
          },
          {
            pid: "623000",
            label: "卓尼县",
            value: "623022"
          },
          {
            pid: "623000",
            label: "舟曲县",
            value: "623023"
          },
          {
            pid: "623000",
            label: "迭部县",
            value: "623024"
          },
          {
            pid: "623000",
            label: "玛曲县",
            value: "623025"
          },
          {
            pid: "623000",
            label: "碌曲县",
            value: "623026"
          },
          {
            pid: "623000",
            label: "夏河县",
            value: "623027"
          }
        ],
        pid: "620000",
        label: "甘南藏族自治州",
        value: "623000"
      }
    ],
    pid: "-1",
    label: "甘肃省",
    value: "620000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "630100",
            label: "城东区",
            value: "630102"
          },
          {
            pid: "630100",
            label: "城中区",
            value: "630103"
          },
          {
            pid: "630100",
            label: "城西区",
            value: "630104"
          },
          {
            pid: "630100",
            label: "城北区",
            value: "630105"
          },
          {
            pid: "630100",
            label: "大通回族土族自治县",
            value: "630121"
          },
          {
            pid: "630100",
            label: "湟中县",
            value: "630122"
          },
          {
            pid: "630100",
            label: "湟源县",
            value: "630123"
          }
        ],
        pid: "630000",
        label: "西宁市",
        value: "630100"
      },
      {
        children: [
          {
            pid: "630200",
            label: "乐都区",
            value: "630202"
          },
          {
            pid: "630200",
            label: "平安区",
            value: "630203"
          },
          {
            pid: "630200",
            label: "民和回族土族自治县",
            value: "630222"
          },
          {
            pid: "630200",
            label: "互助土族自治县",
            value: "630223"
          },
          {
            pid: "630200",
            label: "化隆回族自治县",
            value: "630224"
          },
          {
            pid: "630200",
            label: "循化撒拉族自治县",
            value: "630225"
          }
        ],
        pid: "630000",
        label: "海东市",
        value: "630200"
      },
      {
        children: [
          {
            pid: "632200",
            label: "门源回族自治县",
            value: "632221"
          },
          {
            pid: "632200",
            label: "祁连县",
            value: "632222"
          },
          {
            pid: "632200",
            label: "海晏县",
            value: "632223"
          },
          {
            pid: "632200",
            label: "刚察县",
            value: "632224"
          }
        ],
        pid: "630000",
        label: "海北藏族自治州",
        value: "632200"
      },
      {
        children: [
          {
            pid: "632300",
            label: "同仁县",
            value: "632321"
          },
          {
            pid: "632300",
            label: "尖扎县",
            value: "632322"
          },
          {
            pid: "632300",
            label: "泽库县",
            value: "632323"
          },
          {
            pid: "632300",
            label: "河南蒙古族自治县",
            value: "632324"
          }
        ],
        pid: "630000",
        label: "黄南藏族自治州",
        value: "632300"
      },
      {
        children: [
          {
            pid: "632500",
            label: "共和县",
            value: "632521"
          },
          {
            pid: "632500",
            label: "同德县",
            value: "632522"
          },
          {
            pid: "632500",
            label: "贵德县",
            value: "632523"
          },
          {
            pid: "632500",
            label: "兴海县",
            value: "632524"
          },
          {
            pid: "632500",
            label: "贵南县",
            value: "632525"
          }
        ],
        pid: "630000",
        label: "海南藏族自治州",
        value: "632500"
      },
      {
        children: [
          {
            pid: "632600",
            label: "玛沁县",
            value: "632621"
          },
          {
            pid: "632600",
            label: "班玛县",
            value: "632622"
          },
          {
            pid: "632600",
            label: "甘德县",
            value: "632623"
          },
          {
            pid: "632600",
            label: "达日县",
            value: "632624"
          },
          {
            pid: "632600",
            label: "久治县",
            value: "632625"
          },
          {
            pid: "632600",
            label: "玛多县",
            value: "632626"
          }
        ],
        pid: "630000",
        label: "果洛藏族自治州",
        value: "632600"
      },
      {
        children: [
          {
            pid: "632700",
            label: "玉树市",
            value: "632701"
          },
          {
            pid: "632700",
            label: "杂多县",
            value: "632722"
          },
          {
            pid: "632700",
            label: "称多县",
            value: "632723"
          },
          {
            pid: "632700",
            label: "治多县",
            value: "632724"
          },
          {
            pid: "632700",
            label: "囊谦县",
            value: "632725"
          },
          {
            pid: "632700",
            label: "曲麻莱县",
            value: "632726"
          }
        ],
        pid: "630000",
        label: "玉树藏族自治州",
        value: "632700"
      },
      {
        children: [
          {
            pid: "632800",
            label: "格尔木市",
            value: "632801"
          },
          {
            pid: "632800",
            label: "德令哈市",
            value: "632802"
          },
          {
            pid: "632800",
            label: "茫崖市",
            value: "632803"
          },
          {
            pid: "632800",
            label: "乌兰县",
            value: "632821"
          },
          {
            pid: "632800",
            label: "都兰县",
            value: "632822"
          },
          {
            pid: "632800",
            label: "天峻县",
            value: "632823"
          }
        ],
        pid: "630000",
        label: "海西蒙古族藏族自治州",
        value: "632800"
      }
    ],
    pid: "-1",
    label: "青海省",
    value: "630000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "640100",
            label: "兴庆区",
            value: "640104"
          },
          {
            pid: "640100",
            label: "西夏区",
            value: "640105"
          },
          {
            pid: "640100",
            label: "金凤区",
            value: "640106"
          },
          {
            pid: "640100",
            label: "永宁县",
            value: "640121"
          },
          {
            pid: "640100",
            label: "贺兰县",
            value: "640122"
          },
          {
            pid: "640100",
            label: "灵武市",
            value: "640181"
          }
        ],
        pid: "640000",
        label: "银川市",
        value: "640100"
      },
      {
        children: [
          {
            pid: "640200",
            label: "大武口区",
            value: "640202"
          },
          {
            pid: "640200",
            label: "惠农区",
            value: "640205"
          },
          {
            pid: "640200",
            label: "平罗县",
            value: "640221"
          }
        ],
        pid: "640000",
        label: "石嘴山市",
        value: "640200"
      },
      {
        children: [
          {
            pid: "640300",
            label: "利通区",
            value: "640302"
          },
          {
            pid: "640300",
            label: "红寺堡区",
            value: "640303"
          },
          {
            pid: "640300",
            label: "盐池县",
            value: "640323"
          },
          {
            pid: "640300",
            label: "同心县",
            value: "640324"
          },
          {
            pid: "640300",
            label: "青铜峡市",
            value: "640381"
          }
        ],
        pid: "640000",
        label: "吴忠市",
        value: "640300"
      },
      {
        children: [
          {
            pid: "640400",
            label: "原州区",
            value: "640402"
          },
          {
            pid: "640400",
            label: "西吉县",
            value: "640422"
          },
          {
            pid: "640400",
            label: "隆德县",
            value: "640423"
          },
          {
            pid: "640400",
            label: "泾源县",
            value: "640424"
          },
          {
            pid: "640400",
            label: "彭阳县",
            value: "640425"
          }
        ],
        pid: "640000",
        label: "固原市",
        value: "640400"
      },
      {
        children: [
          {
            pid: "640500",
            label: "沙坡头区",
            value: "640502"
          },
          {
            pid: "640500",
            label: "中宁县",
            value: "640521"
          },
          {
            pid: "640500",
            label: "海原县",
            value: "640522"
          }
        ],
        pid: "640000",
        label: "中卫市",
        value: "640500"
      }
    ],
    pid: "-1",
    label: "宁夏回族自治区",
    value: "640000"
  },
  {
    children: [
      {
        children: [
          {
            pid: "650100",
            label: "天山区",
            value: "650102"
          },
          {
            pid: "650100",
            label: "沙依巴克区",
            value: "650103"
          },
          {
            pid: "650100",
            label: "新市区",
            value: "650104"
          },
          {
            pid: "650100",
            label: "水磨沟区",
            value: "650105"
          },
          {
            pid: "650100",
            label: "头屯河区",
            value: "650106"
          },
          {
            pid: "650100",
            label: "达坂城区",
            value: "650107"
          },
          {
            pid: "650100",
            label: "米东区",
            value: "650109"
          },
          {
            pid: "650100",
            label: "乌鲁木齐县",
            value: "650121"
          }
        ],
        pid: "650000",
        label: "乌鲁木齐市",
        value: "650100"
      },
      {
        children: [
          {
            pid: "650200",
            label: "独山子区",
            value: "650202"
          },
          {
            pid: "650200",
            label: "克拉玛依区",
            value: "650203"
          },
          {
            pid: "650200",
            label: "白碱滩区",
            value: "650204"
          },
          {
            pid: "650200",
            label: "乌尔禾区",
            value: "650205"
          }
        ],
        pid: "650000",
        label: "克拉玛依市",
        value: "650200"
      },
      {
        children: [
          {
            pid: "650400",
            label: "高昌区",
            value: "650402"
          },
          {
            pid: "650400",
            label: "鄯善县",
            value: "650421"
          },
          {
            pid: "650400",
            label: "托克逊县",
            value: "650422"
          }
        ],
        pid: "650000",
        label: "吐鲁番市",
        value: "650400"
      },
      {
        children: [
          {
            pid: "650500",
            label: "伊州区",
            value: "650502"
          },
          {
            pid: "650500",
            label: "巴里坤哈萨克自治县",
            value: "650521"
          },
          {
            pid: "650500",
            label: "伊吾县",
            value: "650522"
          }
        ],
        pid: "650000",
        label: "哈密市",
        value: "650500"
      },
      {
        children: [
          {
            pid: "652300",
            label: "昌吉市",
            value: "652301"
          },
          {
            pid: "652300",
            label: "阜康市",
            value: "652302"
          },
          {
            pid: "652300",
            label: "呼图壁县",
            value: "652323"
          },
          {
            pid: "652300",
            label: "玛纳斯县",
            value: "652324"
          },
          {
            pid: "652300",
            label: "奇台县",
            value: "652325"
          },
          {
            pid: "652300",
            label: "吉木萨尔县",
            value: "652327"
          },
          {
            pid: "652300",
            label: "木垒哈萨克自治县",
            value: "652328"
          }
        ],
        pid: "650000",
        label: "昌吉回族自治州",
        value: "652300"
      },
      {
        children: [
          {
            pid: "652700",
            label: "博乐市",
            value: "652701"
          },
          {
            pid: "652700",
            label: "阿拉山口市",
            value: "652702"
          },
          {
            pid: "652700",
            label: "精河县",
            value: "652722"
          },
          {
            pid: "652700",
            label: "温泉县",
            value: "652723"
          }
        ],
        pid: "650000",
        label: "博尔塔拉蒙古自治州",
        value: "652700"
      },
      {
        children: [
          {
            pid: "652800",
            label: "库尔勒市",
            value: "652801"
          },
          {
            pid: "652800",
            label: "轮台县",
            value: "652822"
          },
          {
            pid: "652800",
            label: "尉犁县",
            value: "652823"
          },
          {
            pid: "652800",
            label: "若羌县",
            value: "652824"
          },
          {
            pid: "652800",
            label: "且末县",
            value: "652825"
          },
          {
            pid: "652800",
            label: "焉耆回族自治县",
            value: "652826"
          },
          {
            pid: "652800",
            label: "和静县",
            value: "652827"
          },
          {
            pid: "652800",
            label: "和硕县",
            value: "652828"
          },
          {
            pid: "652800",
            label: "博湖县",
            value: "652829"
          }
        ],
        pid: "650000",
        label: "巴音郭楞蒙古自治州",
        value: "652800"
      },
      {
        children: [
          {
            pid: "652900",
            label: "阿克苏市",
            value: "652901"
          },
          {
            pid: "652900",
            label: "温宿县",
            value: "652922"
          },
          {
            pid: "652900",
            label: "库车县",
            value: "652923"
          },
          {
            pid: "652900",
            label: "沙雅县",
            value: "652924"
          },
          {
            pid: "652900",
            label: "新和县",
            value: "652925"
          },
          {
            pid: "652900",
            label: "拜城县",
            value: "652926"
          },
          {
            pid: "652900",
            label: "乌什县",
            value: "652927"
          },
          {
            pid: "652900",
            label: "阿瓦提县",
            value: "652928"
          },
          {
            pid: "652900",
            label: "柯坪县",
            value: "652929"
          }
        ],
        pid: "650000",
        label: "阿克苏地区",
        value: "652900"
      },
      {
        children: [
          {
            pid: "653000",
            label: "阿图什市",
            value: "653001"
          },
          {
            pid: "653000",
            label: "阿克陶县",
            value: "653022"
          },
          {
            pid: "653000",
            label: "阿合奇县",
            value: "653023"
          },
          {
            pid: "653000",
            label: "乌恰县",
            value: "653024"
          }
        ],
        pid: "650000",
        label: "克孜勒苏柯尔克孜自治州",
        value: "653000"
      },
      {
        children: [
          {
            pid: "653100",
            label: "喀什市",
            value: "653101"
          },
          {
            pid: "653100",
            label: "疏附县",
            value: "653121"
          },
          {
            pid: "653100",
            label: "疏勒县",
            value: "653122"
          },
          {
            pid: "653100",
            label: "英吉沙县",
            value: "653123"
          },
          {
            pid: "653100",
            label: "泽普县",
            value: "653124"
          },
          {
            pid: "653100",
            label: "莎车县",
            value: "653125"
          },
          {
            pid: "653100",
            label: "叶城县",
            value: "653126"
          },
          {
            pid: "653100",
            label: "麦盖提县",
            value: "653127"
          },
          {
            pid: "653100",
            label: "岳普湖县",
            value: "653128"
          },
          {
            pid: "653100",
            label: "伽师县",
            value: "653129"
          },
          {
            pid: "653100",
            label: "巴楚县",
            value: "653130"
          },
          {
            pid: "653100",
            label: "塔什库尔干塔吉克自治县",
            value: "653131"
          }
        ],
        pid: "650000",
        label: "喀什地区",
        value: "653100"
      },
      {
        children: [
          {
            pid: "653200",
            label: "和田市",
            value: "653201"
          },
          {
            pid: "653200",
            label: "和田县",
            value: "653221"
          },
          {
            pid: "653200",
            label: "墨玉县",
            value: "653222"
          },
          {
            pid: "653200",
            label: "皮山县",
            value: "653223"
          },
          {
            pid: "653200",
            label: "洛浦县",
            value: "653224"
          },
          {
            pid: "653200",
            label: "策勒县",
            value: "653225"
          },
          {
            pid: "653200",
            label: "于田县",
            value: "653226"
          },
          {
            pid: "653200",
            label: "民丰县",
            value: "653227"
          }
        ],
        pid: "650000",
        label: "和田地区",
        value: "653200"
      },
      {
        children: [
          {
            pid: "654000",
            label: "伊宁市",
            value: "654002"
          },
          {
            pid: "654000",
            label: "奎屯市",
            value: "654003"
          },
          {
            pid: "654000",
            label: "霍尔果斯市",
            value: "654004"
          },
          {
            pid: "654000",
            label: "伊宁县",
            value: "654021"
          },
          {
            pid: "654000",
            label: "察布查尔锡伯自治县",
            value: "654022"
          },
          {
            pid: "654000",
            label: "霍城县",
            value: "654023"
          },
          {
            pid: "654000",
            label: "巩留县",
            value: "654024"
          },
          {
            pid: "654000",
            label: "新源县",
            value: "654025"
          },
          {
            pid: "654000",
            label: "昭苏县",
            value: "654026"
          },
          {
            pid: "654000",
            label: "特克斯县",
            value: "654027"
          },
          {
            pid: "654000",
            label: "尼勒克县",
            value: "654028"
          }
        ],
        pid: "650000",
        label: "伊犁哈萨克自治州",
        value: "654000"
      },
      {
        children: [
          {
            pid: "654200",
            label: "塔城市",
            value: "654201"
          },
          {
            pid: "654200",
            label: "乌苏市",
            value: "654202"
          },
          {
            pid: "654200",
            label: "额敏县",
            value: "654221"
          },
          {
            pid: "654200",
            label: "沙湾县",
            value: "654223"
          },
          {
            pid: "654200",
            label: "托里县",
            value: "654224"
          },
          {
            pid: "654200",
            label: "裕民县",
            value: "654225"
          },
          {
            pid: "654200",
            label: "和布克赛尔蒙古自治县",
            value: "654226"
          }
        ],
        pid: "650000",
        label: "塔城地区",
        value: "654200"
      },
      {
        children: [
          {
            pid: "654300",
            label: "阿勒泰市",
            value: "654301"
          },
          {
            pid: "654300",
            label: "布尔津县",
            value: "654321"
          },
          {
            pid: "654300",
            label: "富蕴县",
            value: "654322"
          },
          {
            pid: "654300",
            label: "福海县",
            value: "654323"
          },
          {
            pid: "654300",
            label: "哈巴河县",
            value: "654324"
          },
          {
            pid: "654300",
            label: "青河县",
            value: "654325"
          },
          {
            pid: "654300",
            label: "吉木乃县",
            value: "654326"
          }
        ],
        pid: "650000",
        label: "阿勒泰地区",
        value: "654300"
      }
    ],
    pid: "-1",
    label: "新疆维吾尔自治区",
    value: "650000"
  },
  {
    pid: "-1",
    label: "台湾省",
    value: "710000"
  },
  {
    pid: "-1",
    label: "香港特别行政区",
    value: "810000"
  },
  {
    pid: "-1",
    label: "澳门特别行政区",
    value: "820000"
  }
];
